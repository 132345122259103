<template>
  <div class="relative text-white flex flex-col gap-3 pt-5 w-full">
    <!-- <div class="flex flex-col gap-2 bg-zinc-800 rounded-sm p-3">
      <div class="flex items-center text-xl">
        Basic info:
        <button @click="editingBasicInfo = !editingBasicInfo" class="pl-1.5 xl:hover:scale-105 text-green-500">
          <font-awesome-icon :icon="['fas', 'pen-to-square']" />
        </button>
      </div>
      <div class="flex items-center gap-x-2">
        <div v-if="!editingBasicInfo" class="bg-zinc-700 px-3 py-1 rounded-sm">
          <h3 class="pr-2">Name:</h3>
          <h2 class="text-lg font-semibold">{{ platform.name }}</h2>
        </div>
        <div v-else class="bg-zinc-700 px-3 py-1 rounded-sm">
          <h3 class="pr-2">Name:</h3>
          <div class="flex items-center gap-2">
            <input type="text" v-model="newName" class="bg-zinc-900 text-white text-lg outline-none">
            <button @click="updatePlatform" class="bg-zinc-500 xl:hover:bg-opacity-50 px-3 rounded-sm text-white">
              Save
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="flex flex-col gap-2 bg-zinc-900 rounded-sm p-3">
      <div class="flex items-center text-xl fugaz uppercase pl-2">Services</div>
      <div class="flex items-start justify-center flex-wrap gap-2">
        <PlatformService
          :platform="platform"
          :serviceName="'gaming'"
          :originalFields="gamingServiceFields"
        />
        <PlatformService
          :platform="platform"
          :serviceName="'data'"
          :originalFields="dataServiceFields"
        />
        <PlatformService
          :platform="platform"
          :serviceName="'play'"
          :originalFields="playServiceFields"
        />
        <PlatformService
          :platform="platform"
          :serviceName="'launch'"
          :originalFields="launchServiceFields"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlatformService from "./PlatformService.vue";

export default {
  name: "PlatformInfo",
  data() {
    return {
      newName: this.platform.name,
      editingBasicInfo: false,
      gamingServiceFields: {
        url: this.platform.gamingServiceURL,
        token: this.platform.gamingServiceToken,
      },
      dataServiceFields: {
        url: this.platform.dataServiceURL,
        token: this.platform.dataServiceToken,
      },
      playServiceFields: {
        url: this.platform.playServiceURL,
        token: this.platform.playServiceToken,
        bookkeepingToken: this.platform.bookkeepingToken,
        operatorToken: this.platform.operatorToken,
        maintenanceToken: this.platform.maintenanceToken,
      },
      launchServiceFields: {
        url: this.platform.launchServiceURL ?? '',
        token: this.platform.launchServiceToken ?? '',
      },
    };
  },
  props: {
    platform: Object,
    updatePlatform: Function,
  },
  components: {
    PlatformService,
  },
  created() {},
  methods: {

  },
};
</script>

<style></style>

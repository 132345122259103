const state = {
  fetching: false,
  lastJob: {},
  activeJob: {
    name: null,
    message: null,
    data: null,
    jobId: null,
    userId: null,
    state: null
  }
}

const getters = {
  lastJob: state => state.lastJob,
  fetching: state => state.fetching,
  activeJob: state => state.activeJob,
}

const actions = {
  setLastJobAction({commit}, payload) {
    commit('setLastJob', payload)
  },
  setFetchingAction({commit}, payload) {
    commit('setFetching', payload)
  },
  setActiveJobAction({commit}, payload) {
    commit('setActiveJob', payload)
  },
}

const mutations = {
  setLastJob(state, payload) {
    state.lastJob = payload
  },
  setFetching(state, payload) {
    state.fetching = payload
  },
  setActiveJob(state, payload) {
    state.activeJob = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
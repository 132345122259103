export function useDateFormatter(date, type = null) {
  let year;
  let month;
  let day;

  switch (type) {
    case "daily":
      year = date.start.getFullYear();
      month = String(date.start.getMonth() + 1).padStart(2, "0");
      day = String(date.start.getDate()).padStart(2, "0");
      return { date: `${year}-${month}-${day}` };

    case "custom":
      const day1 = String(date[0].getDate()).padStart(2, "0");
      const month1 = String(date[0].getMonth() + 1).padStart(2, "0");
      const year1 = date[0].getFullYear();
      const hours1 = String(date[0].getHours()).padStart(2, "0");
      const minutes1 = String(date[0].getMinutes()).padStart(2, "0");
      const day2 = String(date[1].getDate()).padStart(2, "0");
      const month2 = String(date[1].getMonth() + 1).padStart(2, "0");
      const year2 = date[1].getFullYear();
      const hours2 = String(date[1].getHours()).padStart(2, "0");
      const minutes2 = String(date[1].getMinutes()).padStart(2, "0");
      return {
        dateFrom: `${year1}-${month1}-${day1}T${hours1}:${minutes1}:00`,
        dateTo: `${year2}-${month2}-${day2}T${hours2}:${minutes2}:59`,
      };
    case "monthly":
      const d = { ...date };
      const m = d.month + 1;
      const y = d.year;
      return { month: m, year: y };

    default:
      year = date.getFullYear();
      month = String(date.getMonth() + 1).padStart(2, "0");
      day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
}

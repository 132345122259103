<template>
  <div class="w-full h-full flex justify-center bg-[#18181b] text-white">
    <div class="flex flex-col items-center gap-2 pt-[82px]">
      <img class="pb-[52px]" src="../assets/hub-full-logo.png" alt="gai logo">
      <div class="border-orange-600 border-t-4 bg-[#1e1e20] w-[500px] flex flex-col items-center gap-4 py-8">
        <p class="text-center text-lg">
          Something went wrong, please 
          <strong @click="reload" class="cursor-pointer xl:hover:text-pink-500 transition-all duration-100">try again</strong>
          <br>
          If the problem persists please contact our team.
        </p>
        <button @click="logout" class="bg-orange-600 px-3 py-1 rounded-sm xl:hover:font-semibold text-lg">Logout</button>
      </div>
      <div class="flex flex-col items-center gap-1 pt-3">
        <span class="text-xs">Powered by</span>
        <img src="../assets/logo_cristaltec.png" alt="logo cristaltec">
      </div>
    </div>
  </div>
</template>

<script>
  import {keycloak} from '../keycloak.config'

  export default {
    methods: {
      logout() {
        keycloak.logout()
      },
      reload() {
        window.location.reload();
      }
    }
  }
</script>

<style scoped>

</style>
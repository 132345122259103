<template>
  <div class="w-full h-full max-h-screen bg-zinc-200 dark:bg-zinc-950 pb-4 xl:pb-8 pt-4 xl:pt-8 px-4 xl:px-16">
    <div class="w-full h-full rounded-md shadow-md flex gap-x-3" >
      <div class="relative h-full w-full sm:w-2/5 rounded-md bg-zinc-800 flex-col px-4 overflow-y-auto overflow-x-hidden"
        :class="actualNotification.id ? 'hidden sm:flex' : 'flex'"  
      >
        <div class="sticky top-0 bg-zinc-800 w-full p-4 flex justify-center">
          <h2 class="fugaz uppercase text-white text-2xl xl:text-3xl">Notifications</h2>
        </div>
        <div v-for="(notif, index) in notifications" :key="`notif${index}`" 
          @click="seeNotif(notif, index)"
          class="relative cursor-pointer w-full rounded-md px-3 xl:px-4 pt-1 xl:pt-4 pb-7 xl:pb-4 text-white mb-3"
          :class="[
            (notif.seen ? 'bg-zinc-700' : 'bg-zinc-600 font-bold'), 
            (notif.id == actualNotification.id && 'border')
          ]"
        >
          <span class="absolute top-1 xl:top-0.5 right-2 text-base font-normal">
            {{ cleanDate(notif.createdAt) }}
          </span>
          <div v-if="notif.severity" class="absolute bottom-1.5 right-1.5 flex items-center gap-2">
            Severity:
            <span v-if="notif.severity === 1" class="px-3 rounded-sm bg-green-500">Low</span>
            <span v-if="notif.severity === 2" class="px-3 rounded-sm bg-orange-400">Medium</span>
            <span v-if="notif.severity === 3" class="px-3 rounded-sm bg-red-500">High</span>
            <span v-if="notif.severity === 4" class="px-3 rounded-sm bg-red-700">Critical</span>
          </div>

          <h2 class="text-lg" style="width: 85%;">{{notif.title}}</h2>
        </div>
        <div v-if="!notifications.length" class="text-white text-center">
          Nothing to show
        </div>
      </div>
      <div v-if="actualNotification.id" class="relative flex h-full w-full sm:w-3/5 rounded-md bg-zinc-800 flex-col px-4 pt-9 sm:pt-4 pb-4 overflow-y-auto">
        <button @click="actualNotification = {}" class="block sm:hidden absolute top-0 left-0 px-3 rounded-sm bg-green-500">
          Back
        </button>
        <div v-if="actualNotification.id" class="h-full w-full">
          <div v-if="!loading" class="relative w-full flex flex-col gap-y-3 bg-zinc-700 rounded-md text-white px-3 pt-2 xl:pt-3 pb-10 xl:pb-3">
            <span class="absolute bottom-0.5 right-2 text-base font-normal">
              {{ fullDate(actualNotification.createdAt) }}
            </span>
            <h2 v-if="actualNotification.maintenanceId" class="font-bold text-xl" style="width: 85%;">{{actualNotification.title}} at: {{fullDate(actualMaintenance.date)}}</h2>
            <h2 v-else class="font-bold text-xl" style="width: 85%;">{{actualNotification.title}}</h2>
            <h3>
              {{actualNotification.description}}
            </h3>
            <div v-if="actualNotification.maintenanceId">
              <div class="flex items-center gap-2">
                Maintenance severity: 
                <span v-if="actualNotification.severity === 1" class="px-3 py-0.5 rounded-sm bg-green-500">Low</span>
                <span v-if="actualNotification.severity === 2" class="px-3 py-0.5 rounded-sm bg-orange-400">Medium</span>
                <span v-if="actualNotification.severity === 3" class="px-3 py-0.5 rounded-sm bg-red-500">High</span>
                <span v-if="actualNotification.severity === 4" class="px-3 py-0.5 rounded-sm bg-red-700">Critical</span>
              </div>
              <div v-if="!actualMaintenance.platforms[0].maintenance_platforms.confirmed">
                <div v-if="actualNotification.severity < 4" class="flex justify-center items-center gap-x-2">
                  Confirm availability?
                  <button @click="confirmMaintenance(actualNotification, actualNotification.index, true)" 
                    class="px-3 rounded-sm bg-green-500"
                    :class="actualMaintenance.platforms[0].maintenance_platforms.confirmed && 'border-2'"
                  >\
                    Yes
                  </button> 
                  <button @click="confirmMaintenance(actualNotification, actualNotification.index, false)" 
                    class="px-3 rounded-sm bg-red-500"
                    :class="actualMaintenance.platforms[0].maintenance_platforms.confirmed === false && 'border-2'"
                  >
                    No
                  </button> 
                </div>
              </div>
              <div v-else class="flex justify-center items-center gap-2">
                Availability confirmed <font-awesome-icon :icon="['fas', 'check']" class="text-green-500" />
              </div>
            </div>
          </div>
          <div v-else class="w-full h-full flex justify-center items-center">
            <img src="../assets/loading2.png" alt="loading icon" class="w-auto h-10 text-zinc-500 object-cover animate-spin"/>
          </div>
        </div>
        <div v-else class="w-full h-full flex justify-center items-center">
          <img src="../assets/GAI_LOGO.png" alt="gai gub logo" class="w-auto h-16 object-cover">
        </div>
      </div>
      <div v-else class="hidden sm:flex h-full w-full sm:w-3/5 justify-center items-center bg-zinc-800">
        <img src="../assets/GAI_LOGO.png" alt="gai gub logo" class="w-auto h-16 object-cover">
      </div>
    </div>
  </div>
</template>

<script>
import request from '../services/axios'
export default {
  name: 'NotificationsView',
  data() {
    return {
      actualNotification: {},
      actualMaintenance: {},
      loading: false,
    }
  },
  mounted() {

  },
  computed: {
    appLanguage() {
      return this.$store.getters['getLanguage']
    },
    notifications() {
      const notifications = this.$store.getters['user/notifications']
      // this.actualNotification = notifications[0]
      return notifications
    }
  },
  methods: {
    async seeNotif(notif, index) {
      this.loading = true
      if(!notif.seen) {
        const seenNotif = await request.put(`/notifications/update/${notif.id}`, {
          seen: true
        })
        this.$store.dispatch('user/updateOneNotificationAction', {notification: seenNotif.data, index: index})
      }
      this.actualNotification = notif
      this.actualNotification.index = index
      if (notif.maintenanceId) {
        const resMaintenance = await request.get(`/maintenances/${notif.maintenanceId}`)
        this.actualMaintenance = resMaintenance.data
      }
      this.loading = false
    },
    async confirmMaintenance(notif, index, confirmation) {
      const updatedNotif = await request.put(`/notifications/update/${notif.id}`, {
        confirmation
      })
      const resMaintenance = await request.get(`/maintenances/${notif.maintenanceId}`)
      this.actualMaintenance = resMaintenance.data
      this.$store.dispatch('user/updateOneNotificationAction', {notification: updatedNotif.data, index: index})
      this.actualNotification = updatedNotif.data
    },
    cleanDate(createdDate) {
      if (createdDate) {
        const date = new Date(createdDate);
        const today = new Date(); // Get the current date

        // Check if the date is today
        if (date.toDateString() === today.toDateString()) {
          const options = { hour: "numeric", minute: "numeric" };
          return date.toLocaleTimeString(this.appLanguage.long, options); // Show only the time
        } else {
          const currentYear = today.getFullYear();
          const dateYear = date.getFullYear();

          if (currentYear === dateYear) {
            const options = { month: "long", day: "numeric" };
            return date.toLocaleDateString(this.appLanguage.long, options); // Show date and month
          } else {
            const options = { year: "numeric", month: "long", day: "numeric" };
            return date.toLocaleDateString(this.appLanguage.long, options); // Show full date
          }
        }
      } else {
        return "N/A";
      }
    },
    fullDate(createdAt) {
      const date = new Date(createdAt)
      const options = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" };
      return date.toLocaleDateString(this.appLanguage.long, options)
    }

  }
}
</script>

<style>

</style>
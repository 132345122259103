const state = {
  savedFilters: null
}

const getters = {
  savedFilters: state => state.savedFilters
}

const actions = {

  setSavedFilters({commit}, payload) {
    commit('setSavedFilters', payload)
  }
}

const mutations = {
  setSavedFilters(state, payload) {
    state.savedFilters = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
<template>
  <div
    class="flex flex-col items-center h-full w-full rounded-md pt-6 pb-4 xl:pb-10 px-3 md:px-7 shadow-md"
  >
    <div class="flex justify-center w-full">
      <h2 class="text-3xl text-white pl-4 pb-4 fugaz uppercase">
        {{ name ? name : "New game" }} <span class="text-xl">media</span>
      </h2>
    </div>
    <div class="flex flex-wrap gap-x-20 items-center justify-center w-full mt-4 border-t border-zinc-600 rounded-sm p-3">
      <div class="flex flex-col items-center">
        <h3 class="text-lg font-semibold text-black dark:text-zinc-300 text-center">Main image: <span class="font-normal text-sm">(JPG or PNG)</span></h3>
        <label
          v-if="!mainImage.url"
          for="mainImage"
          class="my-1 px-3 flex justify-center items-center bg-zinc-800 group cursor-pointer xl:hover:brightness-90"
          style="height: 350px; width: 300px"
          @dragover.prevent="handleDragOver"
          @drop.prevent="handleFileDrop('mainImage')"
        >
          <font-awesome-icon
            class="text-orange-600 h-10 group-hover:scale-110 transition-all delay-50"
            icon="fa-solid fa-plus"
          />
          <input
            class="hidden"
            type="file"
            id="mainImage"
            ref="mainImage"
            @click="$event.target.value = null"
            @change="handleFileUpload('mainImage')"
            accept="image/png, image/jpeg"
          />
        </label>
        <div v-else class="relative w-max my-1" style="height: 350px; max-width: 300px;">
          <button
            @click="removeMainImage(mainImage)"
            class="absolute -top-3 -right-4 cursor-pointer xl:hover:scale-105"
          >
            <font-awesome-icon :icon="['fas', 'circle-xmark']" class="h-8 text-red-500" />
          </button>
          <img class="w-full h-full" :src="mainImage.url" alt="main game image" />
        </div>
        <div class="text-sm dark:text-zinc-400 mt-1 text-center">
          Recommended size: 1242 x 1464 px
          <span
            v-if="mainImage.width"
            :class="
              mainImage.width === 1242 && mainImage.height === 1464
                ? 'text-green-500'
                : 'text-red-500'
            "
          >
            (actual: {{ mainImage.width }} x {{ mainImage.height }} px)
          </span>
        </div>
      </div>
      <div>
        <h3 class="text-lg fugaz uppercase text-black dark:text-zinc-300 text-center cursor-default">Example</h3>
        <div class="relative w-max my-1 opacity-80" style="height: 250px; max-width: 300px;">
          <img class="w-full h-full" src="../../assets/main-image-demo.png" alt="main game image" />
        </div>
      </div>
    </div>

    <div class="w-full border-t border-zinc-600 rounded-sm p-3">
      <div class="flex flex-wrap items-center justify-center gap-x-20">
        <div class="flex flex-col items-center">
          <h3 class="text-lg font-semibold text-black dark:text-zinc-300">
            Banner image: 
            <span class="font-normal text-sm">(JPG or PNG)</span>
          </h3>
          <label
            v-if="!bannerImage.url"
            for="bannerImage"
            class="my-1 px-3 h-36 w-96 flex justify-center items-center bg-zinc-800 group cursor-pointer xl:hover:brightness-90"
            @dragover.prevent="handleDragOver"
            @drop.prevent="handleFileDrop('bannerImage')"
          >
            <font-awesome-icon
              class="text-orange-600 h-10 group-hover:scale-110 transition-all delay-50"
              icon="fa-solid fa-plus"
            />
            <input
              class="hidden"
              type="file"
              id="bannerImage"
              ref="bannerImage"
              @click="$event.target.value = null"
              @change="handleFileUpload('bannerImage')"
              accept="image/png, image/jpeg"
            />
          </label>
          <div v-if="bannerImage.url" class="relative my-1 w-96 h-36">
            <button
              @click="removeBannerImage(bannerImage)"
              class="absolute -top-3 -right-4 cursor-pointer xl:hover:scale-105"
            >
              <font-awesome-icon :icon="['fas', 'circle-xmark']" class="h-6 text-red-500" />
            </button>
            <img :src="bannerImage.url" alt="main game image" class="w-full h-full object-cover" />
          </div>
          <div class="text-sm dark:text-zinc-400 mt-1">
            Recommended size: 1833 x 686 px
            <span
              v-if="bannerImage.width"
              :class="
                bannerImage.width === 1833 && bannerImage.height === 686
                  ? 'text-green-500'
                  : 'text-red-500'
              "
            >
              (actual: {{ bannerImage.width }} x {{ bannerImage.height }} px)
            </span>
          </div>
        </div>
        <div>
          <h3 class="text-lg fugaz uppercase text-black dark:text-zinc-300 text-center cursor-default">Example</h3>
          <div class="relative my-1 w-[304px] max-h-[144px] opacity-80">
            <img src="../../assets/banner-demo.png" alt="main game image" class="w-full h-full object-cover" />
          </div>
        </div>
      </div>
      <h3
        v-if="bannerImage.url"
        class="text-lg font-semibold my-1 text-black dark:text-zinc-300 text-center"
      >
        Banner page preview:
      </h3>
      <div v-if="bannerImage.url" class="relative w-full h-max" style="max-height: 525px;">
        <div class="absolute left-24" style="max-width: 400px; bottom: 25%">
          <h1 class="text-white text-2xl italic font-bold uppercase">GAI HUB</h1>
          <h1 class="text-white text-2xl italic font-bold uppercase">ONLINE GAMES</h1>
          <button
            class="w-full h-8 mt-1 text-md bg-orange-600 text-white italic font-bold uppercase xl:hover:scale-105"
          >
            Play demo
          </button>
        </div>
        <img :src="bannerImage.url" class="w-full h-full max-h-full object-cover" style="max-height: 525px;" />
      </div>

    </div>

    <div class="w-full border-t border-zinc-600 rounded-sm pt-3 pb-4">
      <div class="flex flex-col items-center">
        <h3 class="text-lg font-semibold text-black dark:text-zinc-300">Mini banner: 
          <span class="font-normal text-sm">(JPG or PNG)</span>
        </h3>
        <label
          v-if="!miniBanner.url"
          for="miniBanner"
          class="my-1 px-3 h-20 w-full flex justify-center items-center bg-zinc-800 group cursor-pointer xl:hover:brightness-90"
          @dragover.prevent="handleDragOver"
          @drop.prevent="handleFileDrop('miniBanner')"
        >
          <font-awesome-icon
            class="text-orange-600 h-8 group-hover:scale-110 transition-all delay-50"
            icon="fa-solid fa-plus"
          />
          <input
            class="hidden"
            type="file"
            id="miniBanner"
            ref="miniBanner"
            @click="$event.target.value = null"
            @change="handleFileUpload('miniBanner')"
            accept="image/png, image/jpeg"
          />
        </label>
        <div v-if="miniBanner.url" class="relative w-max max-w-full h-20 my-1">
          <button
            @click="removeMiniBanner(miniBanner)"
            class="absolute -top-3 -right-4 cursor-pointer xl:hover:scale-105"
          >
            <font-awesome-icon :icon="['fas', 'circle-xmark']" class="h-6 text-red-500" />
          </button>
          <img :src="miniBanner.url" alt="main game image" class="w-full h-full" />
        </div>
        <div class="text-sm dark:text-zinc-400">
          Recommended size: 1152 x 80 px
          <span
            v-if="miniBanner.width"
            :class="
              miniBanner.width === 1152 && miniBanner.height === 80
                ? 'text-green-500'
                : 'text-red-500'
            "
          >
            (actual: {{ miniBanner.width }} x {{ miniBanner.height }} px)
          </span>
        </div>
      </div>
      <div class="flex flex-col items-center mt-1">
        <h3 class="text-lg fugaz uppercase text-black dark:text-zinc-300 text-center cursor-default">Example</h3>
        <div class="relative w-max max-w-[70%] max-h-20 my-1 opacity-80">
          <img src="../../assets/mini-banner-demo.png" alt="main game image" class="w-full h-full" />
        </div>
      </div>
    </div>

    <div class="w-full border-t border-zinc-600 rounded-sm pt-3 pb-4">
      <h3 class="text-lg font-semibold text-black dark:text-zinc-300 text-center mb-2">
        Gallery images
      </h3>
      <div class="flex flex-wrap justify-center gap-4">
        <div
          v-for="(img, index) in currentGalleryImages"
          :key="`img${index}`"
          class="h-36 w-36 relative"
        >
          <button
            class="absolute -top-2 -right-2 text-red-500 cursor-pointer xl:hover:scale-105"
            @click="removeGalleryImage(img, index)"
          >
            <font-awesome-icon :icon="['fas', 'circle-xmark']" class="h-5" />
          </button>
          <img class="h-full w-full object-cover" :src="img.url" alt="image to upload" />
        </div>
        <label
          for="image"
          class="px-3 h-36 w-36 flex justify-center items-center bg-zinc-800 group cursor-pointer xl:hover:brightness-90"
        >
          <font-awesome-icon
            class="text-orange-600 h-7 group-hover:scale-110 transition-all delay-50"
            icon="fa-solid fa-plus"
          />
          <input
            class="hidden"
            type="file"
            id="image"
            ref="image"
            @click="$event.target.value = null"
            multiple
            @change="handleGalleryUpload('image')"
            accept="image/png, image/jpeg"
          />
        </label>
      </div>
    </div>
    <div class="w-full border-y border-zinc-600 rounded-sm pt-3 pb-4">
      <h3 class="text-lg font-semibold text-black dark:text-zinc-300 text-center mb-2">
        Gallery videos
      </h3>
      <div class="flex flex-wrap justify-center gap-4">
        <div
          v-for="(video, index) in currentGalleryVideos"
          :key="`video${index}`"
          class="h-28 w-44 relative"
        >
          <button
            class="absolute top-0 -right-2 text-red-500 cursor-pointer xl:hover:scale-105 z-20"
            @click="removeGalleryVideo(video, index)"
          >
            <font-awesome-icon :icon="['fas', 'circle-xmark']" class="h-5" />
          </button>
          <video class="h-full w-full z-10" :src="video.url" controls></video>
        </div>
        <label
          for="video"
          class="px-3 h-28 w-44 flex justify-center items-center bg-zinc-800 group cursor-pointer xl:hover:brightness-90"
        >
          <font-awesome-icon
            class="text-orange-600 h-7 group-hover:scale-110 transition-all delay-50"
            icon="fa-solid fa-plus"
          />
          <input
            class="hidden"
            type="file"
            id="video"
            ref="video"
            accept="video/*"
            @click="$event.target.value = null"
            multiple
            @change="handleGalleryUpload('video')"
          />
        </label>
      </div>
    </div>
    <div class="flex flex-col md:flex-row items-center gap-5 mt-6 w-full">
      <div
        class="bg-zinc-800 shadow-md px-2 pt-1 pb-2 rounded-md w-full"
      >
        <div class="flex items-center gap-y-2">
          <h4 class="capitalize text-lg text-white mr-2">
            Docs URL
          </h4>
        </div>
        <input type="text" v-model="docsFile.url" placeholder="Paste URL" class="w-full outline-none px-1 bg-zinc-900 text-white">
      </div>
      <div
        class="bg-zinc-800 shadow-md px-2 pt-1 pb-2 rounded-md w-full"
      >
        <div class="flex items-center gap-y-2">
          <h4 class="capitalize text-lg text-white mr-2">
            Promo Assets URL
          </h4>
        </div>
        <input type="text" v-model="assetsFile.url" placeholder="Paste URL" class="w-full outline-none px-1 bg-zinc-900 text-white">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepTwo",
  data() {
    return {
      imagesToUpload: [],
      currentGalleryImages: [],
      currentGalleryVideos: [],
      mainImage: {},
      bannerImage: {},
      miniBanner: {},
      docsFile: {},
      assetsFile: {},
    };
  },
  mounted() {
    this.emitter.on("stepNumber2Called", this.updateImagesToUpload);
    if(Object.keys(this.gameMedia).length) {
      
      this.bannerImage = this.gameMedia.bannerImage;
      this.bannerImage.url = this.bannerImage.file ? this.createFileURL(this.bannerImage.file) : ''
      
      this.assetsFile = JSON.parse(JSON.stringify(this.gameMedia.assetsFile))
      this.docsFile = JSON.parse(JSON.stringify(this.gameMedia.docsFile))

      this.currentGalleryImages = this.gameMedia.gallery;
      for (const image of this.currentGalleryImages) {
        image.url = image.file ? this.createFileURL(image.file) : ''
      }
      this.currentGalleryVideos = this.gameMedia.videos;
      for (const video of this.currentGalleryImages) {
        video.url = video.file ? this.createFileURL(video.file) : ''
      }
      this.mainImage = this.gameMedia.mainImage;
      this.mainImage.url = this.mainImage.file ? this.createFileURL(this.mainImage.file) : ''

      this.miniBanner = this.gameMedia.miniBanner;
      this.miniBanner.url = this.miniBanner.file ? this.createFileURL(this.miniBanner.file) : ''
    }
    if(Object.keys(this.storeImagesToUpload).length) {
      this.imagesToUpload = this.storeImagesToUpload
    }
  },
  computed: {
    name() {
      return this.$store.getters["newGame/basicInfo"].name;
    },
    gameMedia() {
      return this.$store.getters["newGame/gameMedia"];
    },
    storeImagesToUpload() {
      return this.$store.getters["newGame/imagesToUpload"];
    }
  },
  methods: {
    errorMessage(message) {
      this.$toast.error(message, {
        position: "top-right",
        autoClose: 3000,
      });
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleFileDrop(refName) {
      const file = event.dataTransfer.files[0];
      // Set the file input value to the dropped file
      this.$refs[refName].files = event.dataTransfer.files;
      this.handleFileUpload(refName);
    },
    createFileURL(file) {
      var binaryData = [];
      binaryData.push(file);
      const fileURL = URL.createObjectURL(new Blob(binaryData));
      return fileURL;
    },
    updateImagesToUpload() {
      this.$store.dispatch("newGame/stepTwoAction", {
        imagesToUpload: this.imagesToUpload,
        gameMedia: {
          mainImage: this.mainImage,
          bannerImage: this.bannerImage,
          miniBanner: this.miniBanner,
          gallery: this.currentGalleryImages,
          videos: this.currentGalleryVideos,
          docsFile: this.docsFile,
          assetsFile: this.assetsFile,
        },
      });
    },
    handleGalleryUpload(type) {
      if (type == "image") {
        for (let i = 0; i < this.$refs.image.files.length; i++) {
          const file = this.$refs.image.files[i];
          const fileSrc = URL.createObjectURL(file);
          this.currentGalleryImages.push({
            url: fileSrc,
            name: file.name,
            file: file,
            mediaType: "gallery",
          });
          this.imagesToUpload.push({
            file,
            isMainImage: false,
            isBanner: false,
            isMiniBanner: false,
            mediaType: "gallery",
          });
        }
      }
      if (type == "video") {
        for (let i = 0; i < this.$refs.video.files.length; i++) {
          const file = this.$refs.video.files[i];
          const fileSrc = URL.createObjectURL(file);
          this.currentGalleryVideos.push({
            url: fileSrc,
            name: file.name,
            file: file,
            mediaType: "gallery",
          });
          this.imagesToUpload.push({
            file,
            isMainImage: false,
            isBanner: false,
            isMiniBanner: false,
            mediaType: "gallery",
          });
        }
      }
    },
    async convertToWebP(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error('No file provided'));
          return;
        }

        const reader = new FileReader();

        reader.onload = () => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            canvas.toBlob((blob) => {
              // Now we have a `blob` containing webp data
              const newFileName = file.name.split('.')[0]
              const convertedFile = new File([blob], `${newFileName}.webp`, { type: blob.type });
              resolve(convertedFile);
            }, 'image/webp');
          };

          image.src = reader.result;
        };

        reader.readAsDataURL(file);
      });
    },
    async handleFileUpload(type) {
      // TODO: improve this code to be more efficient
      if (type === "mainImage") {
        const file = this.$refs.mainImage.files[0];
        const extension = file?.name.split(".")[1];
        if (extension !== "jpg" && extension !== "png" && extension !== "webp") {
          return this.errorMessage("Wrong file extension");
        }
        const convertedFile = await this.convertToWebP(file)
        const fileSrc = URL.createObjectURL(convertedFile);
        // get actual file dimensions
        const img = new Image();
        img.onload = () => {
          this.mainImage.width = img.width;
          this.mainImage.height = img.height;
          // URL.revokeObjectURL(img.src);
        };
        img.src = fileSrc;
        
        this.mainImage.url = fileSrc;
        this.mainImage.file = convertedFile;

        this.imagesToUpload.push({
          file: convertedFile,
          originalFile: file,
          isMainImage: true,
          isBanner: false,
          isMiniBanner: false,
          mediaType: "gallery",
        });
      }
      if (type == "bannerImage") {
        const file = this.$refs.bannerImage.files[0];
        const extension = file?.name.split(".")[1];
        if (extension !== "jpg" && extension !== "png" && extension !== "webp") {
          return this.errorMessage("Wrong file extension");
        }
        const convertedFile = await this.convertToWebP(file)
        const fileSrc = URL.createObjectURL(convertedFile);
        // get actual file dimensions
        const img = new Image();
        img.onload = () => {
          this.bannerImage.width = img.width;
          this.bannerImage.height = img.height;
          // URL.revokeObjectURL(img.src);
        };
        img.src = fileSrc;
        this.bannerImage.url = fileSrc;
        this.bannerImage.file = convertedFile;
        this.imagesToUpload.push({
          file: convertedFile,
          originalFile: file,
          isMainImage: false,
          isBanner: true,
          isMiniBanner: false,
          mediaType: "gallery",
        });
      }
      if (type == "miniBanner") {
        const file = this.$refs.miniBanner.files[0];
        const extension = file?.name.split(".")[1];
        if (extension !== "jpg" && extension !== "png" && extension !== "webp") {
          return this.errorMessage("Wrong file extension");
        }
        const convertedFile = await this.convertToWebP(file)
        const fileSrc = URL.createObjectURL(convertedFile);
        // get actual file dimensions
        const img = new Image();
        img.onload = () => {
          this.miniBanner.width = img.width;
          this.miniBanner.height = img.height;
          // URL.revokeObjectURL(img.src);
        };
        img.src = fileSrc;
        this.miniBanner.url = fileSrc;
        this.miniBanner.file = file;
        this.imagesToUpload.push({
          file: convertedFile,
          originalFile: file,
          isMainImage: false,
          isBanner: false,
          isMiniBanner: true,
          mediaType: "gallery",
        });
      }
      // if (type == "docs") {
      //   const file = this.$refs.docs.files[0];
      //   for (let i = 0; i < this.imagesToUpload.length; i++) {
      //     if (this.imagesToUpload[i].mediaType == "docs") {
      //       this.imagesToUpload.splice(i, 1);
      //     }
      //   }
      //   const fileSrc = URL.createObjectURL(file);
      //   this.docsFile.url = fileSrc;
      //   this.docsFile.name = file.name;
      //   this.imagesToUpload.push({
      //     file,
      //     isMainImage: false,
      //     isBanner: true,
      //     isMiniBanner: false,
      //     mediaType: "docs",
      //   });
      // }
      // if (type == "assets") {
      //   const file = this.$refs.assets.files[0];
      //   for (let i = 0; i < this.imagesToUpload.length; i++) {
      //     if (this.imagesToUpload[i].mediaType == "assets") {
      //       this.imagesToUpload.splice(i, 1);
      //     }
      //   }
      //   const fileSrc = URL.createObjectURL(file);
      //   this.assetsFile.url = fileSrc;
      //   this.assetsFile.name = file.name;
      //   this.imagesToUpload.push({
      //     file,
      //     isMainImage: false,
      //     isBanner: true,
      //     isMiniBanner: false,
      //     mediaType: "assets",
      //   });
      // }
    },
    removeFileFromUploadList(file) {
      const index = this.imagesToUpload.findIndex((image) => image.file === file.file);
      this.imagesToUpload.splice(index, 1);
    },
    removeGalleryImage(file, galleryIndex) {
      this.currentGalleryImages.splice(galleryIndex, 1);
      this.removeFileFromUploadList(file)
    },
    removeGalleryVideo(file, galleryIndex) {
      this.currentGalleryVideos.splice(galleryIndex, 1);
      this.removeFileFromUploadList(file)
    },
    removeMainImage(file) {
      this.mainImage = {};
      this.removeFileFromUploadList(file)
    },
    removeBannerImage(file) {
      this.bannerImage = {};
      this.removeFileFromUploadList(file)
    },
    removeMiniBanner(file) {
      this.miniBanner = {};
      this.removeFileFromUploadList(file)
    },
  },
};
</script>

<style></style>

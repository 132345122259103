<template>
  <div class="flex flex-col gap-2 mt-1.5">
    <h1 class="text-lg xl:text-2xl text-center fugaz uppercase text-white">
      {{ $t('manageGames.certificates') }}
    </h1>
    <div class="flex flex-col bg-zinc-800 mx-3 md:mx-8 rounded-md text-white px-2 pt-3 pb-2.5 mb-2">
      <div class="flex flex-col md:flex-row gap-1.5">
        <div class="w-full">
          <Multiselect
            mode="single"
            v-model="selectedPlatform"
            :options="allPlatforms"
            :close-on-select="true"
            :searchable="true"
            label="name"
            value-prop="id"
            :placeholder="$t('bets.selectPlatform')"
            :loading="loading"
            ref="multiselectPlat"
          />
        </div>
        <div class="w-full">
          <Multiselect
            mode="single"
            v-model="selectedGame"
            :options="allGames"
            :close-on-select="true"
            :searchable="true"
            label="name"
            value-prop="id"
            :placeholder="$t('bets.selectGame')"
            :loading="loading"
            ref="multiselectGame"
          />
        </div>
      </div>
      <div v-if="selectedGame !== null && selectedPlatform !== null && !loading" class="flex flex-col pt-2">
        <span class="pl-1 fugaz uppercase">Files</span>
        <div class="flex flex-wrap items-center gap-1.5 pt-1">
          <div v-for="(cert, index) in certificates" class="flex flex-col items-center gap-1.5">
            <div v-if="!loadingDelete" class="pl-2 pr-1.5 py-1 rounded-md bg-zinc-900 flex items-end justify-between gap-3 relative h-full w-max">
              <div class="flex flex-col justify-between gap-1.5">
                <div class="flex items-center gap-4 justify-between group">
                  <div @click="downloadFile(cert.url)" :title="cert.name" class="flex tems-center gap-1.5 cursor-pointer">
                    <font-awesome-icon :icon="['fas', 'file']" class="pl-0.5 pt-0.5" />
                    <span>{{ cert.name ? cert.name : '-' }}</span>
                  </div>
                  <button @click="downloadFile(cert.url)" class="xl:group-hover:text-orange-600 pb-px">
                    <font-awesome-icon :icon="['fas', 'download']" class="cursor-pointer h-3.5 pb-0.5" />
                  </button>
                </div>
                <div class="flex items-center gap-4 justify-between">
                  <span class="font-light">
                    {{ $t('manageGames.uploadedOn') }} {{ clearDate(cert.updatedAt) }}
                  </span>
                  <button @click="openDelete(index)" class="xl:hover:text-red-500" :class="cert.deleting && 'text-orange-600'">
                    <font-awesome-icon :icon="['fas', 'trash']" class="cursor-pointer h-3.5 pl-px" />
                  </button>
                </div>
              </div>
            </div>
            <div v-if="cert.deleting && !loadingDelete" class="w-full flex items-center gap-1.5 text-center px-2">
              {{ $t('manageGames.sure') }}
              <button @click="deleteCertificate(cert.id)" class="px-2 pt-0.5 rounded-sm bg-red-500 xl:hover:bg-red-600 fugaz uppercase text-sm">
                {{ $t('manageGames.yes') }}
              </button>
              <button @click="cert.deleting = false" class="px-2 pt-0.5 rounded-sm bg-white xl:hover:bg-zinc-200 text-black fugaz uppercase text-sm">
                {{ $t('manageGames.no') }}
              </button>
            </div>
          </div>
          <button @click="creating = true" v-if="!creating" class="py-1 h-16 rounded-md bg-zinc-900 flex items-center justify-center gap-3 px-5 group xl:hover:text-orange-600">
            <font-awesome-icon :icon="['fas', 'plus']" class="h-5 transition-all duration-150 xl:group-hover:scale-125" />
          </button>
          <div v-if="creating && !loadingCreate" class="pl-2 pr-1.5 py-1.5 rounded-md bg-zinc-900 flex items-center gap-2 w-80">
            <div class="flex flex-col justify-between">
              <div class="flex items-center gap-1">
                <label for="fileName">{{ $t('manageGames.name') }}:</label>
                <input v-model="fileToUpload.name" type="text" id="fileName" :placeholder="$t('manageGames.certName')" class="w-full bg-zinc-950 outline-none px-1.5 rounded-md">
              </div>
              <label
                for="newFile"
                class="px-3 py-1 w-full flex justify-center items-center gap-1.5 bg-zinc-800 group cursor-pointer xl:hover:brightness-90"
                @dragover.prevent="handleDragOver"
                @drop.prevent="handleFileDrop()"
              >
                <span v-if="!fileToUpload.file">{{ $t('manageGames.uploadFile') }}</span>
                <font-awesome-icon
                  v-if="!fileToUpload.file"
                  class="text-orange-600 h-4 group-hover:scale-110 transition-all delay-50"
                  icon="fa-solid fa-plus"
                />
                <div v-else>
                  {{ fileToUpload.file.name }}
                </div>
                <input
                  class="hidden"
                  type="file"
                  id="newFile"
                  ref="newFile"
                  @click="$event.target.value = null"
                  @change="handleFileUpload('mainImage')"
                />
              </label>
            </div>
            <div class="flex flex-col justify-between gap-1">
              <button @click="cancelCreating" class="px-2 pt-[3px] pb-0.5 rounded-sm bg-red-600 xl:hover:bg-red-500 fugaz uppercase text-sm">
                {{ $t('manageGames.cancel') }}
              </button>
              <button @click="uploadCertificate" :disabled="!fileToUpload.file" 
                class="px-2 pt-[3px] pb-0.5 rounded-sm bg-green-600 fugaz uppercase text-sm disabled:pointer-events-none disabled:opacity-50" 
                :class="fileToUpload.file && 'xl:hover:bg-green-500'">
                {{ $t('manageGames.save') }}
              </button>
            </div>
          </div>
          <div v-if="loadingCreate" class="py-1.5 rounded-md bg-zinc-900 flex items-center gap-2 w-max h-16 px-4">
            <img src="../../assets/loading2.png" alt="loading icon"
              class="text-zinc-600 dark:text-zinc-400 h-7 animate-spin"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import request from '@/services/axios'
  import Multiselect from '@vueform/multiselect'

  export default {
    data() {
      return {
        selectedPlatform: null,
        selectedGame: null,
        loading: false,
        certificates: [],
        deleting: {
          open: false,
          certificate: {}
        },
        fileToUpload: {
          name: '',
        },
        creating: false,
        loadingCreate: false,
        loadingDelete: false,
        nothingFound: false,
      }
    },
    components: {
      Multiselect,
    },
    props: {
      allGames: Array,
      allPlatforms: Array,
      sortByName: Function,
    },
    watch: {
      selectedPlatform(val) {
        if (this.selectedGame !== null && val !== null) {
          this.loadCertificates()
        } else {
          this.certificates = []
        }
      },
      selectedGame(val) {
        if (this.selectedGame !== null && val !== null) {
          this.loadCertificates()
        } else {
          this.certificates = []
        }
      },
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
    },
    methods: {
      cancelCreating() {
        this.creating = false
        this.fileToUpload = {
          name: ''
        }
      },
      handleDragOver(event) {
        event.preventDefault();
      },
      handleFileDrop() {
        this.$refs.newFile.files = dataTransfer.files;
        this.handleFileUpload();
      },
      async handleFileUpload() {
        // TODO: improve this code to be more efficient
        const file = this.$refs.newFile.files[0];
        const fileSrc = URL.createObjectURL(file);
        if(this.fileToUpload.name === '') {
          this.fileToUpload.name = file.name;
        }
        this.fileToUpload.file = file,
        this.fileToUpload.fileName = file.name,
        this.fileToUpload.tempFile = fileSrc
      },
      openDelete(index) {
        this.certificates[index].deleting = true;
      },
      downloadFile(s3Url) {
        const link = document.createElement('a');
        link.href = s3Url;
        link.setAttribute('download', '');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      checkBothSelected() {
        if (this.selectedGame !== null && this.selectedPlatform !== null) {
          this.loadCertificates()
        } else return
      },
      clearDate(date) {
        const options = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
        return new Date(date).toLocaleDateString(this.appLanguage.long, options)
      },
      async loadCertificates() {
        this.loading = true
        try {
          const certificates = (await request.get(`/certificates?gameId=${this.selectedGame}&platformId=${this.selectedPlatform}`)).data
          this.certificates = certificates
          if (certificates.length == 0) {
            this.nothingFound = true
          } else {
            this.nothingFound = false
          }
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      },
      async uploadCertificate() {
        this.loadingCreate = true
        const formData = new FormData()
        formData.append('file', this.fileToUpload.file)
        formData.append('name', this.fileToUpload.name)
        try {
          const response = await request.post(`/certificates/create/${this.selectedGame}/${this.selectedPlatform}`, formData, {
            headers: {
              'Content-Type':'multipart/form-data'
            }
          })
          this.certificates.push(response.data)
          this.nothingFound = false
          this.fileToUpload = {
            name: '',
            file: null
          }
          this.$toast.success('Certificate created')
          this.creating = false
        } catch (e) {
          console.log(e)
        }
        this.loadingCreate = false
      },
      async deleteCertificate(id) {
        this.loadingDelete = true
        try {
          await request.delete(`/certificates/delete/${id}`)
          this.certificates = this.certificates.filter(cert => cert.id !== id)
          this.$toast.success('Certificate deleted')
        } catch (e) {
          console.log(e)
        }
        this.loadingDelete = false
      }
    }
  }

</script>
<style scoped>

</style>
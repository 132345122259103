<template>
  <div
    v-show="fontLoaded"
    @click="clickOut"
    class="w-screen h-screen relative"
    :class="{ dark: isDarkMode }"
  >
    <SideBar v-if="checkValidUser()" />
    <div
      v-if="checkValidUser()"
      class="relative w-full h-full transition-all duration-100 bg-zinc-950"
      :class="[
        sidebarFixed
          ? 'pb-[50px] xl:pb-0 xl:pl-72'
          : 'pb-[50px] xl:pb-0 xl:pl-32',
      ]"
    >
      <MobileSidebarVue />
      <div
        @click="closeMobileSidebar"
        class="h-full w-full transition-all duration-150"
        :class="mobileSidebar && 'brightness-50'"
      >
        <div
          @click="closeMobileSidebar"
          v-if="mobileSidebar"
          class="absolute top-0 h-full w-full bg-black bg-opacity-30 z-40"
        ></div>
        <router-view />
      </div>
    </div>
    <LoginFailed v-else />
    <FloatingJobs :key="jobsKey" />
  </div>
  <div
    v-show="!fontLoaded"
    class="w-screen h-screen flex items-center justify-center gap-4 bg-zinc-950 italic"
  >
    <img
      src="./assets/hub-full-logo.png"
      alt="gai hub logo"
      class="h-16 w-auto animate-pulse"
    />
  </div>
</template>


<script>
import FontFaceObserver from "fontfaceobserver";
import MobileSidebarVue from "./components/MobileSidebar.vue";
import FloatingJobs from "./components/FloatingJobs";
import SideBar from "./components/SideBar.vue";
import LoginFailed from "./views/LoginFailed.vue";

export default {
  data() {
    return {
      fontLoaded: false,
      jobsKey: 0,
    };
  },
  components: {
    SideBar,
    MobileSidebarVue,
    LoginFailed,
    FloatingJobs,
  },
  created() {
    this.$store.dispatch("setScreenWidthAction", window.screen.width);
    this.$store.dispatch("setScreenHeightAction", window.screen.height);
    this.$store.dispatch("filters/getTodayDate");
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    const font = new FontFaceObserver("Fugaz One");
    await font.load();
    this.fontLoaded = true;

    if (
      !this.appLanguage.short ||
      this.appLanguage.short !== "en" ||
      this.appLanguage.short !== "it"
    ) {
      this.$store.dispatch("setLanguageAction", {
        short: "en",
        long: "en-GB",
      });
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    isDarkMode() {
      return this.$store.getters["getDarkMode"];
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
    mobileSidebar() {
      return this.$store.getters["getMobileSidebar"];
    },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
  },
  methods: {
    clickOut() {
      this.emitter.emit("clickOut");
    },
    handleResize() {
      this.$store.dispatch("setScreenWidthAction", window.screen.width);
      this.$store.dispatch("setScreenHeightAction", window.screen.height);
      if (window.screen.width > 1280) {
        this.$store.dispatch("sidebar/setSidebarFixedAction", true);
      }
      this.closeMobileSidebar();
    },
    checkValidUser() {
      if (!this.user?.roles) return false;
      // checking if the user has a platform role but doesn't have any platform associated.
      if (
        !this.user?.localRoles.isLudusAdmin &&
        !this.user?.localRoles.isLudusManager &&
        Object.keys(this.user?.attributes).length === 0
      )
        return false;
      return true;
    },
    closeMobileSidebar() {
      this.clickOut();
      this.$store.dispatch("setMobileSidebarAction", false);
    },
  },
};
</script>

<style>
/* v-if transition options */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<template>
  <div
    class="text-white flex flex-col gap-y-2"
  >
    <h3>Active</h3>
    <div class="flex flex-wrap items-center gap-3">
      <div v-for="(game, index) in activeGames" :key="`actGame${index}`" 
        class="py-2 px-4 rounded-sm bg-zinc-700"
      >
      {{ game.sub_name ? `${game.name}-${game.sub_name}` : game.name }}
      </div>
    </div>
    <div>
      <div class="flex items-center relative">
        <h3>Available</h3>
        <button
          @click="changeEditing"
          class="pl-2 xl:hover:scale-105"
          :class="editing ? 'text-zinc-500' : 'text-green-500'"
        >
          <font-awesome-icon :icon="['fas', 'gears']" />
        </button>
      </div>
      <div class="flex flex-wrap items-center gap-3">
        <button v-for="(game, index) in availableGames" :key="`avaGame${index}`" 
          @click="removeGame(game)"
          class="py-2 px-4 relative rounded-sm"
          :class="[
            (editing ? 'cursor-pointer xl:hover:scale-[1.03]' : 'cursor-default'),
            (gamesToRemove.includes(game.id) ? 'bg-red-500' : 'bg-zinc-600')
          ]"
        >
          {{ game.sub_name ? `${game.name}-${game.sub_name}` : game.name }}
        </button>
      </div>
    </div>
    <div v-if="editing" class="flex flex-col gap-2 items-center">
      <div class="flex w-full justify-start relative">
        <h3>Games to add</h3>
      </div>
      <div class="flex flex-wrap items-center gap-3">
        <div v-for="(game, index) in gamesFromDB" :key="`avaGame${index}`" >
          <button
            @click="addGame(game)"
            class="py-2 px-4 relative rounded-sm"
            :class="[
              (editing ? 'cursor-pointer xl:hover:scale-[1.03]' : 'cursor-default'),
              (gamesToAdd.includes(game.id) ? 'bg-green-500' : 'bg-zinc-600')
            ]"
          >
            <!-- <button class="absolute -top-3 -right-2 xl:hover:scale-110">
              <font-awesome-icon class="text-red-500"  :icon="['fas', 'circle-xmark']" />
            </button> -->
            {{ game.sub_name ? `${game.name}-${game.sub_name}` : game.name }}
          </button>
          <div v-if="gamesToAdd.includes(game.id)" class="flex items-center justify-center gap-1 mt-1">
            Notify?
            <button 
              @click="gamesToNotify.push(game.id)" 
              class="bg-green-600 px-2 py-0 rounded-md" 
              :class="gamesToNotify.includes(game.id) && 'border-2'">
              Yes
            </button>
            <button 
              @click="removeNotifyGame(game.id)" 
              class="bg-red-500 px-2 py-0 rounded-md" 
              :class="!gamesToNotify.includes(game.id) && 'border-2'">
              No
            </button>
          </div>
        </div>
      </div>
      <button @click="savePlatformGames" class="fugaz uppercase mt-2 text-lg xl:hover:scale-105">
        Save
      </button>
    </div>
    <!-- <h3>Requested</h3>
    <div class="flex flex-wrap items-center gap-3">
      <div v-for="(game, index) in games.filter(game => game.platform_games.is_requested !== null)" :key="`avaGame${index}`" 
        class="py-2 px-4 relative rounded-sm"
        :class="game.platform_games.is_active ? 'bg-zinc-500' : 'bg-zinc-800 '"
      >
        {{ game.name }}
      </div>
    </div> -->
  </div>
</template>

<script>
  import request from "../../services/axios";
  export default {
    data() {
      return {
        editing: false,
        actualGames: [],
        gamesToAdd: [],
        gamesToRemove: [],
        gamesFromDB: [],
        gamesToNotify: []
      }
    },
    props: {
      games: Array,
      platformId: Number,
    },
    created() {
      this.getGamesFromDB()
    },
    mounted() {
      this.actualGames = [...this.games]
    },
    computed: {
      activeGames() {
        return this.actualGames.filter(game => game.platform_games.is_active !== null)
      },
      availableGames() {
        return this.actualGames.filter(game => game.platform_games.is_active === null)
      },
    },
    methods: {
      changeEditing() {
        if (!this.editing) this.editing = true
        else this.editing = false
      },
      addGame(game) {
        if (!this.editing) return
        else {
          if(this.gamesToAdd.includes(game.id)) {
            const index = this.gamesToAdd.indexOf(game.id)
            this.gamesToAdd.splice(index, 1)
          } else {
            this.gamesToAdd.push(game.id)
          }
        }
      },
      removeGame(game) {
        if (!this.editing) return
        else {
          if(this.gamesToRemove.includes(game.id)) {
            const index = this.gamesToRemove.indexOf(game.id)
            this.gamesToRemove.splice(index, 1)
          } else {
            this.gamesToRemove.push(game.id)
          }
        }
      },
      removeNotifyGame(gameId) {
        const index = this.gamesToNotify.indexOf(gameId)
        this.gamesToNotify.splice(index, 1)
      },
      async sendGameEmails() {
        try {
          const response = await request.post(`/platform-new-games/${this.platformId}`, { gameIds: this.gamesToNotify })
          this.$toast.success(response.data)
        } catch (e) {
          console.log(e)
          this.$toast.error("Error sending emails")
        }
      },
      async getGamesFromDB() {
        const games = (await request.get('/games')).data.rows
        const availableGamesIds = this.availableGames.map(game => game.id)
        const activeGamesIds = this.activeGames.map(game => game.id)
        this.gamesFromDB = games.filter(game => !availableGamesIds.includes(game.id) && !activeGamesIds.includes(game.id) && game.isCatalog)
      },
      async savePlatformGames() {
        const ids = this.games.filter(game => !this.gamesToRemove.includes(game.id)).map(game => game.id)
        const newIds = ids.concat(this.gamesToAdd)
        try {
          const response = await request.post(`/platforms/${this.platformId}/games`, {
            games: newIds
          })
          // console.log(response.data)
          this.actualGames = response.data
          this.editing = false
          this.gamesToAdd = []
          this.gamesToRemove = []
          // this.games
          this.emitter.emit('platformGamesUpdated')
          if(this.gamesToNotify.length > 0) {
            this.$toast.success('Games updated successfully, sending emails...')
            this.sendGameEmails()
          } else this.$toast.success('Games updated successfully')
        } catch (e) {
          console.log(e)
          this.$toast.error(e.message)
        }
      }
    }
  }
</script>

<style scoped>

</style>
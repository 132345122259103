import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
    // clientSecret: 'haarPjeqVjjm4i4vrQLcp1SurROsGIBz',
    onLoad: 'login-required',
    scope: "openid profile email",
    "verify-token-audience": true,
    // "credentials": {
    //     "secret": "secret"
    // },
    // "use-resource-role-mappings": true,
    "confidential-port": 0,
    realmPublicKey: process.env.VUE_APP_KEYCLOAK_PUBLIC_KEY,
    // "policy-enforcer": {
    //     "credentials": {}
    // }
};

// const keycloakConfig = {
//     "realm": "hub",
//     "auth-server-url": "http://keycloak.hub.devo:8000/",
//     "ssl-required": "none",
//     "resource": "hub-backoffice-client",
//     "verify-token-audience": true,
//     "credentials": {
//         "secret": "vnzEw7sR3i8HQWnw3grWr0RAYmpPw1bw"
//     },
//     "use-resource-role-mappings": true,
//     "confidential-port": 0,
//     "policy-enforcer": {
//         "credentials": {}
//     }
// }

const keycloak = new Keycloak(keycloakConfig);

// console.log(keycloak, keycloakConfig);

export { keycloak, keycloakConfig };

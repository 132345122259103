<template>
  <div v-if="ready" class="w-full h-full bg-zinc-800 rounded-b-md flex flex-col gap-2 p-2">
    <div class="flex flex-col md:flex-row justify-between items-center relative py-2 px-2 bg-zinc-950 rounded-md">
      <div class="flex items-center justify-start gap-3 w-full">
        <h4 class="text-white fugaz uppercase pl-1.5">{{ $t('manageGames.game') }}</h4>
        <div class="w-full md:w-[250px] max-w-full border-zinc-800 rounded-sm">
          <Multiselect
            mode="single"
            v-model="selectedGameId"
            :options="allGames"
            :close-on-select="true"
            :searchable="true"
            label="name"
            value-prop="id"
            :placeholder="$t('manageGames.selectGame')"
            ref="multiselect"
          />
        </div>
      </div>
      <div v-show="hasChanges" class="w-full flex justify-center md:justify-end pt-2.5 md:pt-0">
        <button v-if="!saving" @click="saveChanges" class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-opacity-50 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50">
          {{ $t('manageGames.saveChanges') }}
        </button>
        <div v-else class="text-white fugaz uppercase px-3">
          {{ $t('manageGames.saving') }}
        </div>
      </div>
    </div>
    <div v-if="selectedGameId" class="flex flex-col xl:flex-row gap-1.5 xl:gap-0 items-center justify-between h-full w-full">
      <div 
        class="h-full w-full flex flex-col items-center rounded-md xl:rounded-none xl:rounded-l-md bg-zinc-950 pb-3">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2 xl:pb-1">{{ $t('manageGames.notVisible') }}</h3>\
        </div>
        <div v-if="notVisiblePlatforms.length > 0" class="flex flex-col gap-1.5 text-white px-2.5 h-full w-full max-h-[585px] overflow-y-auto">
          <div v-for="platform in notVisiblePlatforms" class="py-2 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center relative">
            <button @click="addPlatform(platform)" class="group px-4 py-1 rounded-sm bg-green-600 xl:hover:bg-green-500 absolute top-0 bottom-0 my-auto right-0 uppercase">
              <font-awesome-icon :icon="['fas', 'right-long']" class="hidden xl:block" />
              <font-awesome-icon :icon="['fas', 'down-long']" class="block xl:hidden" />
            </button>
            {{ platform.name }}
          </div>
        </div>
        <div v-if="notVisiblePlatforms.length === 0 && selectedGameId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
      <div class="h-full w-full flex flex-col items-center bg-zinc-950 rounded-md xl:rounded-none pb-3 xl:border-x border-zinc-800">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2 xl:pb-1">{{ $t('manageGames.viewing') }}</h3>
        </div>
        <div v-if="visiblePlatforms.length > 0" class="flex flex-col gap-1.5 text-white px-2.5 h-full w-full max-h-[585px] overflow-y-auto">
          <div v-for="platform in visiblePlatforms" class="py-2 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center relative">
            <button @click="removePlatform(platform)" class="group px-4 py-1 rounded-sm bg-red-600 xl:hover:bg-red-500 absolute top-0 bottom-0 my-auto right-0 xl:right-auto xl:left-0 uppercase">
              <font-awesome-icon :icon="['fas', 'left-long']" class="hidden xl:block" />
              <font-awesome-icon :icon="['fas', 'up-long']" class="block xl:hidden" />
            </button>
            <span v-if="platform.toAdd" class="text-green-500 absolute top-0 left-1 xl:left-auto right-auto xl:right-1 text-xs">
              {{ $t('manageGames.new') }}
            </span>
            <span v-if="platform.toAdd" class="text-white flex items-center gap-1 absolute bottom-0 left-1 xl:left-auto xl:right-1 text-xs">
              {{ $t('manageGames.notify') }}
              <button 
                @click="addPlatformToNotify(platform.id)" 
                class="fugaz uppercase"
                :class="platformsToNotify.includes(platform.id) ? 'text-green-500' : 'text-zinc-500'"
              >
                {{ $t('manageGames.yes') }}
              </button>
              <button 
                @click="removePlatformToNotify(platform.id)" 
                class="fugaz uppercase"
                :class="!platformsToNotify.includes(platform.id) ? 'text-red-500' : 'text-zinc-500'"
              >
                {{ $t('manageGames.no') }}
              </button>
            </span>
            {{ platform.name }}
          </div>
        </div>
        <div v-if="visiblePlatforms.length === 0 && selectedGameId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
      <div class="h-full w-full flex flex-col items-center rounded-r-md bg-zinc-950 pb-3">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2 xl:pb-1">{{ $t('manageGames.owned') }}</h3>
          <!-- <span class="text-sm fugaz uppercase text-white">(active)</span> -->
        </div>
        <div v-if="activePlatforms.length > 0" class="flex flex-col gap-1.5 text-white px-2.5 h-full w-full max-h-[585px] overflow-y-auto">
          <div v-for="platform in activePlatforms" class="py-2 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center">
            {{ platform.name }}
          </div>
        </div>
        <div v-if="activePlatforms.length === 0 && selectedGameId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full h-full flex justify-center items-center gap-3 text-zinc-600 dark:text-zinc-400"
  >
    <img v-if="!serverError" src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-16 w-auto animate-pulse">
    <img
      v-if="!serverError"
      src="../../assets/loading2.png"
      alt="loading icon"
      class="h-12 animate-spin mb-2"
    />
    <div v-else>
      {{ $t('serverError') }}
      <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
      {{ $t('tryAgain') }}
    </div>
  </div>
</template>
<script>
  import request from '@/services/axios'
  import Multiselect from '@vueform/multiselect'

  export default {
    data() {
      return {
        selectedGameId: null,
        selectedGame: null,
        notVisiblePlatforms: [],
        visiblePlatforms: [],
        activePlatforms: [],
        platformsToAdd: [],
        platformsToRemove: [],
        platformsToNotify: [],
        ready: false,
        serverError: false,
        saving: false,
      }
    },
    props: {
      allGames: Array,
      allPlatforms: Array,
      sortByName: Function,
    },
    components: {
      Multiselect,
    },
    async mounted() {
      this.ready = true
    },
    watch: {
      selectedGameId: {
        async handler() {
          this.updatePlatforms()
          this.$refs.multiselect.blur()
        },
      },
    },
    computed: {
      hasChanges() {
        return this.platformsToAdd.length > 0 || this.platformsToRemove.length > 0
      },
    },
    methods: {
      addPlatformToNotify(platId) {
        this.platformsToNotify.push(platId)
      },
      removePlatformToNotify(platId) {
        this.platformsToNotify = this.platformsToNotify.filter(id => id !== platId)
      },
      addPlatform(platform) {
        if(platform.toRemove) {
          const index = this.platformsToRemove.indexOf(platform.id)
          this.platformsToRemove.splice(index, 1)
          this.visiblePlatforms.push(platform)
        } else {
          this.platformsToAdd.push(platform.id)
          this.visiblePlatforms.push({
            ...platform,
            toAdd: true
          })
        }
        const index = this.notVisiblePlatforms.map(g => g.id).indexOf(platform.id)
        this.notVisiblePlatforms.splice(index, 1)
        
        this.notVisiblePlatforms.sort(this.sortByName)
        this.visiblePlatforms.sort(this.sortByName)
      },
      removePlatform(platform) {
        if(platform.toAdd) {
          const index = this.platformsToAdd.indexOf(platform.id)
          this.platformsToAdd.splice(index, 1)
          this.notVisiblePlatforms.push(platform)
        } else {
          this.platformsToRemove.push(platform.id)
          this.notVisiblePlatforms.push({
            ...platform,
            toRemove: true
          })
        }
        const index = this.visiblePlatforms.map(g => g.id).indexOf(platform.id)
        this.visiblePlatforms.splice(index, 1)
        this.notVisiblePlatforms.sort(this.sortByName)
        this.visiblePlatforms.sort(this.sortByName)
      },
      resetInfo() {
        this.selectedGame = null
        this.platformsToNotify = []
        this.activePlatforms = []
        this.visiblePlatforms = []
        this.notVisiblePlatforms = []
        this.platformsToAdd = []
        this.platformsToRemove = []
      },
      updatePlatforms() {
        if (this.selectedGameId) {
          this.resetInfo()
          this.selectedGame = this.allGames.find(g => g.id == this.selectedGameId)
          this.activePlatforms = this.selectedGame.platforms.filter(platform => platform.platform_games.is_active !== null)
          this.visiblePlatforms = this.selectedGame.platforms.filter(platform => platform.platform_games.is_active === null)
          this.notVisiblePlatforms = this.allPlatforms.filter(platform => {
            const activeIds = this.activePlatforms.map(plat => plat.id)
            const visibleIds = this.visiblePlatforms.map(plat => plat.id)
            if (!activeIds.includes(platform.id) && !visibleIds.includes(platform.id)) return true
            else return false
          })
          this.notVisiblePlatforms.sort(this.sortByName)
          this.visiblePlatforms.sort(this.sortByName)
        } else {
          this.resetInfo()
        }
      },
      async sendGameEmails() {
        try {
          const response = await request.post(`/notify-game-platforms/${this.selectedGameId}`, { platformIds: this.platformsToNotify })
          this.$toast.success(response.data)
        } catch (e) {
          console.log(e)
          this.$toast.error("Error sending emails")
        }
      },
      async saveChanges() {
        this.saving = true
        try {
          const url = this.selectedGame.providerId
            ? `/games/update/${this.selectedGameId}?providerName=gamestorage`
            : `/games/update/${this.selectedGameId}`;
            
          await request.put(`/games/update/${this.selectedGameId}?providerName=gamestorage`, {
            ...this.selectedGame,
            platformsToAdd: this.platformsToAdd,
            platformsToRemove: this.platformsToRemove,
          })
          const updatedGameWithPlatforms = (await request.get(`/games/${this.selectedGameId}?with=platforms`)).data
          this.selectedGame = updatedGameWithPlatforms
          const index = this.allGames.map(p => p.id).indexOf(this.selectedGame.id)
          this.allGames.splice(index, 1, this.selectedGame)
          if (this.platformsToNotify.length > 0) {
            console.log('sending emails')
            await this.sendGameEmails()
          }
          this.updatePlatforms()
          // this.games
          this.$toast.success(`${this.selectedGame.name} - ${this.$t('manageGames.platformsUpdated')}`)
          this.emitter.emit('gamePlatformsUpdated');
        } catch (e) {
          console.log(e)
          this.$toast.error(e.message)
        }
        this.saving = false
      },

    },
  }

</script>
<style scoped>

</style>
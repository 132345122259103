const state = {
  gamesToRequest: [],
  requestingGames: false,
}
const getters = {
  gamesToRequest: state => state.gamesToRequest,
  requestingGames: state => state.requestingGames
}
const actions = {
  addGamesToRequestAction({commit}, payload) {
    commit('addGamesToRequest', payload)
  },
  removeGamesToRequestAction({commit}, index) {
    commit('removeGamesToRequest', index)
  },
  setRequestingGamesAction({commit}, payload) {
    commit('setRequestingGames', payload)
  }
}
const mutations = {
  addGamesToRequest(state, payload) {
    state.gamesToRequest.push(payload)
  },
  removeGamesToRequest(state, index) {
    state.gamesToRequest.splice(index, 1)
  },
  setRequestingGames(state, payload) {
    state.requestingGames = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

import { createRouter, createWebHistory } from "vue-router";
// game routes
import Catalog from "../views/games/Catalog.vue";
import EditGame from "../views/games/EditGame.vue";
import Game from "../views/games/Game.vue";
import Games from "../views/games/Games.vue";
import ReviewRequested from "../views/games/ReviewRequested.vue";
// platform routes
import Platforms from "../views/platforms/Platforms.vue";
import NewPlatform from "../views/platforms/NewPlatform.vue";
import Platform from "../views/platforms/Platform.vue";
// admin routes
import Maintenances from "../views/admin/Maintenances.vue";
import Operators from "../views/admin/Operators.vue";
import Sessions from "../views/admin/Sessions.vue";
import SessionsList from "../views/admin/SessionsList.vue";
import Charts from "../views/admin/Charts.vue";
import Logs from "../views/admin/Logs.vue";
import Bets from "../views/admin/Bets.vue";
import Users from "../views/admin/Users.vue";
// common routes
import Dashboard from "../views/Dashboard.vue";
import Notifications from "../views/Notifications.vue";
import Settings from "../views/Settings.vue";
import MailingList from "../views/MailingList.vue";
import Ticket from "../views/Ticket.vue";
import NewTicket from "../views/NewTicket.vue";
import Tickets from "../views/Tickets.vue";
import store from "@/store";

const routes = [
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/'
  // },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    // redirect: '/catalog',
    meta: { blockedRoles: ["platform_guest", "platform_manager"] },
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: Catalog,
    meta: { blockedRoles: ["platform_manager"] },
  },
  {
    path: "/operators",
    name: "Operators",
    component: Operators,
    meta: { requiredRoles: ["ludus_admin"] },
  },
  {
    path: "/platforms",
    name: "Platforms",
    component: Platforms,
    meta: { requiredRoles: ["ludus_admin", "ludus_manager"] },
  },
  {
    path: "/game/:id?",
    name: "Game",
    component: Game,
    props: true,
    meta: { blockedRoles: ["platform_manager"] },
  },
  {
    path: "/games/:stepNumber?",
    name: "Games",
    component: Games,
    props: true,
    meta: { requiredRoles: ["ludus_admin"] },
  },
  {
    path: "/editGame/:id?",
    name: "EditGame",
    component: EditGame,
    props: true,
    meta: { requiredRoles: ["ludus_admin"] },
  },
  {
    path: "/platform/:id?",
    name: "Platform",
    component: Platform,
    props: true,
    meta: { requiredRoles: ["ludus_admin", "ludus_manager"] },
  },
  {
    path: "/review",
    name: "ReviewRequested",
    component: ReviewRequested,
    props: {
      games: Array,
    },
    meta: { blockedRoles: ["platform_guest"] },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/maintenances",
    name: "Maintenances",
    component: Maintenances,
    meta: { requiredRoles: ["ludus_admin"] },
  },
  {
    path: "/new-platform",
    name: "New platform",
    component: NewPlatform,
    meta: { requiredRoles: ["ludus_admin", "ludus_manager"] },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: Sessions,
    meta: { blockedRoles: ["platform_guest"] },
  },
  {
    path: "/session-list",
    name: "Sessions List",
    component: SessionsList,
    meta: { blockedRoles: ["platform_guest"] },
  },
  {
    path: "/charts",
    name: "Charts",
    component: Charts,
    meta: { blockedRoles: ["platform_guest", "platform_manager"] },
  },
  {
    path: "/bets",
    name: "Bets",
    component: Bets,
    meta: { requiredRoles: ["ludus_admin"] },
  },
  {
    path: "/logs",
    name: "Logs",
    component: Logs,
    meta: { requiredRoles: ["ludus_admin", "ludus_manager"] },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { requiredRoles: ["ludus_admin", "platform_admin"] },
  },
  {
    path: "/ticket/:id?",
    name: "Ticket",
    component: Ticket,
    props: true,
    meta: { requiredRoles: ["ludus_admin", "platform_admin"] },
  },
  {
    path: "/new-ticket/:propSessionId?",
    name: "NewTicket",
    component: NewTicket,
    props: true,
    meta: { requiredRoles: ["ludus_admin", "platform_admin"] },
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: Tickets,
    meta: { requiredRoles: ["ludus_admin", "platform_admin"] },
  },
  {
    path: "/mailing-list",
    name: "MailingList",
    component: MailingList,
    meta: { requiredRoles: ["ludus_admin"] },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// combine this 2 before each
const removeToken = (to, from, next) => {
  if (to.path.includes("&")) {
    const splitedUrl = to.path.split("&");
    const newPath = splitedUrl[0];
    next({ path: newPath, replace: true });
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => {
  const requiredRoles = to.meta.requiredRoles;
  const blockedRoles = to.meta.blockedRoles;
  const userRoles = store.getters["user/roles"];

  const gettingNotifications = store.getters["user/gettingNotifications"];

  const date = store.getters["user/lastGetNotifications"];
  const now = new Date();
  const diff = now - date;
  const diffInMinutes = diff / (1000 * 60);

  if (!gettingNotifications && diffInMinutes > 1) {
    if (userRoles) store.dispatch("user/setNotificationsAction");
  }

  if (requiredRoles?.length > 0 || blockedRoles?.length > 0) {
    // Check if the user has the required roles
    const hasRequiredRoles = requiredRoles?.some((role) =>
      userRoles?.includes(role)
    );
    const hasBlockedRoles = blockedRoles?.some((role) =>
      userRoles?.includes(role)
    );

    if (
      (requiredRoles && !hasRequiredRoles) ||
      (blockedRoles && hasBlockedRoles)
    ) {
      // User does not have the required roles or has a blocked role, redirect to an unauthorized page or handle it accordingly
      if (userRoles?.includes("platform_manager")) {
        next("/sessions");
      } else {
        next("/catalog");
      }
    } else {
      // User has the required roles, allow navigation
      removeToken(to, from, next);
    }
  } else removeToken(to, from, next);
});

/*
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    console.log('this route requires auth');
    const isAuthenticated = await keycloak.init({
      onLoad: 'check-sso',
      promiseType: 'native',
    });

    console.log('keycloak authenticated?');
    console.log(isAuthenticated);

    if (isAuthenticated) {
      // User is authenticated, proceed to the route
      return next();
    } else {
      // User is not authenticated, redirect to Keycloak login
      // keycloak.login();
      keycloak.login({
        redirectUri: window.location.href, // Redirect back to the current page after login
      });
    }
  } else {
    // Route doesn't require authentication, proceed
    return next();
  }
});
*/
export { router };

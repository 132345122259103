<template>
  <div
    
    class="w-full h-full max-w-full bg-zinc-950 flex flex-col"
  >
    <div class="flex justify-between pt-4 pb-2 px-4 md:px-7 transition-all duration-100 w-full">
      <h2 class="text-3xl xl:text-4xl fugaz uppercase text-white">{{ $t('catalog.title') }}</h2>
      <div class="pb-1.5 flex justify-end items-center relative text-lg lg:text-xl">
        <button @click="toggleSearch" class="">
          <font-awesome-icon
            :icon="['fas', 'magnifying-glass']"
            class="text-orange-600"
          />
          <span v-if="!searchBox" class="hidden md:inline pl-2 fugaz uppercase truncate transition-all duration-150 text-white" 
            :class="searchBox ? 'w-max' : 'w-0'">
            {{ $t('catalog.search') }}
          </span>
        </button> 

        <input
          v-if="searchBox"
          v-model="search"
          name="search"
          type="text"
          placeholder=""
          ref="search"
          @blur="toggleSearch"
          class="ml-2 outline-none border-b border-zinc-900 transition-all duration-50 text-white pt-0.5 bg-zinc-950 w-32 md:w-52 xl:w-64"
        />
        <button
          v-if="searchBox && search.length"
          @click="closeSearch"
          class="absolute -top-1 -right-1 p-2"
        >
          <font-awesome-icon :icon="['fas', 'xmark']" class="text-zinc-500" />
        </button>
      </div>
    </div>
    <div v-if="ready" class="flex flex-col pb-[49px] xl:pb-0 bg-zinc-950">

      <!-- Home Header -->
      <div
        class="flex justify-between items-center pl-2 w-full bg-zinc-950 text-white z-30 shadow-sm border-zinc-700"
        ref="homeHeader"
      >
        <div class="flex justify-between w-full h-12">
          <div class="gap-x-3 items-center xl:w-[20%] min-w-max flex pl-0 md:pl-4 xl:pl-6">
            <!-- <div class="flex xl:hidden dark:bg-zinc-800 h-full item-center justify-center">
              <img src="../../assets/GAI_LOGO.png" alt="gai gub logo" class="w-auto h-12 object-cover">
            </div> -->
            <button
              @click="$store.dispatch('catalog/setItemsDisplayAction', 'grid')"
              class=""
              :class="itemsDisplay == 'grid' ? 'invert' : 'invert-50'"
            >
              <img src="../../assets/menu.png" class="h-6" alt="grid icon" />
            </button>
            <button
              @click="$store.dispatch('catalog/setItemsDisplayAction', 'list')"
              :class="itemsDisplay == 'list' ? 'invert' : 'invert-50'"
            >
              <img src="../../assets/list.png" class="h-6" alt="list icon" />
            </button>
            <div
              v-if="itemsDisplay == 'grid'"
              class="ml-2 text-white text-sm fugaz uppercase pt-0.5"
            >
              <label for="sort" class="">{{ $t('catalog.sortBy') }}</label>
              <select
                v-model="sort"
                id="sort"
                class="outline-none rounded-md text-sm text-center ml-3 bg-zinc-950 fugaz uppercase"
              >
                <option 
                  v-for="n in [
                    { label: $t('catalog.releaseDate'), value: 'release_date' },
                    { label: $t('catalog.name'), value: 'name' }
                  ]" 
                  :key="n.label" 
                  :value="n.value"
                >
                  {{ n.label }}
                </option>
              </select>
            </div>
            <div
              v-if="itemsDisplay == 'list'"
              class="ml-2 text-white text-sm fugaz uppercase pt-0.5 block md:hidden truncate"
            >
              <label for="sort" class="">
                <span :class="screenWidth > 400 ? 'inline' : 'hidden'">{{ $t('catalog.select') }}</span> 
                {{ $t('catalog.category') }}
              </label>
              <select
                v-model="mobileCategory"
                id="sort"
                class="outline-none rounded-md text-sm text-center ml-3 bg-zinc-950 fugaz uppercase border-none"
              >
                <option v-for="n in 
                  [
                    {label: `${$t('catalog.all')} (${getAllGames.length})`, value: 'all'},
                    {
                      label: `${$t('catalog.inDev')} (${devGames.length})`, 
                      value: 'inDev'
                    },
                    {label: `${$t('catalog.available')} (${availableGames.length})`, value: 'available'},
                    {label: `active (${activeGames.length})`, value: 'active'}
                  ]" 
                  :key="n.value" :value="n.value"
                >
                  {{ n.label }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="itemsDisplay == 'grid'"
            class="hidden md:flex items-center justify-center px-2 gap-x-4 fugaz uppercase text-sm w-[60%]"
          >
            <label class="flex items-center checkbox pl-0">
              <div
                class="flex items-center truncate"
                :class="allSelected ? 'text-white' : 'text-zinc-400'"
              >
                {{ $t('catalog.all') }}
                <span class="text-orange-600 text-[17px] pl-1">
                  {{ getAllGames.length }}
                </span>
              </div>
              <input
                type="checkbox"
                @click="selectAll()"
                :checked="allSelected"
              />
            </label>
            <label class="flex items-center checkbox" :class="(devGames.length == 0 || !inDevelopment) && 'grayscale'">
              <div
                class="flex items-center truncate text-[14px]"
                :class="inDevelopment ? 'text-white' : 'text-zinc-400'"
              >
                {{ $t('catalog.inDev') }} <span class="text-orange-600 text-[17px] pl-1">{{ devGames.length }}</span>
              </div>
              <input
                type="checkbox"
                @click="inDevelopment = !inDevelopment"
                :disabled="devGames.length == 0"
                :checked="inDevelopment"
              />
              <!-- <span class="checkmark"></span> -->
            </label>
            <label class="flex items-center checkbox" :class="(availableGames.length == 0 || !available) && 'grayscale opacity-50'">
              <div
                class="flex items-center truncate text-[14px]"
                :class="available ? 'text-white' : 'text-zinc-400'"
              >
                {{ $t('catalog.available') }} <span class="text-orange-600 text-[17px] pl-1">{{ availableGames.length }}</span>
              </div>
              <input
                type="checkbox"
                @click="available = !available"
                :disabled="availableGames.length == 0"
                :checked="available"
              />
              <!-- <span class="checkmark"></span> -->
            </label>
            <label class="flex items-center checkbox" :class="(activeGames.length == 0 || !active) && 'grayscale'">
              <div
                class="flex items-center truncate text-[14px]"
                :class="active ? 'text-white' : 'text-zinc-400'"
              >
                {{ $t('catalog.active') }} <span class="text-orange-600 text-[17px] pl-1">{{ activeGames.length }}</span>
              </div>
              <input
                type="checkbox"
                @click="active = !active"
                :disabled="activeGames.length == 0"
                :checked="active"
              />
              <!-- <span class="checkmark"></span> -->
            </label>
          </div>
          <div
            v-else
            class="hidden md:flex flex-col sm:flex-row gap-x-4 w-[57%] sticky top-0 z-30 justify-center"
          >
            <div
              class="flex items-center justify-center gap-x-2.5 sm:gap-x-4 mt-0 mb-1 sm:mb-0 w-full"
            >
              <div
                @click="changeCategory('all')"
                class="group cursor-pointer"
              >
                <h2
                  class="fugaz uppercase flex items-center gap-x-1 text-[14px] transition-all duration-300"
                  :class="[
                    (viewAllList
                      ? 'text-orange-600'
                      : 'text-white group-hover:text-purple-500'),
                    (getAllGames.length === 0 ? 'grayscale opacity-50 group-hover:text-white' : '')
                  ]"
                  
                >
                  <span class="pb-0.5">{{ $t('catalog.all') }}</span>
                  <span class="text-[17px]" :class="viewAllList ? 'text-white' : 'text-orange-600'">
                    {{ getAllGames.length }}
                  </span>
                </h2>
              </div>
              <div
                @click="changeCategory('inDev')"
                class="group cursor-pointer"
              >
                <h2
                  class="fugaz uppercase flex items-center gap-x-1 text-[14px] transition-all duration-300"
                  :class="[
                    (viewInDevList
                      ? 'text-orange-600'
                      : 'text-white group-hover:text-purple-500'),
                    (devGames.length === 0 ? 'grayscale opacity-50 group-hover:text-white' : '')
                  ]"
                >
                  <span class="hidden sm:block pb-0.5">{{ $t('catalog.inDev') }} </span>
                  <span class="block sm:hidden pb-0.5">{{ $t('catalog.inDevMobile') }} </span>
                  <span class="text-[17px]" :class="viewInDevList ? 'text-white' : 'text-orange-600'">
                    {{ devGames.length }}
                  </span>
                </h2>
              </div>
              <button
                @click="changeCategory('available')"
                class="group cursor-pointer"
                :disabled="availableGames.length === 0"
              >
                <h2
                  class="fugaz uppercase flex items-center gap-x-1 text-[14px] transition-all duration-300"
                  :class="[
                    (viewAvailableList
                      ? 'text-orange-600'
                      : 'text-white group-hover:text-purple-500'),
                    (availableGames.length === 0 ? 'grayscale opacity-50 group-hover:text-white' : '')
                  ]"
                >
                  <span class="pb-0.5">{{ $t('catalog.available') }}</span>
                  <span class="text-[17px]" :class="viewAvailableList ? 'text-white' : 'text-orange-600'">
                    {{ availableGames.length }}
                  </span>
                </h2>
              </button>
              <div
                @click="changeCategory('active')"
                class="group cursor-pointer"
              >
                <h2
                  class="fugaz uppercase flex items-center gap-1 text-[14px] transition-all duration-300"
                  :class="[
                    (viewActiveList
                      ? 'text-orange-600'
                      : 'text-white group-hover:text-purple-500'),
                    (activeGames.length === 0 ? 'grayscale opacity-50' : '')
                  ]"
                >
                  <span class="pb-0.5">{{ $t('catalog.active') }}</span>
                  <span class="text-[17px]" :class="viewActiveList ? 'text-white' : 'text-orange-600'">
                    {{ activeGames.length }}
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div class="md:w-[5%] xl:w-[20%]"></div>
        </div>
      </div>
      <!-- Content  -->
      <div
        v-show="itemsDisplay == 'grid' && gamesFromDB.length"
        class="pt-2 pb-4 z-10 w-full text-white bg-zinc-950"
      >
        <div
          v-show="(!inDevelopment || devGames.length == 0) && 
          (!available || availableGames.length == 0) && 
          (!active || activeGames.length == 0)"
          class="text-lg text-white ml-4 pt-5"
        >
          No content to display
        </div>
        <div
          v-show="inDevelopment && devGames.length > 0"
          class="mb-4 pt-2.5 pb-1 w-full sticky top-0 z-[50] bg-zinc-950"
        >
          <h2 class="fugaz uppercase text-xl md:text-2xl pl-3 md:pl-8 text-orange-600">
            {{ $t('catalog.inDev') }}
          </h2>
          <div class="w-64 h-1.5 md:h-2 bg-orange-600 ml-3 md:ml-8"></div>
        </div>
        <GridGames
          v-show="inDevelopment && devGames.length > 0"
          :games="devGames"
          :setGamesOrder="setGamesOrder"
        />
        <div
          v-show="available && availableGames.length > 0"
          class="mb-4 pt-2.5 pb-1 w-full sticky top-0 z-[50] bg-zinc-950"
        >
          <h2 class="fugaz uppercase text-xl md:text-2xl pl-3 md:pl-8 text-orange-600">
            {{ $t('catalog.availableGames') }}
          </h2>
          <div class="w-64 h-1.5 md:h-2 bg-orange-600 ml-3 md:ml-8"></div>
        </div>
        <GridGames
          v-show="available && availableGames.length > 0"
          :games="availableGames"
          :setGamesOrder="setGamesOrder"
        />
        <div
          v-show="active && activeGames.length > 0"
          class="mb-4 pt-2.5 pb-1 w-full sticky top-0 z-[50] bg-zinc-950"
        >
          <h2 class="fugaz uppercase text-xl md:text-2xl pl-3 md:pl-8 text-orange-600">
            {{ $t('catalog.activeGames') }}
          </h2>
          <div class="w-64 h-1.5 md:h-2 bg-orange-600 ml-3 md:ml-8"></div>
        </div>
        <GridGames
          v-show="active && activeGames.length > 0"
          :games="activeGames"
          :setGamesOrder="setGamesOrder"
        />
      </div>
      <div
        v-show="itemsDisplay == 'list' && gamesFromDB.length"
        class="flex flex-col pt-0 xl:pt-4 pb-4 px-2 md:px-6 xl:px-8 w-full bg-zinc-950"
      >
        <!-- <div class="flex justify-between items-center gap-x-4">
          <h2 class="fugaz uppercase text-5xl pt-6 -mb-2 text-white">
            Discover our games
          </h2>
        </div> -->
        <div class="relative w-full">
          <table
            class="table table-auto w-full border-separate border-spacing-y-[3px]"
          >
            <thead
              class="bg-zinc-800 text-white sticky z-20 top-0"
            >
              <tr class="fugaz">
                <th
                  class="py-3 text-left text-md uppercase tracking-wider"
                >
                  <span
                    @click="sortTable('name')"
                    class="cursor-pointer flex items-center w-max font-normal ml-[59%]"
                  >
                    <!-- ghost icon -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-2 px-2 invisible" />
                    {{ $t('catalog.name') }}
                    <font-awesome-icon
                      v-if="tableSort == 'name'"
                      :icon="['fas', 'sort-down']"
                      class="pb-2 px-2"
                    />
                    <font-awesome-icon
                      v-if="tableSort == 'nameInverse'"
                      :icon="['fas', 'sort-up']"
                      class="pt-1 px-2"
                    />
                    <!-- ghost icon -->
                    <font-awesome-icon
                      v-if="tableSort !== 'name' && tableSort !== 'nameInverse'"
                      :icon="['fas', 'sort-down']"
                      class="pb-2 px-2 invisible" 
                    />
                  </span>
                </th>
                <th
                  class="hidden lg:table-cell py-3 text-center text-md uppercase tracking-wider"
                >
                  <span
                    @click="sortTable('rtp')"
                    class="cursor-pointer w-max font-normal whitespace-nowrap"
                  >
                    <!-- ghost icon -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-1 invisible" />
                    {{ $t('catalog.rtp') }}
                    <font-awesome-icon
                      v-if="tableSort == 'rtp'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-1"
                    />
                    <font-awesome-icon
                      v-if="tableSort == 'rtpInverse'"
                      :icon="['fas', 'sort-up']"
                      class=" px-1"
                    />
                    <!-- ghost icon -->
                    <font-awesome-icon
                      v-if="tableSort !== 'rtp' && tableSort !== 'rtpInverse'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-1 invisible" 
                    />
                  </span>
                </th>
                <th
                  class="hidden lg:table-cell py-3 text-center text-md uppercase tracking-wider"
                >
                  <span
                    @click="sortTable('volatility')"
                    class="cursor-pointer w-max font-normal whitespace-nowrap"
                  >
                    <!-- ghost icon -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-1 invisible" />
                    {{ $t('catalog.volatility') }}
                    <font-awesome-icon
                      v-if="tableSort == 'volatility'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-1"
                    />
                    <font-awesome-icon
                      v-if="tableSort == 'volatilityInverse'"
                      :icon="['fas', 'sort-up']"
                      class="px-1"
                    />
                    <!-- ghost icon -->
                    <font-awesome-icon
                      v-if="tableSort !== 'volatility' && tableSort !== 'volatilityInverse'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-1 invisible" 
                    />
                  </span>
                </th>
                
                <th
                  class="hidden xl:table-cell py-3 text-center text-md uppercase tracking-wider"
                >
                  <span
                    @click="sortTable('max_multiplier_win')"
                    class="cursor-pointer w-max font-normal whitespace-nowrap"
                  >
                    <!-- ghost icon -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-1 invisible" />
                    {{ $t('catalog.maxWin') }}
                    <font-awesome-icon
                      v-if="tableSort == 'max_multiplier_win'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-1"
                    />
                    <font-awesome-icon
                      v-if="tableSort == 'max_multiplier_winInverse'"
                      :icon="['fas', 'sort-up']"
                      class="px-1"
                    />
                    <!-- ghost icon -->
                    <font-awesome-icon
                      v-if="tableSort !== 'max_multiplier_win' && tableSort !== 'max_multiplier_winInverse'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-1 invisible" 
                    />
                  </span>
                </th>
                
                <th
                  v-if="!viewActiveList && requestingGames"
                  class="w-20"
                ></th>
              </tr>
            </thead>
            <CatalogTableBody
              v-show="viewAllList"
              :viewActiveList="viewActiveList"
              :games="getAllGames"
              :requestingGames="requestingGames"
              :gamesToRequest="gamesToRequest"
              :setGamesOrder="setGamesOrder"
            />
            <CatalogTableBody
              v-show="viewInDevList"
              :viewActiveList="viewActiveList"
              :games="devGames"
              :requestingGames="requestingGames"
              :gamesToRequest="gamesToRequest"
              :setGamesOrder="setGamesOrder"
            />
            <CatalogTableBody
              v-show="viewAvailableList"
              :viewActiveList="viewActiveList"
              :games="availableGames"
              :requestingGames="requestingGames"
              :gamesToRequest="gamesToRequest"
              :setGamesOrder="setGamesOrder"
            />
            <CatalogTableBody
              v-show="viewActiveList"
              :viewActiveList="viewActiveList"
              :games="activeGames"
              :requestingGames="requestingGames"
              :gamesToRequest="gamesToRequest"
              :setGamesOrder="setGamesOrder"
            />
          </table>
        </div>
      </div>
      <div
        v-if="ready && !serverError && gamesFromDB.length === 0"
        class="flex items-center justify-center p-4"
      >
        <h2 class="text-zinc-700 dark:text-zinc-300 text-xl mr-2 fugaz uppercase">
          {{ $t('catalog.gameListEmpty') }}
        </h2>
        <button
          v-if="userRoles.isLudusAdmin"
          class="px-1 h-10 rounded-sm text-xl text-zinc-100 xl:hover:text-white fugaz uppercase"
          @click="$router.push('/new-game/1')"
        >
        {{ $t('catalog.createGame') }}
        </button>
      </div>
    </div>
    <!-- loading -->
    <div
      v-else
      class="w-full h-[90vh] flex justify-center items-center gap-3 bg-zinc-950 text-white mt-4"
    >
      <img src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
      <!-- <img
        src="../../assets/loading2.png"
        alt="loading icon"
        class="h-12 animate-spin mb-2"
      /> -->
    </div>
    <div v-if="serverError" class="w-full h-full flex justify-center items-center gap-1.5 bg-zinc-950 text-white">
      {{ $t('serverError') }}
      <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
      {{ $t('tryAgain') }}
    </div>
  </div>
</template>

<script>
import CatalogTableBody from "../../components/catalog/CatalogTableBody.vue";
import GridGames from "../../components/catalog/GridGames.vue";
import request from "../../services/axios";

export default {
  name: "CatalogView",
  data() {
    return {
      gamesFromDB: [],
      available: true,
      active: true,
      searchBox: false,
      inDevelopment: true,
      tableSort: "name",
      sort: '',
      search: "",
      currentPage: 1,
      perPage: 50,
      ready: false,
      serverError: false,
      viewAvailableList: false,
      viewInDevList: false,
      viewActiveList: true,
      viewAllList: false,
      mobileCategory: 'all',
      categoryKey: 0,
      mountTimer: null,
      // requestingGames: false
    };
  },
  components: {
    CatalogTableBody,
    GridGames,
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      try {
        const response = await request.get("/catalog?with=game_media&mediaTypes=main,mini");
        this.gamesFromDB = response.data.rows;
        this.sort = this.gridSort;
        this.ready = true;
      } catch (error) {
        console.log(error);
        this.serverError = true;
        this.ready = true;
      }
    }, 500);
  },
  beforeUnmount() {
    clearTimeout(this.mountTimer);
  },
  watch: {
    sort() {
      this.$store.dispatch("catalog/setGridSortAction", this.sort);
    },
    mobileCategory() {
      this.changeCategory(this.mobileCategory)
    }
  },
  computed: {
    appLanguage() {
      return this.$store.getters['getLanguage']
    },
    screenWidth() {
      return this.$store.getters['getScreenWidth']
    },
    screenHeight() {
      return this.$store.getters['getScreenHeight']
    },
    listView() {
      return this.$store.getters["catalog/listView"];
    },
    gridSort() {
      return this.$store.getters["catalog/gridSort"];
    },
    requestingGames() {
      return this.$store.getters["requesting/requestingGames"];
    },
    gamesToRequest() {
      return this.$store.getters["requesting/gamesToRequest"];
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
    searchedGames() {
      const actualGames = this.gamesFromDB;
      return actualGames.filter((game) =>
        game.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    sortedGames() {
      return this.sortGames(this.searchedGames);
    },
    getAllGames() {
      let games = [];
      games = games.concat(this.devGames);
      games = games.concat(this.availableGames);
      games = games.concat(this.activeGames);
      if (this.itemsDisplay == "grid") return this.sortGamesByDateOrName(games);
      else return this.sortGames(games);
    },
    devGames() {
      let games = this.sortedGames;
      const gamesFiltered = games.filter((game) => game.isDev);
      if (this.itemsDisplay === "grid")
        return this.sortGamesByDateOrName(gamesFiltered);
      else return this.sortGames(gamesFiltered);
    },
    availableGames() {
      let games = this.sortedGames;
      if (
        this.user.localRoles.isLudusAdmin ||
        this.user.localRoles.isLudusManager
      )
        return [];
      else {
        const gamesFiltered = games.filter((game) => {
          const platform = game.platforms.find(
            (platform) =>
              platform.name.toLowerCase() ===
              this.user.attributes.platform[0].toLowerCase()
          );
          if (
            platform?.platform_games?.is_active === null &&
            game.isDev === false
          )
            return true;
        });
        if (this.itemsDisplay === "grid")
          return this.sortGamesByDateOrName(gamesFiltered);
        else return this.sortGames(gamesFiltered);
      }
    },
    activeGames() {
      let games = this.sortedGames;
      if (
        this.user.localRoles.isLudusAdmin ||
        this.user.localRoles.isLudusManager
      ) {
        const gamesFiltered = games.filter((game) => !game.isDev);
        if (this.itemsDisplay === "grid")
          return this.sortGamesByDateOrName(gamesFiltered);
        else return this.sortGames(gamesFiltered);
      } else {
        const gamesFiltered = games.filter((game) => {
          const platform = game.platforms.find(
            (platform) =>
              platform.name.toLowerCase() ===
              this.user.attributes.platform[0].toLowerCase()
          );
          if (
            platform?.platform_games?.is_active === true &&
            game.isDev === false
          )
            return true;
        });
        if (this.itemsDisplay === "grid")
          return this.sortGamesByDateOrName(gamesFiltered);
        else return this.sortGames(gamesFiltered);
      }
    },
    itemsDisplay() {
      return this.$store.getters["catalog/itemsDisplay"];
    },
    allSelected() {
      if (this.available && this.active && this.inDevelopment) return true;
      else return false;
    },
  },
  methods: {
    setGamesOrder() {
      let games = this.devGames.concat(this.availableGames, this.activeGames)
      this.$store.dispatch('catalog/setGamesOrderAction', games.map(game => game.id))
    },
    closeSearch() {
      this.search = "";
      this.toggleSearch();
    },
    getMiniBannerSrc(game) {
      const image = game.game_media.find(
        (image) => image.isMiniBanner === true
      );
      return image?.url;
    },
    toggleSearch() {
      if (this.searchBox) {
        if (this.search.length === 0) {
          this.searchBox = false;
        } else {
          this.$refs.search.blur();
        }
      } else {
        this.searchBox = true;
        this.$nextTick(() => {
          this.$refs.search.focus();
        })
      }
    },
    sortGamesByDateOrName(games) {
      if (this.gridSort === "release_date") {
        games.sort((a, b) => this.sortByReleaseDate(a, b));
      } else if (this.gridSort === "name") {
        games.sort((a, b) => this.sortByName(a, b));
      }
      return games;
    },
    sortByReleaseDate(a, b) {
      const dateA = new Date(a.release_date);
      const dateB = new Date(b.release_date);
      return dateB - dateA;
    },
    sortByName(a, b) {
      const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1; // a should come before b
      } else if (nameA > nameB) {
        return 1; // b should come before a
      } else {
        return 0; // the order remains unchanged
      }
    },
    openRequesting(value) {
      this.$store.dispatch("requesting/setRequestingGamesAction", value);
    },
    sortTable(column) {
      if (this.tableSort === column) {
        this.tableSort += "Inverse";
      } else {
        this.tableSort = column;
      }
    },
    sortGames(games) {
      if (this.tableSort) {
        let sort = this.tableSort;
        let reverse = false;
        if (sort.includes("Inverse")) {
          sort = sort.replace("Inverse", "");
          reverse = true;
        }
        games.sort((a, b) => {
          const valueA = this.getPropertyValue(a, sort);
          const valueB = this.getPropertyValue(b, sort);
          let comparison = 0;
          if (typeof valueA === "string" && typeof valueB === "string") {
            const upperA = valueA.toUpperCase();
            const upperB = valueB.toUpperCase();
            if (upperA > upperB) {
              comparison = 1;
            } else if (upperA < upperB) {
              comparison = -1;
            }
          } else {
            if (valueA > valueB) {
              comparison = 1;
            } else if (valueA < valueB) {
              comparison = -1;
            }
          } 
          return reverse ? comparison * -1 : comparison;
        });
      }
      return games;
    },
    getPropertyValue(object, propertyString) {
      const property = propertyString.split(".")[0];
      const value = object[property];
      if(property === 'name') return value.toString();
      else return parseFloat(value) || 0;
    },
    openReview() {
      if (this.gamesToRequest.length) {
        this.$router.push({
          name: "ReviewRequested",
          params: {
            games: this.gamesToRequest,
          },
        });
      }
    },
    changeCategory(category) {
      this.viewActiveList = category === 'active';
      this.viewInDevList = category === 'inDev';
      this.viewAvailableList = category === 'available';
      this.viewAllList = category === 'all';
    },
    selectAll() {
      this.available = true;
      this.active = true;
      this.inDevelopment = true;
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        const date = new Date(releaseDate);
        const options = { year: "numeric", month: "short", day: "numeric" };
        return date.toLocaleDateString(this.appLanguage.long, options);
      } else return "N/A";
    },
  },
};
</script>

<style scoped>
input:focus {
  border-bottom-color: rgb(107, 107, 107);
}
.checkbox {
  display: block;
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

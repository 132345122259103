// const state = {
//     date: {start: null, end: null},
//     dateType: null
//   }

//   const getters = {
//   }

//   const actions = {
//     getTodayStart({commit}) {
//       const today = new Date();
//       today.setHours(0, 0, 0, 0);
//       return today;
//     },
//     getTodayEnd({commit}) {
//       const today = new Date();
//       today.setHours(23, 59, 59, 59);
//       return today;
//     },
  
//     async getTodayDate({dispatch, commit}) {
//       return Promise.all([
//         dispatch('getTodayStart'),
//         dispatch('getTodayEnd')
//       ]).then(([start, end]) => {
//         const obj = {start, end}
//         commit('updateDate', obj)
//         commit('updateDateType', 'custom')
//       })
//     }   
//   }

//   const mutations = {
//     updateDate(state, payload) {
//         state.date = payload
//       },
//       updateDateType(state, payload) {
//         state.dateType = payload
//       }
//   }
  
//   export default {
//     namespaced: true,
//     state,
//     mutations,
//     actions,
//     getters
//   };
  
const state = {
  date: { start: null, end: null },
  dateType: null
};

const getters = {
  // You can add any computed values or accessors for state if needed.
};

const actions = {
  getTodayStart() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  },
  getTodayEnd() {
    const today = new Date();
    today.setHours(23, 59, 59, 999); // End of day
    return today;
  },

  async getTodayDate({ dispatch, commit }) {
    return Promise.all([
      dispatch('getTodayStart'),
      dispatch('getTodayEnd')
    ]).then(([start, end]) => {
      const obj = { start, end };
      commit('updateDate', obj);         // Set the date range in Vuex
      commit('updateDateType', 'custom'); // Set the type to 'custom'
    });
  }
};

const mutations = {
  updateDate(state, payload) {
    state.date = payload;
  },
  updateDateType(state, payload) {
    state.dateType = payload;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

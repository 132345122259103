<template>
  <div class="w-full flex flex-col items-start bg-zinc-950 relative gap-4 pb-[65px] xl:pb-0 py-4 text-white">
    <header class="w-full flex items-center justify-between px-4 md:px-7">
      <h1 class="text-3xl xl:text-4xl text-center fugaz uppercase">
        {{ $t('mailing.title') }}
      </h1>
      <button @click="openNewEmailBox = true" class="fugaz uppercase hover:text-orange-600 text-lg xl:text-xl">
        {{ $t('mailing.addEmail') }}
      </button>
    </header>
    <table v-if="ready" class="w-full table table-fixed border-separate border-spacing-y-[4px] px-4 md:px-7">
      <thead>
        <tr class="bg-zinc-700 fugaz uppercase">
          <th class="text-left py-2 pl-2 font-normal">{{ $t('mailing.email') }}</th>
          <th class="text-center py-2 w-[140px] font-normal">{{ $t('mailing.platform') }}</th>
          <th class="text-center py-2 w-[170px] font-normal">{{ $t('mailing.reportTypes') }}</th>
          <th class="w-[40px]"></th>
        </tr>
      </thead>
      <tbody :key="filteredMailingList.length">
        <tr v-for="mail in filteredMailingList" class="bg-zinc-900 hover:brightness-125">
          <td class="py-2 pl-2 font-medium">{{ mail.email }}</td>
          <td class="py-2 text-center font-medium">
            {{ mail.platformId !== null ? mail.platform : 'ALL' }}
          </td>
          <td class="py-2 text-center flex justify-center gap-1 capitalize font-medium">
            <span class="px-2 bg-zinc-700 rounded-md">{{ mail.reportTypeId === 1 ? 'Daily' : 'Monthly' }}</span>
          </td>
          <td @click="openEditEmail(mail)" class="cursor-pointer font-semibold hover:text-orange-600">
            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="w-full h-[90vh] flex justify-center items-center bg-zinc-950 text-white mt-4"
    >
      <img v-if="!serverError" src="../assets/hub-full-logo.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
      <div v-else>
        {{ $t('serverError') }}
        <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
        {{ $t('tryAgain') }}
      </div>
    </div>
    <NewEmail v-if="openNewEmailBox" :allPlatforms="allPlatforms" :editingEmail="editingEmail" @close-new-email="closeNewEmailBox" @new-email-created="getMailingList" />
  </div>
</template>
<script>
  import Multiselect from '@vueform/multiselect'
  import NewEmail from '../components/NewEmail'
  import request from '@/services/axios'

  export default {
    data() {
      return {
        ready: false,
        allPlatforms: [],
        mailingList: [],
        filteredMailingList: [],
        openNewEmailBox: false,
        editingEmail: null,
        mountTimer: null,
      }
    },
    components: {
      Multiselect,
      NewEmail,
    },
    async mounted() {
      this.mountTimer = setTimeout(async () => {
        await this.getAllPlatforms()
        await this.getMailingList()
        this.ready = true
      }, 500);
    },
    beforeUnmount() {
      clearTimeout(this.mountTimer);
    },
    watch: {

    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
    },
    methods: {
      openEditEmail(email) {
        this.editingEmail = email
        this.openNewEmailBox = true
      },
      async getAllPlatforms() {
        try {
          const platforms = (await request.get('/platforms')).data.rows
          this.allPlatforms = platforms
          this.allPlatforms.sort((a, b) => a.name.localeCompare(b.name))
        } catch (e) {
          console.log(e);
          this.serverError = true;
        }
      },
      async getMailingList() {
        try {
          const mailingList = (await request.get('/mailing-list-reports')).data
          this.mailingList = mailingList
          this.filteredMailingList = [...this.mailingList]
        } catch (e) {
          console.log(e);
          this.serverError = true;
        }
      },
      closeNewEmailBox() {
        this.openNewEmailBox = false
        this.editingEmail = null
        this.getMailingList()
      },
    },
  }
</script>
<style scoped>
tr:nth-child(even) {
  background-color: rgb(39 39 42);
}

</style>
<template>
  <div class="w-full h-full flex flex-col gap-y-3 z-10 bg-zinc-950">
    <div
      class="w-full flex items-end justify-between px-3 md:px-[30px] pt-2 pb-1.5 border-zinc-700"
    >
      <div class="flex flex-wrap items-end justify-center gap-3 relative">
        <div :key="dateKey" class="flex flex-col pb-1 w-full md:w-max">
          <div class="flex justify-between">
            <span class="text-zinc-300 whitespace-nowrap text-sm">
              {{ $t("charts.dateRange") }}
            </span>
          </div>
          <!-- <v-date-picker v-model.range="dateRange" is-dark mode="dateTime" color="pink" is24hr :max-date="new Date()" :locale="appLanguage.long">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center gap-2">
                <input
                  v-if="ready"
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  class="px-2 py-1 w-full md:w-[146px] rounded focus:outline-none bg-zinc-800 text-white"
                />
                <div v-else class="bg-zinc-800 animate-pulse h-8 rounded-md w-full md:w-[146px]"></div>
                <font-awesome-icon :icon="['fas', 'arrow-right']" class="h-4 text-white" />
                <input
                  v-if="ready"
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  class="px-2 py-1 w-full md:w-[146px] rounded focus:outline-none bg-zinc-800 text-white"
                />
                <div v-else class="bg-zinc-800 animate-pulse h-8 rounded-md w-full md:w-[146px]"></div>
              </div>
            </template>
          </v-date-picker> -->
          <div class="w-[22rem]">
            <DatePicker type="custom" />
          </div>
        </div>
        <div class="flex items-center gap-1.5 h-full py-1 mb-0.5">
          <button
            @click="tempLinesFormat = 'hour'"
            class="pt-1 pb-0.5 px-2 rounded-sm fugaz uppercase text-sm"
            :class="
              tempLinesFormat == 'hour'
                ? 'bg-zinc-800 text-orange-600'
                : 'bg-zinc-800 text-white xl:hover:bg-opacity-80'
            "
          >
            {{ $t("charts.hour") }}
          </button>
          <button
            @click="tempLinesFormat = 'day'"
            class="pt-1 pb-0.5 px-2 rounded-sm fugaz uppercase text-sm disabled:opacity-25 disabled:cursor-not-allowed"
            :class="
              tempLinesFormat == 'day'
                ? 'bg-zinc-800 text-orange-600'
                : 'bg-zinc-800 text-white xl:hover:bg-opacity-80'
            "
          >
            {{ $t("charts.day") }}
          </button>
          <button
            @click="tempLinesFormat = 'month'"
            class="pt-1 pb-0.5 px-2 rounded-sm fugaz uppercase text-sm disabled:opacity-25 disabled:cursor-not-allowed"
            :class="
              tempLinesFormat == 'month'
                ? 'bg-zinc-800 text-orange-600'
                : 'bg-zinc-800 text-white xl:hover:bg-opacity-80'
            "
          >
            {{ $t("charts.month") }}
          </button>
        </div>
        <!-- <div class="flex items-center gap-3"> -->
        <div
          class="w-full md:w-max whitespace-nowrap"
          :class="
            appLanguage.long == 'en-GB' ? 'min-w-[210px]' : 'min-w-[270px]'
          "
        >
          <Multiselect
            mode="multiple"
            v-model="selectedGames"
            :options="allGames"
            label="name"
            value-prop="id"
            :placeholder="$t('charts.totals.filterByGame')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :multiple-label="(values) => `${values.length} Games selected`"
            :loading="!ready"
          />
        </div>
        <div
          v-if="!userPlatform"
          class="w-full md:w-max whitespace-nowrap"
          :class="
            appLanguage.long == 'en-GB' ? 'min-w-[200px]' : 'min-w-[280px]'
          "
        >
          <Multiselect
            mode="multiple"
            v-model="selectedPlatforms"
            :options="actualPlatforms"
            label="name"
            value-prop="id"
            :placeholder="$t('charts.totals.filterByPlatform')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :multiple-label="(values) => `${values.length} Platforms selected`"
            :loading="!ready"
          />
        </div>
        <div class="px-3 flex items-cener gap-1">
          <button
            @click="changeSessionsType('fun')"
            class="font-bold xl:hover:text-white group flex items-center"
            :class="[
              sessionsType == 'fun' || sessionsType == 'all'
                ? 'text-white'
                : 'text-zinc-400',
            ]"
          >
            <span class="text-lg">{{ $t("charts.fun") }}</span>
            <font-awesome-icon :icon="['fas', 'gift']" class="h-4 pl-1" />
          </button>
          <label
            class="gap-2 items-center cursor-pointer relative px-2 rounded-md h-max"
          >
            <input
              type="checkbox"
              class="sr-only outline-none"
              @input="changeSessionsType()"
            />
            <div
              :class="[
                'block w-14 h-8 rounded-full relative transition-all duration-300 bg-zinc-800',
              ]"
            >
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                :class="{
                  'translate-x-6': sessionsType === 'real',
                  'translate-x-3': sessionsType === 'all',
                }"
              ></div>
            </div>
          </label>
          <button
            @click="changeSessionsType('real')"
            class="font-bold xl:hover:text-white group flex items-center"
            :class="[
              sessionsType == 'real' || sessionsType == 'all'
                ? 'text-white'
                : 'text-zinc-400',
            ]"
          >
            <span class="text-lg">{{ $t("charts.real") }}</span>
            <font-awesome-icon :icon="['fas', 'euro-sign']" class="h-4 pl-1" />
          </button>
        </div>
        <div class="flex items-center gap-1">
          <button
            @click="isOpen = false"
            class="text-lg font-semibold"
            :class="
              isOpen === false || isOpen === null
                ? 'text-white'
                : 'text-zinc-400 xl:hover:text-white'
            "
          >
            {{ $t("sessionsList.closed") }}
          </button>
          <label
            class="flex gap-2 items-center cursor-pointer relative px-2 rounded-md h-max"
          >
            <input
              type="checkbox"
              class="sr-only outline-none"
              @input="changeIsOpen"
            />
            <div
              :class="[
                'block w-14 h-8 rounded-full relative transition-all duration-300',
                {
                  'bg-sky-500': isOpen === true,
                  'bg-green-500': isOpen === false,
                  'bg-zinc-800': isOpen === null,
                },
              ]"
            >
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                :class="{
                  'translate-x-6': isOpen === true,
                  'translate-x-3': isOpen === null,
                }"
              ></div>
            </div>
          </label>
          <button
            @click="isOpen = true"
            class="text-lg font-semibold"
            :class="
              isOpen === true || isOpen === null
                ? 'text-white'
                : 'text-zinc-400 xl:hover:text-white'
            "
          >
            {{ $t("sessionsList.open") }}
          </button>
        </div>
        <button
          @click="getTotals(true)"
          :disabled="loading"
          class="hidden md:block px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
        >
          {{ $t("charts.submit") }}
        </button>
        <!-- </div> -->
        <!-- <div v-if="loading" class="flex items-center justify-center pr-2 pb-1">
          <img
            src="../../assets/loading2.png"
            alt="loading icon"
            class="h-7 text-zinc-200 animate-spin"
          />
        </div> -->
      </div>
    </div>
    <!-- mobile submit -->
    <div class="flex md:hidden items-center justify-center gap-1 w-full">
      <button
        @click="getTotals(true)"
        :disabled="loading"
        class="px-3 pt-1 pb-[3px] text-orange-600 bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
      >
        {{ $t("charts.submit") }}
      </button>
    </div>
    <div
      class="w-full h-full flex flex-col gap-y-2 px-3 md:px-7 pb-[60px] xl:pb-4 mt-1 md:mt-0"
    >
      <div
        class="w-full flex flex-col gap-2.5 bg-zinc-950 rounded-md pb-2 text-white"
      >
        <div
          class="flex items-end flex-wrap justify-start gap-2.5 cursor-default"
        >
          <div
            class="flex flex-col bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md fugaz uppercase"
          >
            <span class="text-sm"> Bet </span>
            <span class="text-xl text-sky-600">
              {{ getCurrencyValue(allBet) }}
            </span>
          </div>
          <div
            class="flex flex-col bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-lg fugaz uppercase"
          >
            <span class="text-sm"> Win </span>
            <span class="text-xl text-orange-600">
              {{ getCurrencyValue(allWin) }}
            </span>
          </div>
          <div
            v-if="!hideRealWins"
            class="flex flex-col bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-lg fugaz uppercase"
          >
            <span class="text-sm"> Gross win </span>
            <span
              class="text-xl"
              :class="allGrossWin >= 0 ? 'text-green-500' : 'text-red-500'"
            >
              {{ getCurrencyValue(allGrossWin) }}
            </span>
          </div>
          <div
            v-if="
              ready &&
              (userRoles.isLudusAdmin || userRoles.isPlatformAdmin) &&
              !hideRealWins
            "
            class="flex flex-col bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-lg fugaz uppercase"
          >
            <span class="text-sm"> Net win </span>
            <span
              class="text-xl"
              :class="allNetWin >= 0 ? 'text-pink-500' : 'text-red-500'"
            >
              {{ getCurrencyValue(allNetWin) }}
            </span>
          </div>
          <div
            v-if="
              ready &&
              (userRoles.isLudusAdmin || userRoles.isPlatformAdmin) &&
              !hideRealWins
            "
            class="flex flex-col bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-lg fugaz uppercase"
          >
            <span class="text-sm"> due </span>
            <span
              class="text-xl"
              :class="allDue >= 0 ? 'text-purple-500' : 'text-red-500'"
            >
              {{ getCurrencyValue(allDue) }}
            </span>
          </div>
          <div class="flex items-center bg-zinc-800 rounded-md">
            <div
              class="flex flex-col items-center bg-zinc-800 pb-1 px-2.5 rounded-md text-lg fugaz uppercase pt-1.5"
            >
              <span class="text-sm">
                {{ $t("charts.sessions") }}
              </span>
              <span class="text-xl text-white">
                {{ formatNumberWithPoints(sessionsCount) }}
              </span>
            </div>
            <div
              class="flex flex-col items-center bg-zinc-800 pb-1 pt-1.5 px-3 text-lg fugaz uppercase border-x border-zinc-700"
            >
              <span class="text-sm">
                {{ $t("charts.totals.open") }}
              </span>
              <span class="text-xl text-sky-500">
                {{ formatNumberWithPoints(sessionsOpen) }}
              </span>
            </div>
            <div
              class="flex flex-col items-center bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-lg fugaz uppercase"
            >
              <span class="text-sm">
                {{ $t("charts.totals.closed") }}
              </span>
              <span class="text-xl text-green-500">
                {{ formatNumberWithPoints(sessionsClosed) }}
              </span>
            </div>
          </div>
          <div
            class="flex flex-col items-center bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-lg fugaz uppercase"
          >
            <span class="text-sm">
              {{ $t("charts.rounds") }}
            </span>
            <span class="text-xl text-zinc-300">
              {{ formatNumberWithPoints(rounds) }}
            </span>
          </div>
          <div
            class="flex flex-col items-center bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-lg fugaz uppercase"
          >
            <span class="text-sm">
              {{ $t("charts.uniquePlayers") }}
            </span>
            <span class="text-xl text-red-500">
              {{ formatNumberWithPoints(usersCount) }}
            </span>
          </div>
        </div>
        <!-- <div class="flex items-end flex-wrap gap-2.5">
        </div> -->
      </div>
      <div
        class="w-full h-[400px] md:h-full flex justify-between gap-3 bg-zinc-900 p-2 rounded-md"
      >
        <div class="w-full xl:w-[80%] h-full flex flex-col text-white">
          <div class="flex justify-between gap-6" :class="allWin > 0 && 'pr-4'">
            <div class="flex gap-1.5">
              <button
                @click="changeChartType('lines')"
                class="py-1 px-2 rounded-sm"
                :class="[
                  chartType == 'lines'
                    ? 'bg-zinc-600'
                    : 'bg-zinc-700 text-zinc-300',
                  chartTypesAllowed.includes('lines')
                    ? 'xl:hover:bg-opacity-60'
                    : 'opacity-50 ',
                ]"
              >
                <font-awesome-icon :icon="['fas', 'chart-line']" />
              </button>
              <button
                @click="changeChartType('bars')"
                class="py-1 px-2 rounded-sm"
                :class="
                  chartType == 'bars'
                    ? 'bg-zinc-600'
                    : 'bg-zinc-700 xl:hover:bg-opacity-60 text-zinc-300'
                "
              >
                <font-awesome-icon :icon="['fas', 'chart-column']" />
              </button>
              <!-- <button
                @click="changeChartType('barsHorizontal')"
                class="py-1 px-2 rounded-sm"
                :class="
                  chartType == 'barsHorizontal'
                    ? 'bg-zinc-600'
                    : 'bg-zinc-700 xl:hover:bg-opacity-60 text-zinc-300'
                "
              >
                <font-awesome-icon :icon="['fas', 'chart-bar']" />
              </button> -->
            </div>
            <button
              v-if="betDataset.length > 0"
              @click="exportToExcel"
              class="py-1 px-2 rounded-sm bg-zinc-700 xl:hover:bg-opacity-60"
            >
              <font-awesome-icon :icon="['fas', 'file-csv']" />
            </button>
          </div>
          <div
            class="w-full h-full max-h-full pt-2 flex items-center justify-center"
            :key="chartKey"
          >
            <Line
              v-if="!loadingChart && chartType == 'lines'"
              :data="linesChartData"
              :options="linesChartOptions"
            />
            <Bar
              v-if="!loadingChart && chartType == 'bars'"
              :data="linesChartData"
              :options="linesChartOptions"
            />
            <!-- <Bar
              v-if="!loadingChart && chartType == 'barsHorizontal' "
              :data="linesChartData"
              :options="horizontalBarOptions"
            /> -->
            <div v-if="loadingChart" class="flex items-center gap-4">
              <img
                src="../../assets/hub-full-logo.png"
                alt="gai hub logo"
                class="h-22 w-auto animate-pulse"
              />
              <!-- <img src="../../assets/loading2.png" alt="loading icon"
                class="text-zinc-400 h-[50px] animate-spin mb-1.5"
              /> -->
            </div>
          </div>
        </div>
        <div class="w-[20%] h-full hidden xl:flex items-center relative">
          <div class="w-full h-full max-h-[450px] pt-2">
            <Pie
              class="w-full h-full"
              :data="pieChartData"
              :options="pieChartOptions"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import { useDateFormatter } from "@/helpers/dateFormatter.js";
import request from "@/services/axios";
import { utils, writeFile } from "xlsx";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Pie, Line, Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  TimeScale,
} from "chart.js";

import { DateTime } from "luxon";
import "chartjs-adapter-luxon";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  TimeScale
);
export default {
  data() {
    return {
      ready: false,
      loading: false,
      loadingChart: false,
      actualPlatforms: [],
      openSessionsInfo: false,
      sessionsCount: 0,
      sessionsOpen: 0,
      sessionsClosed: 0,
      rounds: 0,
      allBet: 0,
      allWin: 0,
      allGrossWin: 0,
      allNetWin: 0,
      allDue: 0,
      totals: [],
      selectedPlatforms: [],
      selectedGames: [],
      chartType: "lines",
      chartTypesAllowed: ["lines", "bars", "barsHorizontal"],
      linesFormat: "hour",
      tempLinesFormat: "hour",
      linesFormatsAllowed: ["hour"],
      sessionsType: "real",
      hideRealWins: false,
      chartLabels: [],
      betDataset: [],
      winDataset: [],
      grossWinDataset: [],
      netWinDataset: [],
      dueDataset: [],
      sessionsDataset: [],
      playersDataset: [],
      roundsDataset: [],
      dateKey: 0,
      groupDate: "YYYY-mm",
      usersCount: 0,
      isOpen: null,
    };
  },
  props: {
    allGames: Array,
    allPlatforms: Array,
    userPlatform: Object | null,
    chartKey: Number,
  },
  components: {
    DatePicker,
    Pie,
    Line,
    Bar,
    Multiselect,
  },
  async mounted() {
    // this.emitter.on('changingChartsToSpecific', this.saveFilters)

    // await this.getAllPlatforms();
    // await this.getAllSessions();
    // await this.getTotals(true)
    this.actualPlatforms = [...this.allPlatforms];
    // if(this.savedFilters !== null) {
    //   this.updateFilters()
    //   if(this.savedFilters.submitted) {
    //     await this.getTotals(true)
    //   }
    // }
    // else {
    // this.dateRange.start = this.getTodayStart();
    // this.dateRange.end = this.getTodayEnd();
    // this.dateKey++;
    // }
    this.ready = true;
  },
  watch: {
    // 'dateRange.start'() {
    //   this.checkDatesInterval()
    // },
    // tempLinesFormat() {
    //   this.checkDatesInterval()
    // },
    selectedGames() {
      this.filterPlatforms();
    },
    selectedPlatforms() {
      this.filterGames();
    },
  },
  computed: {
    dateRange() {
      return this.$store.state.filters.date;
    },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    savedFilters() {
      return this.$store.getters["charts/savedFilters"];
    },
    horizontalBarOptions() {
      return {
        indexAxis: "y",
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        interaction: {
          mode: "index",
          intersect: true,
        },
        plugins: {
          legend: {
            position: "right",
          },
        },
        scales: {
          y: {
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
          x: {
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
        },
      };
    },
    linesChartOptions() {
      return {
        responsive: true,
        interaction: {
          mode: "index",
          intersect: false,
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = context.dataset.data[context.dataIndex];
                const datasetName = context.dataset.label;
                let label = "";
                if (
                  datasetName !== "Rounds" &&
                  datasetName !== "Sessions" &&
                  datasetName !== "Players"
                ) {
                  label = this.getCurrencyValue(value);
                } else {
                  // Default number format for other datasets
                  label = value;
                }
                return `${datasetName}: ${label}`;
              },
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            ticks: {
              color: "white", // Change scale label (number) color here
              font: {
                family: "Fugaz One, cursive", // Change font-family here
                weight: "medium",
                size: 12, // Adjust font size as needed
              },
              autoSkip: true,
              maxTicksLimit: 12,
            },
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
          x: {
            time: {
              unit: this.linesFormat, // TODO this must respect the current select
              // parser: 'YYYY-MM-dd HH',
              // displayFormats: {
              //   hour: 'YYYY-MM-dd HH'
              // },
              // tooltipFormat: 'YYYY-MM-dd HH:mm:ss'
            },
            // suggestedMin: DateTime.fromJSDate( new Date(this.dateRange.start) ).toFormat(this.groupDate),
            // suggestedMax: DateTime.fromJSDate( new Date(this.dateRange.end) ).toFormat(this.groupDate),
            a: "0",
            ticks: {
              autoSkip: false,
              color: "white", // Change scale label (number) color here
              font: {
                family: "Fugaz One, cursive", // Change font-family here
                weight: "medium",
                size: 12, // Adjust font size as needed
              },
              autoSkip: true,
              maxTicksLimit: 12,
            },
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
        },
        maintainAspectRatio: false,
        // spanGaps: 1000 * 60 * 60 * 24 * 1,
      };
    },
    linesChartData() {
      let datasets = [
        {
          label: "Bet",
          borderColor: "rgb(2 132 199)",
          backgroundColor: "rgb(2 132 199)",
          data: this.betDataset,
          tension: 0.05,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        },
        {
          label: "Win",
          borderColor: "rgb(249 115 22)",
          backgroundColor: "rgb(249 115 22)",
          data: this.winDataset,
          tension: 0.05,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        },
        {
          label: "GrossWin",
          borderColor: "rgb(34 197 94)",
          backgroundColor: "rgb(34 197 94)",
          data: this.grossWinDataset,
          tension: 0.05,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        },
      ];

      if (this.userRoles.isLudusAdmin || this.userRoles.isPlatformAdmin) {
        datasets.push({
          label: "NetWin",
          borderColor: "#FF6600",
          backgroundColor: "#FF6600",
          data: this.netWinDataset,
          tension: 0.05,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        });
        datasets.push({
          label: "Due",
          borderColor: "rgb(168 85 247)",
          backgroundColor: "rgb(168 85 247)",
          data: this.dueDataset,
          tension: 0.05,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        });
      }
      datasets.push({
        label: "Sessions",
        borderColor: "rgb(255 255 255)",
        backgroundColor: "rgb(255 255 255)",
        data: this.sessionsDataset,
        tension: 0.05,
        hidden: true,
      });
      datasets.push({
        label: "Rounds",
        borderColor: "rgb(212 212 216)",
        backgroundColor: "rgb(212 212 216)",
        data: this.roundsDataset,
        tension: 0.05,
        hidden: true,
      });
      datasets.push({
        label: "Players",
        borderColor: "rgb(239 68 68)",
        backgroundColor: "rgb(239 68 68)",
        data: this.playersDataset,
        tension: 0.05,
        hidden: true,
      });
      return {
        labels: this.chartLabels,
        datasets,
      };
    },
    pieChartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              color: "white",
            },
          },
          legpieChartOptionsend: {
            labels: {
              color: "white",
            },
            legendCallback: (chart) => {
              const legendPadding = 10; // Adjust the padding as needed
              return `<div style="padding-bottom: ${legendPadding}px;"></div>`;
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = context.dataset.data[context.dataIndex];
                return new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(value);
              },
            },
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        maintainAspectRatio: false,
      };
    },
    pieChartData() {
      let labels = [];
      let data = [];
      labels = ["Bet", "Win", "GrossWin"];
      data = [this.allBet, this.allWin, this.allGrossWin];
      if (this.userPlatform === null) {
        labels.push("NetWin");
        data.push(this.allNetWin);
        labels.push("Due");
        data.push(this.allDue);
      }
      return {
        labels,
        datasets: [
          {
            backgroundColor: [
              "rgb(2 132 199)",
              "rgb(249 115 22)",
              "rgb(34 197 94)",
              "#FF6600",
              "rgb(168 85 247)",
            ],
            data,
          },
        ],
        cutoutPercentage: 80,
      };
    },
    lastJob() {
      return this.$store.getters["jobs/lastJob"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
    storeSession() {
      return this.$store.getters["sessions/getSession"];
    },
  },
  methods: {
    filterPlatforms() {
      if (this.selectedGames.length === 0) {
        // If no game is selected, enable all platforms
        this.allPlatforms.forEach((platform) => (platform.disabled = false));
        return;
      }

      // For each platform, check if it contains all selected games
      this.allPlatforms.forEach((platform) => {
        platform.disabled = !this.selectedGames.every((gameId) =>
          platform.games.map((game) => game.id).includes(gameId)
        );
      });
    },
    filterGames() {
      if (this.selectedPlatforms.length === 0) {
        // If no platform is selected, disable all games
        this.allGames.forEach((game) => (game.disabled = false));
        return;
      }

      // For each game, check if it exists in every selected platform's games
      this.allGames.forEach((game) => {
        game.disabled = !this.selectedPlatforms.every((platformId) => {
          const platform = this.allPlatforms.find((p) => p.id === platformId);
          return (
            platform && platform.games.map((game) => game.id).includes(game.id)
          );
        });
      });
    },
    changeChartType(value) {
      if (!this.chartTypesAllowed.includes(value)) {
        this.$toast.open({
          message:
            "Chart type not allowed, increase date range or change date time group.",
          type: "error",
          duration: 5000,
          position: "bottom-right",
        });
      } else {
        this.chartType = value;
      }
    },
    changeIsOpen() {
      if (this.isOpen === null) {
        this.isOpen = true;
        return;
      }
      if (this.isOpen === true) {
        this.isOpen = false;
        return;
      }
      if (this.isOpen === false) {
        this.isOpen = null;
        return;
      }
    },
    saveFilters() {
      // console.log('totals saving filters')
      let submitted = false;
      if (this.savedFilters?.submitted || this.betDataset.length > 0)
        submitted = true;
      this.$store.dispatch("charts/setSavedFilters", {
        dateRange: this.dateRange,
        selectedGames: this.selectedGames,
        tempLinesFormat: this.tempLinesFormat,
        sessionsType: this.sessionsType,
        submitted,
      });
    },
    updateFilters() {
      this.dateRange = this.savedFilters.dateRange;
      this.selectedGames = this.savedFilters.selectedGames;
      this.sessionsType = this.savedFilters.sessionsType;
      this.tempLinesFormat = this.savedFilters.tempLinesFormat;
    },
    checkDatesInterval() {
      const date1 = this.dateRange.start;
      const date2 = this.dateRange.end;
      // Check if the dates are on the same day
      const isSameDay =
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();

      // Check if the dates are on the same month
      const isSameMonth =
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();

      if (this.tempLinesFormat === "hour") {
        this.chartTypesAllowed = ["lines", "bars", "barsHorizontal"];
      }
      if (
        (this.tempLinesFormat === "day" && isSameDay) ||
        (this.tempLinesFormat === "month" && isSameMonth)
      ) {
        this.chartTypesAllowed = ["bars", "barsHorizontal"];
        if (this.chartType === "lines") {
          this.chartType = "bars";
        }
      } else {
        this.chartTypesAllowed = ["lines", "bars", "barsHorizontal"];
      }
    },
    formatNumberWithPoints(number) {
      return number
        ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : number;
    },
    exportToExcel() {
      const labels = this.linesChartData.labels;
      const datasets = this.linesChartData.datasets;

      const header = ["Date"];
      header.push(...datasets.map((dataset) => dataset.label));

      // Sample data
      let data = [header];
      // Combine data and generate rows
      for (let i = 0; i < labels.length; i++) {
        let rowData = [];
        rowData.push(labels[i]);
        rowData.push(
          ...datasets.map((dataset) => {
            // console.log(dataset)
            if (dataset.label !== "Rounds" && dataset.label !== "Sessions") {
              return this.getCurrencyValue(dataset.data[i].toFixed(2));
            } else return dataset.data[i];
          })
        );
        data.push(rowData);
      }
      data.push("");
      data.push([
        "",
        "Total Bet",
        "Total Win",
        "Total Gross Win",
        "Total Net Win",
        "Total Due",
        "Total Sessions",
        "Total Rounds",
      ]);
      data.push([
        "",
        this.getCurrencyValue(this.allBet.toFixed(2)),
        this.getCurrencyValue(this.allWin.toFixed(2)),
        this.getCurrencyValue(this.allGrossWin.toFixed(2)),
        this.getCurrencyValue(this.allNetWin.toFixed(2)),
        this.getCurrencyValue(this.allDue.toFixed(2)),
        this.sessionsCount,
        this.rounds,
      ]);
      // console.log(data);

      // Create a worksheet
      const ws = utils.aoa_to_sheet(data);

      // Set column widths (widths are in Excel's "characters" unit)
      ws["!cols"] = [
        { width: 20 }, // Width for date
        { width: 20 }, // Width for bet
        { width: 20 }, // Width for win
        { width: 20 }, // Width for GrossWin
        { width: 20 }, // Width for NetWin
        { width: 20 }, // Width for Due
        { width: 15 }, // Width for Sessions
        { width: 15 }, // Width for Rounds
      ];
      // Create a workbook
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Date range");

      // Export the workbook to a file
      writeFile(wb, "totals_charts_data.xlsx");
    },
    changeSessionsType(type = null) {
      if (type !== null) {
        this.sessionsType = type;
        return;
      }
      if (this.sessionsType === "fun") {
        this.sessionsType = "real";
        return;
      }
      if (this.sessionsType === "real") {
        this.sessionsType = "all";
        return;
      }
      if (this.sessionsType === "all") {
        this.sessionsType = "fun";
        return;
      }
    },
    areDatesOnSameDay(dateArray) {
      const firstDateDay = dateArray[0].split(" ")[0];
      return dateArray.every((date) => date.split(" ")[0] === firstDateDay);
    },
    areDatesOnSameYear(dateArray) {
      return dateArray.every(
        (date) =>
          new Date(date).getFullYear() === new Date(dateArray[0]).getFullYear()
      );
    },
    areMonthsOnSameYear(dateArray) {
      return dateArray.every((date) => {
        const date1String = `${date}-01`;
        const date2String = `${dateArray[0]}-01`;
        return (
          new Date(date1String).getFullYear() ===
          new Date(date2String).getFullYear()
        );
      });
    },
    async getTotals(fromSubmit) {
      this.checkDatesInterval();
      if (fromSubmit) this.loading = true;
      if (this.sessionsType === "fun") this.hideRealWins = true;
      this.linesFormat = this.tempLinesFormat;
      this.loadingChart = true;
      try {
        const from = new Date(this.dateRange.start);
        const to = new Date(this.dateRange.end);
        const gameIds =
          this.selectedGames.length > 0 ? this.selectedGames : null;
        let platformIds = null;
        if (this.selectedPlatforms.length) platformIds = this.selectedPlatforms;
        if (this.userPlatform) platformIds = [this.userPlatform.id];
        let groupDate = "";
        if (this.linesFormat == "hour") {
          // groupDate = '%Y-%m-%d %H'
          groupDate = "%Y-%m-%d %H";
          this.groupDate = "y-LL-dd HH";
        }
        if (this.linesFormat == "day") {
          groupDate = "%Y-%m-%d";
          this.groupDate = "y-LL-d";
        }
        if (this.linesFormat == "month") {
          groupDate = "%Y-%m";
          this.groupDate = "y-LL";
        }

        let sessionTypeId = null;
        if (this.sessionsType == "real") sessionTypeId = 1;
        if (this.sessionsType == "fun") sessionTypeId = 4;

        const requestObject = {
          from: useDateFormatter(from),
          to: useDateFormatter(to),
          games: gameIds,
          platforms: platformIds,
          stats: "bet,win,grosswin,netwin,due,sessionsTotals,usersCount,rounds",
          datasetTotals: 1,
          key: groupDate,
          sessionTypeId,
        };
        if (this.isOpen !== null) {
          requestObject.isOpen = this.isOpen;
        }
        // console.log(requestObject)
        const a = btoa(JSON.stringify(requestObject));
        // const b = atob(a)
        // console.log(b)
        const response = (await request(`/bookkeeping/stats?a=${a}`)).data;
        // console.log(response)
        this.allBet = response.grandTotals.bet;
        this.allWin = response.grandTotals.win;
        this.allGrossWin = response.grandTotals.grosswin;
        // const italianTax = (process.env.VUE_APP_ITALIAN_TAX / 100) * this.allGrossWin
        // const netWin = this.allGrossWin - italianTax
        this.allNetWin = response.grandTotals.netwin;
        this.allDue = response.grandTotals.due;
        this.sessionsCount = response.grandTotals.sessionsTotal;
        this.sessionsClosed = response.grandTotals.sessionsClosed;
        this.sessionsOpen = response.grandTotals.sessionsOpen;
        this.rounds = response.grandTotals.rounds;
        this.usersCount = response.grandTotals.usersCount;

        const formatFunction = (label) => {
          if (this.linesFormat == "hour") {
            const labelSplitted = label.split(" ");
            const labelDate = new Date(labelSplitted[0]);
            labelDate.setHours(labelSplitted[1]);

            const options = { hour: "numeric", minute: "numeric" };

            if (!this.areDatesOnSameDay(response.labels)) {
              options.day = "numeric";
              options.month = "short";
            }
            return labelDate.toLocaleTimeString("en-GB", options);
          }
          if (this.linesFormat == "day") {
            const labelDate = new Date(label);
            const options = { day: "numeric", month: "short" };
            if (!this.areDatesOnSameYear(response.labels)) {
              options.year = "numeric";
            }
            return labelDate.toLocaleDateString("en-GB", options);
          }
          if (this.linesFormat == "month") {
            const labelString = `${label}-01`;
            const labelDate = new Date(labelString);
            const options = { month: "long" };
            if (!this.areMonthsOnSameYear(response.labels)) {
              options.year = "numeric";
            }
            return labelDate.toLocaleDateString("en-GB", options);
          }
        };

        // console.log(response.labels)
        const labels = response.labels.map(formatFunction);

        this.chartLabels = [...labels];

        this.betDataset = response.datasets.totals.bet[0].data;
        this.winDataset = response.datasets.totals.win[0].data;
        this.grossWinDataset = response.datasets.totals.grosswin[0].data;
        this.netWinDataset = response.datasets.totals.netwin[0].data;
        this.dueDataset = response.datasets.totals.due[0].data;

        this.sessionsDataset = response.datasets.totals.sessionsTotals[0].data;
        this.playersDataset = response.datasets.totals.usersCount[0].data;
        this.roundsDataset = response.datasets.totals.rounds[0].data;

        if (this.sessionsType !== "fun") this.hideRealWins = false;
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
      if (fromSubmit) this.loading = false;
      this.loadingChart = false;
      this.saveFilters();
    },
  },
};
</script>

<style>
.multiselect {
  background: rgb(24 24 27);
  border: 0px;
  max-height: 20px;
  box-shadow: none;
}
.multiselect.is-active {
  border-width: 1px;
  border-color: rgb(63 63 70);
  box-shadow: none;
}
.multiselect.is-disabled {
  background: rgb(24 24 27);
  border-width: 1px;
  opacity: 0.3;
  border-color: rgb(63 63 70);
  box-shadow: none;
}
.multiselect-tags-search {
  background: rgb(24 24 27);
  color: white;
}
.multiselect-search {
  background: rgb(24 24 27);
  color: white;
}
.multiselect-single-label {
  color: white;
}
.multiselect-dropdown {
  background: rgb(24 24 27);
  color: white;
  border-width: 1px;
  border-color: rgb(63 63 70);
  text-transform: capitalize;
  max-height: 25rem;
}
.multiselect-option.is-selected,
.multiselect-option.is-selected.is-pointed,
.multiselect-tag {
  background: #ff6600;
}
.multiselect-multiple-label {
  color: white;
}
.multiselect-option.is-disabled {
  background: rgb(24 24 27);
  color: rgb(85, 85, 85);
}
</style>
<template>
  <div 
      @click="closeSearch"
      class="w-full h-full flex justify-center items-center bg-black bg-opacity-70 fixed top-0 left-0 z-[999] overflow-y-auto"
    >
      <div @click.stop class="bg-zinc-800 h-[95vh] w-[95%] xl:w-[85%] flex flex-col rounded-md text-white relative border border-zinc-700">
        <div v-if="!storeSession.id" class="flex flex-col bg-zinc-700 pl-3 pr-6 sticky top-0 z-[20] py-2.5">
          <button @click.stop="closeSearch" class="absolute top-1.5 right-2 p-1 group">
            <font-awesome-icon :icon="['fas', 'xmark']" class="h-5 text-zinc-300 group-hover:text-orange-600 transition-all duration-100" />
          </button>
          <span class="fugaz uppercase text-xl mb-1">Search session</span>
          <form @submit.prevent="searchSession" class="flex flex-col md:flex-row items-center gap-1.5 mt-2 md:mt-0">
            <!-- <select v-if="allPlatforms.length > 1" name="platform" v-model="platformToSearch" class="outline-none shadow-sm text-lg py-1 bg-zinc-900" style="color-scheme: dark">
              <option value="">Select platform</option>
              <option v-for="platform in allPlatforms" :value="platform.id">{{ platform.name }}</option>
            </select> -->
            <!-- <div v-if="allPlatforms.length > 1" class="w-full md:w-80 z-[10]">
              <Multiselect
                mode="single"
                v-model="platformToSearch"
                :options="allPlatforms"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                label="name"
                value-prop="id"
                placeholder="Select platform"
                ref="multiselect"
              />
            </div> -->
            <div class="flex items-center gap-1.5">
              <button 
                class="px-2.5 py-1 rounded-md whitespace-nowrap font-semibold" 
                :class="searchType == 'sessionId' ? 'bg-orange-600' : 'bg-zinc-800'"
                @click.prevent="searchType = 'sessionId'"
                type="button"
              >
                Session ID
              </button>
              <button 
                class="px-2.5 py-1 rounded-md whitespace-nowrap font-semibold" 
                :class="searchType == 'userId' ? 'bg-orange-600' : 'bg-zinc-800'"
                @click.prevent="searchType = 'userId'"
                type="button"
              >
                User ID
              </button>
            </div>
            <input
              type="text"
              name="search"
              ref="search"
              :placeholder="searchType == 'sessionId' ? 'Enter AamsTicketid, AamsSessionId or SessionId' : 'Enter UserId'"
              class="px-2.5 py-2 rounded-md w-full outline-none text-white bg-zinc-900"
              style="color-scheme: dark"
              v-model="search"
            />
            <button :disabled="search.length === 0" 
              class="px-2 pb-0.5 pt-1 fugaz uppercase text-white disabled:opacity-30" type="submit"
              :class="search.length !== 0 && 'xl:hover:text-orange-600'"
            >
              Submit
            </button>
          </form>
        </div>
        <div v-if="!storeSession.id && results.length === 0" class="w-full h-[100%] bg-zinc-900 rounded-md flex flex-col items-center justify-center">
          <div class="flex items-center gap-4">
            <img src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-16 md:h-24 w-auto" :class="loadingSearch && 'animate-pulse'">
            <img v-if="loadingSearch" src="../../assets/loading2.png" alt="loading icon"
              class="text-zinc-400 h-[50px] animate-spin mb-1.5"
            />
          </div>
          <div v-if="!loadingSearch && sessionNotFound" class="w-full flex justify-center items-center py-3 text-white bg-zinc-900 text-lg">
            Session not found
          </div>
        </div>
        <!-- <div v-if="loadingSearch" class="w-full flex justify-center items-center py-3 text-white">
          <img src="../../assets/loading2.png" alt="loading icon"
            class="text-zinc-400 h-6 animate-spin"
          />
        </div> -->
        <div v-if="results.length > 0 && !storeSession.id" class="px-2 pb-1 mt-2 flex flex-col h-full overflow-auto rounded-md">
          <div class="flex flex-col md:flex-row gap-1.5 justify-between items-center pt-1 px-1.5">
            <div class="flex flex-col md:flex-row justify-center items-center gap-2.5">
              <div class="fugaz uppercase">
                <span class=text-md><span class="text-orange-600 text-lg">{{ results.length }}</span> sessions found</span> 
                <span class="text-sm pl-1.5">in {{ platformsFound.length }} Platforms</span>
              </div>
              <div v-if="platformsFound.length > 1" class="w-full md:w-52 z-[10]">
                <Multiselect
                  mode="single"
                  v-model="selectedPlatform"
                  :options="platformsFound"
                  :close-on-select="true"
                  :searchable="true"
                  :create-option="true"
                  label="name"
                  value-prop="id"
                  placeholder="Filter by Platform"
                  ref="multiselect"
                />
              </div>
            </div>
            <div class="flex items-center font-semibold">
              <div class="px-2.5 text-center border-x border-zinc-600">
                Bet 
                <span>{{ this.getCurrencyValue(totalBet) }}</span>
              </div>
              <div class="px-2.5 text-center border-r border-zinc-600">
                Gross Win 
                <span :class="totalGrossWin >= 0 ? 'text-green-500' : 'text-red-500'">{{ this.getCurrencyValue(totalGrossWin) }}</span>
              </div>
              <div class="px-2.5 text-center border-r border-zinc-600">
                Rounds {{ totalRounds }}
              </div>
            </div>
          </div>
          <table class="table-auto border-separate border-spacing-y-[5px]">
            <thead class="bg-zinc-700 sticky top-0">
              <tr>
                <th class="py-2 pl-3 pr-1.5 text-left hidden md:table-cell">Session ID</th>
                <th class="py-2 px-1.5 hidden xl:table-cell">Status</th>
                <th class="py-2 px-1.5 hidden xl:table-cell whitespace-nowrap"
                  @click="sortTable('bet')"
                >
                  <button class="w-full flex justify-center items-center">
                    <!-- ghost icon -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible" />
                    Bet
                    <button class="uppercase" v-if="tableSort.order == 'bet'">
                      <font-awesome-icon
                        v-if="tableSort.order == 'bet' && tableSort.direction == 'desc'"
                        :icon="['fas', 'sort-down']"
                        class="pb-1 px-2"
                      />
                      <font-awesome-icon
                        v-if="tableSort.order == 'bet' && tableSort.direction == 'asc'"
                        :icon="['fas', 'sort-up']"
                        class="px-2 pt-1"
                      />
                    </button>
                    <!-- ghost icon -->
                    <font-awesome-icon
                      v-if="tableSort.order !== 'bet'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2 invisible" 
                    />
                  </button>
                </th>
                <th class="py-2 px-1.5 hidden xl:table-cell whitespace-nowrap"
                  @click="sortTable('win')"
                >
                  <button class="w-full flex justify-center items-center">
                    <!-- ghost icon -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible" />
                    Win
                    <button class="uppercase" v-if="tableSort.order == 'win'">
                      <font-awesome-icon
                        v-if="tableSort.order == 'win' && tableSort.direction == 'desc'"
                        :icon="['fas', 'sort-down']"
                        class="pb-1 px-2"
                      />
                      <font-awesome-icon
                        v-if="tableSort.order == 'win' && tableSort.direction == 'asc'"
                        :icon="['fas', 'sort-up']"
                        class="px-2 pt-1"
                      />
                    </button>
                    <!-- ghost icon -->
                    <font-awesome-icon
                      v-if="tableSort.order !== 'win'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2 invisible" 
                    />
                  </button>
                </th>
                <th class="py-2 px-1.5 hidden xl:table-cell whitespace-nowrap"
                  @click="sortTable('grossWin')"
                >
                  <button class="w-full flex justify-center items-center">
                    <!-- ghost icon -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible" />
                    Gross Win
                    <button class="uppercase" v-if="tableSort.order == 'grossWin'">
                      <font-awesome-icon
                        v-if="tableSort.order == 'grossWin' && tableSort.direction == 'desc'"
                        :icon="['fas', 'sort-down']"
                        class="pb-1 px-2"
                      />
                      <font-awesome-icon
                        v-if="tableSort.order == 'grossWin' && tableSort.direction == 'asc'"
                        :icon="['fas', 'sort-up']"
                        class="px-2 pt-1"
                      />
                    </button>
                    <!-- ghost icon -->
                    <font-awesome-icon
                      v-if="tableSort.order !== 'grossWin'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2 invisible" 
                    />
                  </button>
                </th>
                <th class="py-2 px-1.5 hidden xl:table-cell">Rounds</th>
                <th class="py-2 px-1.5 hidden xl:table-cell">UserID</th>
                <th class="py-2 px-1.5">Platform</th>
                <th class="py-2 px-1.5 hidden xl:table-cell">Operator</th>
                <th class="py-2 px-1.5"
                  @click="sortTable('externalUpdatedAt')"
                >
                  <button class="w-full flex justify-center items-center whitespace-nowrap">
                    <!-- ghost icon -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible" />
                    {{ $t('sessionsList.lastUpdate') }}
                    <button class="uppercase" v-if="tableSort.order == 'externalUpdatedAt'">
                      <font-awesome-icon
                        v-if="tableSort.order == 'externalUpdatedAt' && tableSort.direction == 'desc'"
                        :icon="['fas', 'sort-down']"
                        class="pb-1 px-2"
                      />
                      <font-awesome-icon
                        v-if="tableSort.order == 'externalUpdatedAt' && tableSort.direction == 'asc'"
                        :icon="['fas', 'sort-up']"
                        class="px-2 pt-1"
                      />
                    </button>
                    <!-- ghost icon -->
                    <font-awesome-icon
                      v-if="tableSort.order !== 'externalUpdatedAt'"
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2 invisible" 
                    />
                  </button>
                </th>
              </tr>

            </thead>
            <tbody class="">
              <tr v-for="session in currentResults" 
                :key="session.id"
                @click="openFoundSession(session)" 
                class="px-2.5 bg-zinc-900 hover:bg-black cursor-pointer text-white w-full rounded-md"
              >
                  <td class="py-2.5 pl-3 pr-1.5 text-left font-semibold max-w-[450px] break-words hidden md:table-cell">{{ session.sessionId }}</td>
                  <!-- <td class="py-3 px-1 text-center font-semibold truncate">{{ session.sessionId }}</td>
                  <td class="py-3 px-1 text-center font-semibold">{{ session.win }}</td> -->

                  <td class="py-2.5 px-1.5 text-center font-semibold hidden xl:table-cell">{{ session.isOpen ? 'Open' : 'Closed' }}</td>
                  <td class="py-2.5 px-1.5 text-center font-semibold hidden xl:table-cell">
                    {{ getCurrencyValue(session.bet) }}
                  </td>
                  <td class="py-2.5 px-1.5 text-center font-semibold hidden xl:table-cell">
                    {{ getCurrencyValue(session.win) }}
                  </td>
                  <td class="py-2.5 px-1.5 text-center font-semibold hidden xl:table-cell" :class="session.grossWin >= 0 ? 'text-green-500' : 'text-red-500'">
                    {{ getCurrencyValue(session.grossWin) }}
                  </td>
                  <td class="py-2.5 px-1.5 text-center font-semibold hidden xl:table-cell">{{ session.currentRound }}</td>
                  <td class="py-2.5 px-1.5 text-center font-semibold hidden xl:table-cell">{{ session.userId }}</td>
                  <td class="py-2.5 px-1.5 text-center fugaz uppercase">{{ session.platform.name }}</td>
                  <td class="py-2.5 px-1.5 text-center fugaz uppercase hidden xl:table-cell">{{ session.operator ? session.operator.name : 'N/A' }}</td>
                  <td class="py-2.5 px-1.5 text-center font-semibold whitespace-nowrap">{{ clearDate(session.externalUpdatedAt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Session v-if="!loadingSearch && sessionFound && storeSession.id" :fromSearch="true" class="z-[9] h-[87%]" />
        <!-- <div v-if="loadingSearch" class="flex items-center justify-center py-6 w-full gap-2 z-[2]">
          <img src="../../assets/loading2.png" alt="loading icon"
            class="text-zinc-600 dark:text-zinc-400 h-6 animate-spin"
          />
        </div> -->
      </div>
    </div>
</template>

<script>
  import Session from "@/components/sessions/Session.vue";
  import Multiselect from '@vueform/multiselect'
  import request from "@/services/axios";

  export default {
    data() {
      return {
        search: '',
        searchType: 'sessionId',
        loadingSearch: false,
        sessionFound: false,
        sessionNotFound: false,
        platformToSearch: '',
        selectedPlatform: null,
        serverError: false,
        results: [],
        tableSort: {
          order: 'externalUpdatedAt',
          direction: 'desc'
        },
        platformsFound: [],
      }
    },
    props: {
      userPlatform: Number | null,
      closeSearch: Function,
      allPlatforms: Array,
    },
    components: {
      Session,
      Multiselect
    },
    mounted() {
      if(this.userPlatform) this.platformToSearch = this.userPlatform
      this.$refs.search.focus()
    },
    watch: {
      platformToSearch() {
        if(this.$refs.multiselect) this.$refs.multiselect.blur()
      },
      // selectedPlatform() {
      //   this.results = this.results.filter(session => session.platform.id == this.selectedPlatform)
      // }
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
      storeSession() {
        return this.$store.getters["sessions/getSession"]
      },
      currentResults() {
        return this.results.filter(session => this.selectedPlatform ? session.platform.id === this.selectedPlatform : true)
      },
      totalBet() {
        return this.currentResults.reduce((total, session) => total + parseFloat(session.bet), 0)
      },
      totalGrossWin() {
        return this.currentResults.reduce((acc, session) => acc + session.grossWin, 0)
      },
      totalRounds() {
        return this.currentResults.reduce((acc, session) => acc + session.currentRound, 0)
      }
    },
    methods: {
      sortTable(column) {
        if (this.tableSort.order === column) {
          this.tableSort.direction = this.tableSort.direction === "asc" ? "desc" : "asc";
        } else {
          this.tableSort.order = column;
          this.tableSort.direction = "desc";
        }
        const sortOrder = this.tableSort.direction === 'desc' ? -1 : 1;

        this.results.sort((a, b) => {
          if (this.tableSort.order === 'externalUpdatedAt') {
            return sortOrder * (new Date(b[this.tableSort.order]) - new Date(a[this.tableSort.order]));
          } else {
            return sortOrder * (a[this.tableSort.order] - b[this.tableSort.order]);
          }
        });
      },
      async searchSession(){
        this.results = []
        this.loadingSearch = true
        try {
          const response = (await request.get(`/bookkeeping/session/search?relatedId=${this.search}&searchType=${this.searchType}`)).data
          let platformsFound = []
          const sessions = response.map(session => {
            if (!platformsFound.map(plat => plat.id).includes(session.platform.id)) {
              platformsFound.push(session.platform)
            }
            return {
              ...session,
              // grossWin: session.bet - session.win
            }
          })
          // const platformsFound = new Set(sessions.map(session => session.platform))
          // console.log(platformsFound)
          this.platformsFound = [...platformsFound]
          if(sessions.length > 0) {
            this.sessionFound = true
            this.sessionNotFound = false
            if(sessions.length === 1) {
              this.setStoreSession(sessions[0])
            } else {
              sessions.sort((a, b) => new Date(b.externalUpdatedAt) - new Date(a.externalUpdatedAt))
              this.results = sessions
            }
          } else {
            // console.log("No sessions found")
            this.sessionNotFound = true
            this.$store.dispatch("sessions/setSessionAction", {});
            this.loadingSearch = false
            this.results = []
          }
        } catch (e) {
          this.sessionNotFound = true
          this.$store.dispatch("sessions/setSessionAction", {});
          this.serverError = true
          console.log(e)
        }
        this.loadingSearch = false
      },
      openFoundSession(session) {
        this.setStoreSession(session)
        // this.results = []
      },
      setStoreSession(session) {
        this.$store.dispatch("sessions/setSessionAction", session);
      },
      clearDate(date) {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }
        return new Date(date).toLocaleDateString(this.appLanguage.long, options)
      },
    }
  }
</script>

<style scoped>

</style>
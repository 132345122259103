<template>
  <div
    class="w-full h-screen flex justify-center items-center bg-zinc-200 dark:bg-zinc-950 relative"
  >
    <div
      class="flex flex-col gap-y-3 bg-zinc-800 rounded-lg px-8 pb-6 overflow-y-auto"
      style="width: 80%; height: 90%"
    >
      <div class="sticky top-0 bg-zinc-800 pb-2 pt-3 z-20">
        <h2 class="text-2xl text-white fugaz uppercase">Games selected</h2>
        <div class="w-64 h-2 bg-orange-600"></div>
      </div>
      <div
        v-for="(game, index) in games"
        :key="`game${index}`"
        class="w-full flex gap-x-2 border border-zinc-600 relative z-10"
      >
        <img
          class="w-40 h-40 object-cover"
          :src="getGameMainImageSrc(game)"
          alt="game main image"
        />
        <div class="flex flex-col gap-y-2 py-3.5 pl-2">
          <!-- title  -->
          <div class="flex flex-col fugaz uppercase cursor-pointer">
            <h2 @click="openGame(game)" class="text-2xl text-white -mb-1.5">
              {{ game.name }}
            </h2>
            <h2 @click="openGame(game)" class="text-xl text-white">
              {{ game.sub_name }}
            </h2>
          </div>
          <!-- game details -->
          <div
            class="flex items-center gap-2"
            :class="game.sub_name ? '' : 'mt-4'"
          >
            <div
              class="flex flex-col justify-center items-center rounded-sm bg-zinc-700 px-3 py-1.5 text-white"
            >
              <h3 class="font-semibold text-base">RTP</h3>
              <span class="text-lg font-semibold">{{
                game.rtp.toFixed(2)
              }}</span>
            </div>
            <div
              class="flex flex-col justify-center items-center rounded-sm bg-zinc-700 px-3 py-1.5 text-white"
            >
              <h3 class="font-semibold text-base">Hit rate</h3>
              <span class="text-lg font-semibold">{{
                game.hit_frequency
              }}</span>
            </div>
            <div
              class="flex flex-col justify-center items-center rounded-sm bg-zinc-700 px-3 py-1.5 text-white"
            >
              <h3 class="font-semibold text-base">Volatility</h3>
              <div class="flex gap-2 text-lg" :title="game.volatility">
                <div
                  v-for="index in fullCircle(game)"
                  :key="`circle${index}`"
                  class="text-2xl"
                >
                  <font-awesome-icon :icon="['fa', 'circle']" />
                </div>
                <div v-if="hasHalfCircle(game)" class="text-2xl">
                  <font-awesome-icon :icon="['fas', 'circle-half-stroke']" />
                </div>
                <div
                  v-for="(n, index) in emptyCircles(game)"
                  :key="`emptyCircle${index}`"
                  class="text-2xl"
                >
                  <font-awesome-icon :icon="['far', 'circle']" />
                </div>
              </div>
            </div>
            <div
              class="flex flex-col justify-center items-center rounded-sm bg-zinc-700 px-3 py-1.5 text-white"
            >
              <h3 class="font-semibold text-base">Max win</h3>
              <span class="text-lg font-semibold">{{
                game.max_multiplier_win
              }}</span>
            </div>
            <div
              class="flex flex-col justify-center items-center rounded-sm bg-zinc-700 px-3 py-1.5 text-white"
            >
              <h3 class="font-semibold text-base">Max bet</h3>
              <span class="text-lg font-semibold">{{ game.max_bet }} €</span>
            </div>
            <div
              class="flex flex-col justify-center items-center rounded-sm bg-zinc-700 px-3 py-1.5 text-white"
            >
              <h3 class="font-semibold text-base">Max exposure ratio</h3>
              <span class="text-lg font-semibold">{{
                game.max_exposure_ratio
              }}</span>
            </div>
            <div
              v-for="(value, key, index) in game.propertiesData"
              :key="`propertiesData${index}`"
              class="flex flex-col justify-center items-center rounded-sm bg-zinc-700 px-3 py-1.5 text-white"
            >
              <h3 class="font-semibold text-base">{{ key }}</h3>
              <span class="text-lg font-semibold">{{ value }}</span>
            </div>
          </div>
        </div>
        <button
          @click="removeGame(game)"
          class="absolute top-2 right-2 bg-red-600 text-white fugaz uppercase px-4 xl:hover:scale-105"
        >
          remove game
        </button>
      </div>
      <div class="flex justify-center mt-2">
        <button
          class="bg-orange-600 text-white fugaz uppercase px-4 py-1 xl:hover:scale-105 w-max"
        >
          Finish request
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewRequested",
  data() {
    return {};
  },
  created() {
    if (!this.games.length) {
      this.$router.push({ name: "Catalog" });
    }
  },
  computed: {
    games() {
      return this.$store.getters["requesting/gamesToRequest"];
    },
  },
  methods: {
    removeGame(game) {
      const gamesToRequestIDs = this.games.map((game) => game.id);
      const index = gamesToRequestIDs.indexOf(game.id);
      this.$store.dispatch("requesting/removeGamesToRequestAction", index);
    },
    getGameMainImageSrc(game) {
      const mainImage = game.game_media?.find(
        (image) => image.isMainImage === true
      );
      return mainImage
        ? mainImage.url
        : require(`@/assets/no-image-available.jpg`);
    },
    openGame(game) {
      this.$router.push({
        name: "Game",
        params: {
          id: game.id,
        },
      });
    },
    fullCircle(game) {
      return Math.floor(game.volatility / 2);
    },
    hasHalfCircle(game) {
      return game.volatility % 2 !== 0;
    },
    emptyCircles(game) {
      const maxCircles = 5;
      return Math.floor(maxCircles - game.volatility / 2);
    },
  },
};
</script>

<style></style>

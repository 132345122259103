<template>
  <div
    class="flex flex-col max-w-full w-full"
  >
    <h4 v-if="description" class="text-white capitalize text-base">{{ description }}</h4>
    <ckeditor v-model="text" :editor="editor" @input="$emit('input', text)" :config="editorConfig"></ckeditor>
  </div>
</template>
<script>
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Font } from '@ckeditor/ckeditor5-font';
import { Alignment } from '@ckeditor/ckeditor5-alignment';  
import { List } from '@ckeditor/ckeditor5-list';


export default {
  data() {
    return {
      text: "",
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Alignment,
          Essentials,
          Bold,
          Italic,
          Heading,
          Font,
          List,
        ],
        heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
          ]
        },
        toolbar: {
            items: [
              'heading',
              '|',
              'alignment',
              '|',
              'bold',
              'italic',
              '|',
              'bulletedList',
              'numberedList',
              '|',
              'fontFamily',
              'fontSize',
              'fontColor',
              'fontBackgroundColor',
              '|',
              'undo',
              'redo',
            ]
        }
      }
    }
  },
  mounted() {
    this.text = this.value
  },
  props: {
    value: String,
    description: String,
  }
}

</script>
<style scoped>

</style>
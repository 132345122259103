<template>
  <div class="w-full h-full min-h-screen flex justify-center items-start bg-zinc-200 dark:bg-zinc-900">
    <div v-if="operators && operators.length" class="bg-white dark:bg-zinc-800 w-11/12 h-11/12 rounded-md shadow-md my-4 md:my-8 lg:my-12 xl:my-14">
      <div class="sticky top-0 flex justify-between items-center rounded-t-md bg-white dark:bg-zinc-800 w-full pt-5 pb-6 px-4 border-b border-zinc-300 dark:border-zinc-700">
        <h2 class="font-semibold text-4xl dark:text-white">
          Operators
        </h2>
        <div class="w-full flex justify-end pt-1">
          <button class="px-3 text-lg rounded-md font-semibold bg-green-500 text-white xl:hover:scale-105">Add operator</button>
        </div>
      </div>
      <div class="flex flex-col p-4">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden  sm:rounded-lg">
              <table class="min-w-full divide-y divide-zinc-200">
                <thead class="bg-zinc-50 dark:bg-zinc-500 text-zinc-500 dark:text-white">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-md font-medium uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" class="hidden xl:table-cell px-6 py-3 text-left text-md font-medium uppercase tracking-wider">
                      email
                    </th>
                    <th scope="col" class="hidden xl:table-cell px-6 py-3 text-left text-md font-medium uppercase tracking-wider">
                      role
                    </th>
                    <th scope="col" class=" py-4 text-md font-medium uppercase tracking-wider text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(operator, index) in paginatedOperators" :key="operator.id" :class="index % 2 === 0 ? 'bg-white dark:bg-zinc-600' : 'bg-zinc-50 dark:bg-zinc-700'">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="">
                          <div class="text-lg font-medium text-zinc-900 dark:text-white">
                            {{ operator.name }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap hidden xl:table-cell">
                      <a :href="operator.url" class="text-lg text-blue-500 xl:hover:text-blue-800">{{ operator.email }}</a>
                    </td>
                    <td class="hidden md:table-cell px-6 py-4 whitespace-nowrap text-lg text-zinc-500 dark:text-white text-left">
                      {{ operator.role }}
                    </td>
                    <td class="py-4 whitespace-nowrap text-lg text-zinc-500 dark:text-white flex justify-center gap-x-2">
                      <button class="px-3 rounded-md bg-blue-500 text-white font-semibold xl:hover:scale-105">Edit</button>
                      <button class="px-3 rounded-md bg-red-500 text-white font-semibold xl:hover:scale-105">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="flex justify-between p-4 dark:bg-zinc-700">
                <div class="flex items-center text-zinc-600 dark:text-white text-md">
                  <span class="mr-1.5">Show</span>
                  <select v-model="perPage" class="outline-none border rounded-md p-2 dark:bg-zinc-800">
                    <option v-for="n in [10, 20, 50]" :key="n" :value="n">{{ n }} rows</option>
                  </select>
                </div>
                <div class="flex items-center">
                  <span class="mr-2 text-zinc-600 dark:text-white">Page {{ currentPage }}</span>
                  <nav class="border rounded-md py-2 px-3 text-lg text-zinc-500 dark:text-white">
                    <ul class="inline-flex gap-x-3">
                      <li>
                        <button :disabled="currentPage === 1" @click="currentPage -= 1">
                          <font-awesome-icon :icon="['fas', 'arrow-left']" />
                        </button>
                      </li>
                      <li v-for="page in Math.ceil(operators.length / perPage)" :key="page">
                        <button :class="currentPage === page ? 'font-bold' : ''" @click="currentPage = page">
                          {{ page }}
                        </button>
                      </li>
                      <li>
                        <button :disabled="currentPage === Math.ceil(operators.length / perPage)" @click="currentPage += 1">
                          <font-awesome-icon :icon="['fas', 'arrow-right']" />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import request from '../../services/axios'
import tempOperators from '../../assets/tempOperators.json'

export default {
  name: 'OperatorsView',
  data() {
    return {
      operators: tempOperators,
      currentPage: 1,
      perPage: 10,
    }
  },
  computed: {
    paginatedOperators() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.operators.slice(start, end)
    }
  },
}
</script>

<style>

</style>
const state = {
  valuesWidth: {
    bet: 0,
    win: 0,
    grosswin: 0
  }
}

const getters = {
  valuesWidth: state => state.valuesWidth,
}

const actions = {
  setValuesWidthAction({commit}, payload) {
    commit('setValuesWidth', payload)
  },
}

const mutations = {
  setValuesWidth(state, payload) {
    state.showGallery = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
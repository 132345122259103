<!-- <template>
  <VueDatePicker
    v-model="date"
    :range="type === 'custom'"
    :month-picker="type === 'monthly'"
    :enable-time-picker="type === 'custom' || inline"
    :format="type !== 'custom' ? format : formatRange"
    :locale="appLanguage.long"
    :inline="inline"
    :min-date="minDate"
    time-picker-inline
    auto-apply
    dark
    @update:model-value="updateDateOnStore"
  >
    <!-- :start-time="{ hours: 0, minutes: 0 }" -->
    <!-- :auto-apply="inline" -->
    <!-- @internal-model-change="internalModelChangeHandler" -->
    <!-- @blur="blurHandler"
  </VueDatePicker>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: { VueDatePicker },

  props: {
    type: {
      type: String,
      default: () => "daily",
    },
    inline: {
      type: Boolean,
      default: () => false,
    },
    minDate: {
      type: String || null,
      default: () => null,
    },
  },

  data() {
    return {
      date: new Date(),
    };
  },

  mounted() {
    debugger;
    if (this.$store.state.filters.date) {
      this.date = this.$store.state.filters.date;
    } else {
      this.typeHandler();
    }
  },

  computed: {
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
  },

  watch: {
    type: {
      handler(val) {
        this.typeHandler(val);
      },
      immediate: true,
    },
  },

  methods: {
    typeHandler(val) {
      switch (val) {
        case "custom":
          debugger;
          if (this.$store.state.filters.dateType !== "custom") {
            this.date = [this.getTodayStart(), this.getTodayEnd()];
            this.updateDateOnStore();
          } else {
            this.date = this.$store.state.filters.date;
          }
          break;

        case "daily":
          debugger;
          if (this.$store.state.filters.dateType !== "daily") {
            this.date = new Date();
            this.updateDateOnStore();
          } else {
            this.date = this.$store.state.filters.date;
          }
          break;

        case "monthly":
          debugger;
          if (this.$store.state.filters.dateType !== "monthly") {
            const date = new Date();
            const obj = { month: date.getMonth(), year: date.getFullYear() };
            this.date = obj;
            this.updateDateOnStore();
          } else {
            this.date = this.$store.state.filters.date;
          }
          break;
      }
    },

    updateDateOnStore() {
      this.$store.commit("filters/updateDate", this.date);
      this.$store.commit("filters/updateDateType", this.type);

      // this.$emit("updateDate", this.date);
    },
    getTodayStart() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },

    getTodayEnd() {
      const today = new Date();
      today.setHours(23, 59, 59, 999);
      return today;
    },

    format(date) {
      console.log(date);
      if (date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        if (this.type !== "monthly") {
          // this.updateDateOnStore();
          return `${String(day).padStart(2, "0")}/${String(month).padStart(
            2,
            "0"
          )}/${year}`;
        } else {
          // this.updateDateOnStore();
          return `${String(month).padStart(2, "0")}/${year}`;
        }
      } else {
        // this.updateDateOnStore();
        return "--/--/----";
      }
    },

    formatRange(date) {
      if (date && date[0]) {
        const day1 = date[0].getDate();
        const month1 = date[0].getMonth() + 1;
        const year1 = date[0].getFullYear();
        const hours1 = date[0].getHours();
        const minutes1 = date[0].getMinutes();
        if (date[1]) {
          const day2 = date[1].getDate();
          const month2 = date[1].getMonth() + 1;
          const year2 = date[1].getFullYear();
          const hours2 = date[1].getHours();
          const minutes2 = date[1].getMinutes();

          // this.updateDateOnStore();
          return `${String(day1).padStart(2, "0")}/${String(month1).padStart(
            2,
            "0"
          )}/${year1}, ${String(hours1).padStart(2, "0")}:${String(
            minutes1
          ).padStart(2, "0")} - ${String(day2).padStart(2, "0")}/${String(
            month2
          ).padStart(2, "0")}/${year2}, ${String(hours2).padStart(
            2,
            "0"
          )}:${String(minutes2).padStart(2, "0")}`;
        }
        // this.updateDateOnStore();
        return `${String(day1).padStart(2, "0")}/${String(month1).padStart(
          2,
          "0"
        )}/${year1}, ${String(hours1).padStart(2, "0")}:${String(
          minutes1
        ).padStart(2, "0")}`;
      }
      return "--/--/----, --:--";
    },
  },
};
</script> -->
<template>
  <VueDatePicker
    v-model="date"
    :range="type === 'custom'"
    :month-picker="type === 'monthly'"
    :enable-time-picker="type === 'custom' || inline"
    :format="type !== 'custom' ? format : formatRange"
    :locale="appLanguage.long"
    :inline="inline"
    :min-date="minDate"
    time-picker-inline
    auto-apply
    dark
    @update:model-value="updateDateOnStore"
  >
  </VueDatePicker>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: { VueDatePicker },

  props: {
    type: {
      type: String,
      default: "daily",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: [String, null],
      default: null,
    },
  },

  data() {
    return {
      date: new Date(), // Placeholder, but will be updated by Vuex
    };
  },

  mounted() {
    if (
      this.$store.state.filters.date.start &&
      this.$store.state.filters.date.end
    ) {
      this.date = [
        this.$store.state.filters.date.start,
        this.$store.state.filters.date.end,
      ];
    } else {
      this.typeHandler();
    }
  },

  computed: {
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    // Access the date and type directly from Vuex
    dateFromStore() {
      return this.$store.state.filters.date;
    },
    dateTypeFromStore() {
      return this.$store.state.filters.dateType;
    },
  },

  watch: {
    type: {
      handler(val) {
        this.typeHandler(val); // Re-run type handling logic when `type` changes
      },
      immediate: true,
    },
  },

  methods: {
    async typeHandler(val) {
      switch (val) {
        case "custom":
          if (this.$store.state.filters.dateType !== "custom") {
            // Fetch the custom date (today start/end) from Vuex store
            await this.$store.dispatch("filters/getTodayDate");
            this.date = [this.dateFromStore.start, this.dateFromStore.end]; // Set the date to the custom range
          } else {
            this.date = [this.dateFromStore.start, this.dateFromStore.end];
          }
          break;

        case "daily":
          if (this.$store.state.filters.dateType !== "daily") {
            const today = new Date();
            this.date = today; // Set today as the single daily date
            this.updateDateOnStore();
          } else {
            this.date = this.dateFromStore;
            // this.date = new Date(this.dateFromStore.start);
          }
          break;

        case "monthly":
          if (this.$store.state.filters.dateType !== "monthly") {
            const date = new Date();
            const obj = { month: date.getMonth(), year: date.getFullYear() };
            this.date = obj;
            this.updateDateOnStore();
          } else {
            this.date = this.dateFromStore;
          }
          break;
      }
    },

    updateDateOnStore() {
      const dateToStore = Array.isArray(this.date)
        ? { start: this.date[0], end: this.date[1] }
        : { start: this.date };
      this.$store.commit("filters/updateDate", dateToStore);
      this.$store.commit("filters/updateDateType", this.type);
    },

    format(date) {
      if (date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        if (this.type !== "monthly") {
          return `${String(day).padStart(2, "0")}/${String(month).padStart(
            2,
            "0"
          )}/${year}`;
        } else {
          return `${String(month).padStart(2, "0")}/${year}`;
        }
      }
      return "--/--/----";
    },

    formatRange(date) {
      if (date && date[0]) {
        const day1 = date[0].getDate();
        const month1 = date[0].getMonth() + 1;
        const year1 = date[0].getFullYear();
        const hours1 = date[0].getHours();
        const minutes1 = date[0].getMinutes();
        if (date[1]) {
          const day2 = date[1].getDate();
          const month2 = date[1].getMonth() + 1;
          const year2 = date[1].getFullYear();
          const hours2 = date[1].getHours();
          const minutes2 = date[1].getMinutes();

          return `${String(day1).padStart(2, "0")}/${String(month1).padStart(
            2,
            "0"
          )}/${year1}, ${String(hours1).padStart(2, "0")}:${String(
            minutes1
          ).padStart(2, "0")} - ${String(day2).padStart(2, "0")}/${String(
            month2
          ).padStart(2, "0")}/${year2}, ${String(hours2).padStart(
            2,
            "0"
          )}:${String(minutes2).padStart(2, "0")}`;
        }
        return `${String(day1).padStart(2, "0")}/${String(month1).padStart(
          2,
          "0"
        )}/${year1}, ${String(hours1).padStart(2, "0")}:${String(
          minutes1
        ).padStart(2, "0")}`;
      }
      return "--/--/----, --:--";
    },
  },
};
</script>


<style lang="postcss">
.dp__theme_dark {
  --dp-primary-color: theme(colors.orange.600);
  --dp-background-color: rgb(24, 24, 27);
}

.dp__btn {
  @apply fugaz uppercase;
}
</style>

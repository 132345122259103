<template>
  <div
    v-if="!serverError"
    class="w-full h-max min-h-full max-w-full bg-zinc-950 flex flex-col relative"
  >
    <button
      class="hidden xl:block fixed bottom-[50px] xl:bottom-0 text-white xl:hover:text-orange-600 transition-all duration-100 bg-zinc-950 fugaz uppercase px-2.5 pt-1 rounded-tr-lg z-[99] border-t border-r border-zinc-700"
      :class="sidebarFixed ? 'left-0 xl:left-72' : 'left-0 xl:left-20'"
      @click="$router.push({ name: 'Sessions' })"
    >
      <font-awesome-icon :icon="['fas', 'backward']" class="h-4 pr-1" />
      Sessions
    </button>
    <!-- header  -->
    <div class="flex justify-between px-4 md:px-7 pt-4 pb-4">
      <h2 class="text-white text-3xl xl:text-4xl fugaz uppercase pt-0.5">
        {{ $t("sessionsList.title") }}
      </h2>
      <div class="flex items-center gap-2">
        <div v-show="loading" class="flex items-center justify-center pr-2">
          <img
            src="../../assets/loading2.png"
            alt="loading icon"
            class="h-7 text-zinc-200 animate-spin"
          />
        </div>
        <div
          class="flex items-end gap-2 fugaz uppercase font-thin text-zinc-200 -mb-1 pr-2 pb-0.5"
        >
          <div v-if="fetchingSessions" class="flex items-center gap-2">
            {{ $t("sessionsList.fetching") }}
            <img
              src="../../assets/loading2.png"
              alt="loading icon"
              class="text-zinc-600 dark:text-zinc-400 h-6 animate-spin"
            />
          </div>
          <div v-else-if="sessionsFound" class="hidden md:block">
            {{ sessionsFound }} {{ $t("sessionsList.sessionsFound") }}
          </div>
        </div>
        <button
          @click="searching = true"
          class="py-1.5 text-zinc-200 xl:hover:text-orange-600 fugaz uppercase flex items-center text-lg lg:text-xl transition-all duration-150"
        >
          <font-awesome-icon
            :icon="['fas', 'magnifying-glass']"
            class="text-orange-600 pr-1 pb-0.5"
          />
          <span class="hidden md:block pl-1.5">{{
            $t("sessionsList.search")
          }}</span>
        </button>
      </div>
    </div>
    <div
      v-if="ready"
      class="w-full rounded-md flex flex-col z-10 pb-[60px] xl:pb-0"
    >
      <!-- filters  -->
      <div class="flex justify-between items-end px-4 md:px-[30px]">
        <div
          v-if="ready"
          class="w-full flex flex-wrap items-end justify-center md:justify-start gap-3 rounded-smpt-1 border-zinc-700"
        >
          <div class="flex items-end gap-3 pb-1 w-full md:w-max">
            <div class="flex flex-col z-[30]">
              <div class="flex justify-between">
                <span class="text-zinc-300 whitespace-nowrap text-sm">
                  {{ $t("sessionsList.dateRange") }}
                </span>
              </div>
              <!-- <v-date-picker
                v-model.range="dateRange"
                is-dark
                mode="dateTime"
                color="pink"
                is24hr
                :max-date="new Date()"
                :locale="appLanguage.long"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center gap-2">
                    <input
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      class="px-2 py-1 w-full md:w-[146px] rounded focus:outline-none bg-zinc-800 text-white"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'arrow-right']"
                      class="h-4 text-white"
                    />
                    <input
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      class="px-2 py-1 w-full md:w-[146px] rounded focus:outline-none bg-zinc-800 text-white"
                    />
                  </div>
                </template>
              </v-date-picker> -->
              <div class="w-[22rem]">
                <DatePicker type="custom" />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center justify-center gap-3 mb-1.5">
            <div class="flex items-center gap-1">
              <button
                @click="isOpen = false"
                class="text-lg font-semibold"
                :class="
                  isOpen === false || isOpen === null
                    ? 'text-white'
                    : 'text-zinc-400 xl:hover:text-white'
                "
              >
                {{ $t("sessionsList.closed") }}
              </button>
              <label
                class="flex gap-2 items-center cursor-pointer relative px-2 rounded-md h-max"
              >
                <input
                  type="checkbox"
                  class="sr-only outline-none"
                  @input="changeIsOpen"
                />
                <div
                  :class="[
                    'block w-14 h-8 rounded-full relative transition-all duration-300',
                    {
                      'bg-sky-500': isOpen === true,
                      'bg-green-500': isOpen === false,
                      'bg-zinc-800': isOpen === null,
                    },
                  ]"
                >
                  <div
                    class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                    :class="{
                      'translate-x-6': isOpen === true,
                      'translate-x-3': isOpen === null,
                    }"
                  ></div>
                </div>
              </label>
              <button
                @click="isOpen = true"
                class="text-lg font-semibold"
                :class="
                  isOpen === true || isOpen === null
                    ? 'text-white'
                    : 'text-zinc-400 xl:hover:text-white'
                "
              >
                {{ $t("sessionsList.open") }}
              </button>
            </div>
            <button
              @click="getAllSessions(true)"
              :disabled="loading"
              class="hidden md:block px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
            >
              {{ $t("sessionsList.submit") }}
            </button>
            <Transition>
              <span v-if="activeFilters.count > 0" class="text-white font-light"
                >{{ activeFilters.count }} Active Filters</span
              >
            </Transition>
          </div>
        </div>
        <div class="flex items-center gap-1.5">
          <button
            @click="openCSVInput = true"
            :disabled="loading"
            class="hidden md:block pl-3 pr-2.5 pt-1 pb-[3px] h-max text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:pointer-events-none disabled:opacity-50"
          >
            <!-- {{ $t('sessionsList.submit') }} -->
            <font-awesome-icon :icon="['fas', 'file-csv']" />
          </button>
        </div>
      </div>
      <!-- mobile submit -->
      <div class="flex md:hidden items-center justify-center gap-1 w-full mt-3">
        <button
          @click="getAllSessions(true)"
          :disabled="loading"
          class="px-3 pt-1 pb-[3px] text-orange-600 bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
        >
          {{ $t("sessionsList.submit") }}
        </button>
      </div>
      <div class="px-4 md:px-[30px] mb-1 mt-2 flex justify-center">
        <button
          @click="moreFilters = !moreFilters"
          class="text-white xl:hover:text-orange-600 text-sm fugaz uppercase"
        >
          {{ moreFilters ? "Close" : "More" }} filters
          <font-awesome-icon
            :icon="['fas', `caret-${moreFilters ? 'up' : 'down'}`]"
            class="pl-1 h-4"
          />
        </button>
      </div>
      <div
        v-if="moreFilters"
        class="px-4 md:px-[30px] flex flex-wrap items-center justify-center md:justify-start gap-2 mb-1.5 transition-all duration-200"
      >
        <!-- <div class="flex flex-wrap items-center gap-2.5 justify-center"> -->
        <div
          v-if="userRoles.isLudusAdmin || userRoles.isLudusManager"
          class="w-full md:w-max min-w-[210px] whitespace-nowrap"
        >
          <Multiselect
            mode="multiple"
            v-model="selectedPlatforms"
            :options="allPlatforms"
            label="name"
            value-prop="id"
            :placeholder="$t('sessionsList.filterByPlat')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :multiple-label="(values) => `${values.length} Platforms selected`"
          />
        </div>
        <div v-if="userPlatform !== null" class="w-full md:w-max min-w-[210px]">
          <Multiselect
            mode="multiple"
            v-model="selectedOperators"
            :options="platformOperators"
            label="name"
            value-prop="id"
            :placeholder="$t('sessionsList.filterByOperator')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :multiple-label="(values) => `${values.length} Operators selected`"
          />
        </div>
        <div class="w-full md:w-max min-w-[230px]">
          <Multiselect
            mode="multiple"
            v-model="selectedGames"
            :options="
              allGames.filter((game) =>
                selectedPlatforms.length > 0
                  ? game.platforms.some((platform) =>
                      selectedPlatforms.includes(platform.id)
                    )
                  : true
              )
            "
            label="name"
            value-prop="id"
            :placeholder="$t('sessionsList.filterByGame')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :multiple-label="(values) => `${values.length} Games selected`"
          >
          </Multiselect>
        </div>
        <input
          v-model="userId"
          type="text"
          placeholder="Filter by UserID"
          class="py-2 px-2.5 text-white bg-zinc-900 rounded-md outline-none w-full md:w-36"
        />
        <button
          @click="nonZeroRounds = !nonZeroRounds"
          class="px-2.5 pb-2 pt-2.5 rounded-md fugaz uppercase text-sm transition-all duration-100"
          :class="
            nonZeroRounds
              ? 'bg-orange-600 text-white'
              : 'bg-zinc-800 text-white xl:hover:text-orange-600'
          "
        >
          Rounds > 0
        </button>
        <div
          class="text-white border-zinc-700 flex items-center transition-all duration-100"
          :class="betRange ? 'w-80 pb-3 pt-1.5' : 'w-[100px]'"
        >
          <div v-if="betRange" class="flex flex-col items-center gap-1.5 group">
            <span class="fugaz uppercase text-xs cursor-default">Min</span>
            <input
              v-model="betRangeValues[0]"
              type="number"
              min="0.1"
              class="py-0.5 px-1.5 text-white bg-zinc-900 rounded-md outline-none w-full md:w-[56px]"
            />
          </div>
          <!-- this is just a spacer, Slider is ignoring margins or gap -->
          <font-awesome-icon
            v-if="betRange"
            :icon="['fas', 'minus']"
            class="invisible"
          />
          <div class="flex flex-col items-center gap-2 w-full">
            <button
              @click.stop="betRange = !betRange"
              class="fugaz uppercase text-sm xl:hover:text-orange-600 whitespace-nowrap"
              :class="
                betRange
                  ? 'text-orange-600'
                  : 'w-full pb-2 pt-2.5 bg-zinc-800 rounded-md'
              "
            >
              Bet range
            </button>
            <Slider
              v-if="betRange"
              :disabled="!betRange"
              v-model="betRangeValues"
              :showTooltip="'focus'"
              :format="format"
              :max="maxBetRange"
              class="pl-1.5 w-full"
            />
          </div>
          <font-awesome-icon
            v-if="betRange"
            :icon="['fas', 'minus']"
            class="invisible"
          />
          <div v-if="betRange" class="flex flex-col items-center gap-1.5 group">
            <span class="fugaz uppercase text-xs cursor-default">Max</span>
            <input
              v-model="betRangeValues[1]"
              type="number"
              min="0.1"
              class="py-0.5 px-1.5 text-white bg-zinc-900 rounded-md outline-none w-full md:w-[56px]"
            />
          </div>
        </div>
        <div
          class="text-white border-zinc-700 flex items-center transition-all duration-100"
          :class="winRange ? 'w-80 pb-3 pt-1.5' : 'w-[100px]'"
        >
          <div v-if="winRange" class="flex flex-col items-center gap-1.5 group">
            <span class="fugaz uppercase text-xs cursor-default">Min</span>
            <input
              v-model="winRangeValues[0]"
              type="number"
              min="0.1"
              class="py-0.5 px-1.5 text-white bg-zinc-900 rounded-md outline-none w-full md:w-[56px]"
            />
          </div>
          <!-- this is just a spacer, Slider is ignoring margins or gap -->
          <font-awesome-icon
            v-if="winRange"
            :icon="['fas', 'minus']"
            class="invisible"
          />
          <div class="flex flex-col items-center gap-2 w-full">
            <button
              @click.stop="winRange = !winRange"
              class="fugaz uppercase text-sm xl:hover:text-orange-600 whitespace-nowrap"
              :class="
                winRange
                  ? 'text-orange-600'
                  : 'w-full pb-2 pt-2.5 bg-zinc-800 rounded-md'
              "
            >
              Win range
            </button>
            <Slider
              v-if="winRange"
              :disabled="!winRange"
              v-model="winRangeValues"
              :showTooltip="'focus'"
              :format="format"
              :max="maxWinRange"
              class="pl-1.5 w-full"
            />
          </div>
          <font-awesome-icon
            v-if="winRange"
            :icon="['fas', 'minus']"
            class="invisible"
          />
          <div v-if="winRange" class="flex flex-col items-center gap-1.5 group">
            <span class="fugaz uppercase text-xs cursor-default">Max</span>
            <input
              v-model="winRangeValues[1]"
              type="number"
              min="0.1"
              class="py-0.5 px-1.5 text-white bg-zinc-900 rounded-md outline-none w-full md:w-[56px]"
            />
          </div>
        </div>
        <div
          class="text-white border-zinc-700 flex items-center transition-all duration-100"
          :class="grossWinRange ? 'w-80 pb-3 pt-1.5' : 'w-[150px]'"
        >
          <div
            v-if="grossWinRange"
            class="flex flex-col items-center gap-1.5 group"
          >
            <span class="fugaz uppercase text-xs cursor-default">Min</span>
            <input
              v-model="grossWinRangeValues[0]"
              type="number"
              min="0.1"
              class="py-0.5 px-1.5 text-white bg-zinc-900 rounded-md outline-none w-full md:w-[56px]"
            />
          </div>
          <!-- this is just a spacer, Slider is ignoring margins or gap -->
          <font-awesome-icon
            v-if="grossWinRange"
            :icon="['fas', 'minus']"
            class="invisible"
          />
          <div class="flex flex-col items-center gap-2 w-full">
            <button
              @click.stop="grossWinRange = !grossWinRange"
              class="fugaz uppercase text-sm xl:hover:text-orange-600 whitespace-nowrap"
              :class="
                grossWinRange
                  ? 'text-orange-600'
                  : 'w-full pb-2 pt-2.5 bg-zinc-800 rounded-md'
              "
            >
              Gross win range
            </button>
            <Slider
              v-if="grossWinRange"
              :disabled="!grossWinRange"
              v-model="grossWinRangeValues"
              :showTooltip="'focus'"
              :format="format"
              :max="maxGrossWinRange"
              class="pl-1.5 w-full"
            />
          </div>
          <font-awesome-icon
            v-if="grossWinRange"
            :icon="['fas', 'minus']"
            class="invisible"
          />
          <div
            v-if="grossWinRange"
            class="flex flex-col items-center gap-1.5 group"
          >
            <span class="fugaz uppercase text-xs cursor-default">Max</span>
            <input
              v-model="grossWinRangeValues[1]"
              type="number"
              min="0.1"
              class="py-0.5 px-1.5 text-white bg-zinc-900 rounded-md outline-none w-full md:w-[56px]"
            />
          </div>
        </div>
        <div class="mr-1.5 flex items-cener gap-1">
          <button
            @click="changeSessionsType('fun')"
            class="group flex items-center font-semibold px-2 rounded-md"
            :class="[
              sessionsType == 'fun' || sessionsType == 'all'
                ? 'text-white'
                : 'text-zinc-400 xl:hover:text-white',
            ]"
          >
            <span class="text-lg">{{ $t("sessionsList.fun") }}</span>
            <font-awesome-icon :icon="['fas', 'gift']" class="h-4 pl-1" />
          </button>
          <label
            class="gap-2 items-center cursor-pointer relative px-2 rounded-md h-max"
          >
            <input
              type="checkbox"
              class="sr-only outline-none"
              @input="changeSessionsType()"
            />
            <div
              :class="[
                'block w-14 h-8 rounded-full relative transition-all duration-300 bg-zinc-800',
              ]"
            >
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                :class="{
                  'translate-x-6': sessionsType === 'real',
                  'translate-x-3': sessionsType === 'all',
                }"
              ></div>
            </div>
          </label>
          <button
            @click="changeSessionsType('real')"
            class="group flex items-center font-semibold px-2 rounded-md"
            :class="[
              sessionsType == 'real' || sessionsType == 'all'
                ? 'text-white'
                : 'text-zinc-400 xl:hover:text-white',
            ]"
          >
            <span class="text-lg">{{ $t("sessionsList.real") }}</span>
            <font-awesome-icon :icon="['fas', 'euro-sign']" class="h-4 pl-1" />
          </button>
        </div>
        <!-- </div> -->
        <!-- <button
          v-if="moreFilters"
          @click="getAllSessions(true)"
          :disabled="loading"
          class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
        >
          {{ $t('sessionsList.submit') }}
        </button> -->
      </div>
      <div v-if="ready" class="w-full rounded-sm px-3 md:px-7">
        <table
          class="table table-auto w-full divide-zinc-900 shadow-md border-separate border-spacing-y-[3px]"
        >
          <thead
            class="text-white text-lg fugaz uppercase bg-zinc-800 sticky top-0 z-20"
          >
            <tr class="">
              <th
                class="table-cell py-2 font-thin text-zinc-100 cursor-default rounded-tl-sm"
              >
                {{
                  userPlatform
                    ? $t("sessionsList.operator")
                    : $t("sessionsList.platform")
                }}
              </th>
              <th
                class="hidden md:table-cell font-thin text-zinc-100 cursor-default"
              >
                {{ $t("sessionsList.game") }}
              </th>
              <th
                class="hidden sm:table-cell font-thin text-zinc-100 cursor-default"
              >
                AAMS Ticket ID
              </th>
              <th
                @click="sortTable('bet')"
                class="table-cell font-thin"
                :class="loading ? 'cursor-not-allowed' : 'cursor-pointer'"
              >
                <div class="flex justify-center items-center">
                  <!-- ghost icon -->
                  <font-awesome-icon
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                  Bet
                  <button class="uppercase">
                    <font-awesome-icon
                      v-if="
                        tableSort.order === 'bet' &&
                        tableSort.direction == 'desc'
                      "
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2"
                    />
                    <font-awesome-icon
                      v-if="
                        tableSort.order === 'bet' &&
                        tableSort.direction == 'asc'
                      "
                      :icon="['fas', 'sort-up']"
                      class="px-2 pt-2.5"
                    />
                  </button>
                  <!-- ghost icon -->
                  <font-awesome-icon
                    v-if="tableSort.order !== 'bet'"
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                </div>
              </th>
              <th
                @click="sortTable('win')"
                class="hidden sm:table-cell font-thin"
                :class="loading ? 'cursor-not-allowed' : 'cursor-pointer'"
              >
                <div class="flex justify-center items-center">
                  <!-- ghost icon -->
                  <font-awesome-icon
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                  Win
                  <button class="uppercase" v-if="tableSort.order == 'win'">
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'win' &&
                        tableSort.direction == 'desc'
                      "
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2"
                    />
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'win' && tableSort.direction == 'asc'
                      "
                      :icon="['fas', 'sort-up']"
                      class="px-2 pt-2.5"
                    />
                  </button>
                  <!-- ghost icon -->
                  <font-awesome-icon
                    v-if="tableSort.order !== 'win'"
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                </div>
              </th>
              <th
                @click="sortTable('grossWin')"
                class="hidden lg:table-cell font-thin truncate"
                :class="loading ? 'cursor-not-allowed' : 'cursor-pointer'"
              >
                <div class="flex justify-center items-center">
                  <font-awesome-icon
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                  Gross Win
                  <button
                    class="uppercase"
                    v-if="tableSort.order == 'grossWin'"
                  >
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'grossWin' &&
                        tableSort.direction == 'desc'
                      "
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2"
                    />
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'grossWin' &&
                        tableSort.direction == 'asc'
                      "
                      :icon="['fas', 'sort-up']"
                      class="px-2 pt-2"
                    />
                  </button>
                  <!-- ghost icon -->
                  <font-awesome-icon
                    v-if="tableSort.order !== 'grossWin'"
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                </div>
              </th>
              <th
                @click="sortTable('netWin')"
                class="hidden lg:table-cell font-thin truncate"
                :class="loading ? 'cursor-not-allowed' : 'cursor-pointer'"
              >
                <div class="flex justify-center items-center">
                  <font-awesome-icon
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                  Net Win
                  <button class="uppercase" v-if="tableSort.order == 'netWin'">
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'netWin' &&
                        tableSort.direction == 'desc'
                      "
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2"
                    />
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'netWin' &&
                        tableSort.direction == 'asc'
                      "
                      :icon="['fas', 'sort-up']"
                      class="px-2 pt-2"
                    />
                  </button>
                  <!-- ghost icon -->
                  <font-awesome-icon
                    v-if="tableSort.order !== 'netWin'"
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                </div>
              </th>
              <th
                @click="sortTable('due')"
                class="hidden lg:table-cell font-thin truncate"
                :class="loading ? 'cursor-not-allowed' : 'cursor-pointer'"
              >
                <div class="flex justify-center items-center">
                  <font-awesome-icon
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                  Due
                  <button class="uppercase" v-if="tableSort.order == 'due'">
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'due' &&
                        tableSort.direction == 'desc'
                      "
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2"
                    />
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'due' && tableSort.direction == 'asc'
                      "
                      :icon="['fas', 'sort-up']"
                      class="px-2 pt-2"
                    />
                  </button>
                  <!-- ghost icon -->
                  <font-awesome-icon
                    v-if="tableSort.order !== 'due'"
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                </div>
              </th>
              <th
                @click="sortTable('currentRound')"
                class="hidden xl:table-cell font-thin"
                :class="loading ? 'cursor-not-allowed' : 'cursor-pointer'"
              >
                <div class="flex justify-center items-center">
                  <!-- ghost icon -->
                  <font-awesome-icon
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                  {{ $t("sessionsList.rounds") }}
                  <button
                    class="uppercase"
                    v-if="tableSort.order == 'currentRound'"
                  >
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'currentRound' &&
                        tableSort.direction == 'desc'
                      "
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2"
                    />
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'currentRound' &&
                        tableSort.direction == 'asc'
                      "
                      :icon="['fas', 'sort-up']"
                      class="px-2 pt-2.5"
                    />
                  </button>
                  <!-- ghost icon -->
                  <font-awesome-icon
                    v-if="tableSort.order !== 'currentRound'"
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                </div>
              </th>
              <th
                @click="sortTable('extUpdatedAt')"
                class="hidden lg:table-cell font-thin"
                :class="loading ? 'cursor-not-allowed' : 'cursor-pointer'"
              >
                <div class="flex justify-center items-center">
                  <!-- ghost icon -->
                  <font-awesome-icon
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                  {{ $t("sessionsList.lastUpdate") }}
                  <button
                    class="uppercase"
                    v-if="tableSort.order == 'extUpdatedAt'"
                  >
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'extUpdatedAt' &&
                        tableSort.direction == 'desc'
                      "
                      :icon="['fas', 'sort-down']"
                      class="pb-1 px-2"
                    />
                    <font-awesome-icon
                      v-if="
                        tableSort.order == 'extUpdatedAt' &&
                        tableSort.direction == 'asc'
                      "
                      :icon="['fas', 'sort-up']"
                      class="px-2 pt-2.5"
                    />
                  </button>
                  <!-- ghost icon -->
                  <font-awesome-icon
                    v-if="tableSort.order !== 'extUpdatedAt'"
                    :icon="['fas', 'sort-down']"
                    class="pb-1 px-2 invisible"
                  />
                </div>
              </th>
              <th class="table-cell"></th>
            </tr>
          </thead>
          <tbody
            v-if="submited && actualSessions.length > 0"
            class="text-white z-10"
          >
            <tr
              v-for="(session, index) in actualSessions.slice(0, listLimit)"
              :key="`session${index}`"
              @click="openSessionBox(session)"
              class="border-zinc-900 text-center cursor-pointer xl:hover:brightness-110"
              :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
            >
              <td v-if="!userPlatform" class="py-2 fugaz uppercase font-thin">
                {{ session.platform.name }}
              </td>
              <td v-else class="py-2 capitalize">
                {{ session.operator.name }}
              </td>
              <td class="hidden md:table-cell py-2 fugaz uppercase font-thin">
                {{
                  session.game.sub_name
                    ? `${session.game.name} - ${session.game.sub_name}`
                    : session.game.name
                }}
              </td>
              <td class="hidden sm:table-cell py-2 max-w-xs truncate">
                {{ session.aamsTicketId }}
              </td>
              <!-- <td class="py-2">{{ session.userId }}</td> -->
              <!-- <td class="py-2">{{ session.isOpen }}</td> -->
              <td class="table-cell py-2 font-medium">
                {{ getCurrencyValue(session.bet) }}
              </td>
              <td class="hidden sm:table-cell py-2 font-medium">
                {{ getCurrencyValue(session.win) }}
              </td>
              <td
                class="hidden lg:table-cell py-2 font-medium"
                :class="[
                  session.grossWin > 0 ? 'text-green-500' : 'text-red-500',
                  (session.grossWin == 0 || session.grossWin == null) &&
                    'text-white',
                ]"
              >
                {{ getCurrencyValue(session.grossWin) }}
              </td>
              <td
                class="hidden lg:table-cell py-2 font-medium"
                :class="[
                  session.netWin > 0 ? 'text-green-500' : 'text-red-500',
                  (session.netWin == 0 || session.netWin == null) &&
                    'text-white',
                ]"
              >
                {{ getCurrencyValue(session.netWin) }}
              </td>
              <td
                class="hidden lg:table-cell py-2 font-medium"
                :class="[
                  session.due > 0 ? 'text-green-500' : 'text-red-500',
                  (session.due == 0 || session.due == null) && 'text-white',
                ]"
              >
                {{ getCurrencyValue(session.due) }}
              </td>
              <td class="hidden xl:table-cell py-2">
                {{ session.currentRound }}
              </td>
              <td class="hidden lg:table-cell py-2">
                {{ clearUpdatedAt(session.extUpdatedAt) }}
              </td>
              <td class="px-2 py-2">
                <font-awesome-icon
                  v-if="session.sessionTypeId === 1"
                  :icon="['fas', 'euro-sign']"
                  :class="session.isOpen ? 'text-sky-500' : 'text-green-500'"
                />
                <font-awesome-icon
                  v-if="session.sessionTypeId === 4"
                  :icon="['fas', 'gift']"
                  :class="session.isOpen ? 'text-sky-500' : 'text-green-500'"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="submited && actualSessions.length === 0"
          class="py-2 w-full flex justify-center text-white text-lg"
        >
          {{ $t("sessionsList.noSessionsFound") }}
        </div>
        <div
          v-if="actualSessions.length > 0"
          class="w-full bg-zinc-900 text-white flex gap-0.5 xl:gap-1.5 items-center justify-center py-2"
          :class="loading ? 'opacity-70 pointer-events-none animate-pulse' : ''"
        >
          <!-- <div class="flex items-center justify-center" :class="loading ? 'opacity-100' : 'opacity-0'">
            <img
              src="../../assets/loading2.png"
              alt="loading icon"
              class="h-7 text-zinc-200 animate-spin"
            />
          </div> -->
          <button
            class="hover:bg-zinc-800 xl:hover:text-orange-600 px-1.5 xl:px-2.5 py-1 rounded-sm cursor-pointer"
            @click="prevPage"
            :disabled="visibleCurrentPage === 1"
          >
            <span class="hidden md:block fugaz uppercase text-sm">Prev</span>
            <font-awesome-icon
              :icon="['fas', 'angle-left']"
              class="block md:hidden"
            />
          </button>
          <button
            @click="goToPage(page)"
            v-for="page in visiblePages"
            :key="page"
            class="text-zinc-400 fugaz uppercase py-1 px-1.5"
            :class="
              page !== '...'
                ? 'hover:text-pink-500 xl:hover:bg-zinc-800'
                : 'cursor-default'
            "
          >
            <span
              v-if="loading && currentPage === page"
              :class="visibleCurrentPage === page && 'text-orange-600'"
            >
              <img
                src="../../assets/loading2.png"
                alt="loading icon"
                class="h-5 text-zinc-200 animate-spin"
              />
            </span>
            <span
              v-else
              :class="visibleCurrentPage === page && 'text-orange-600'"
              >{{ page }}</span
            >
          </button>
          <button
            class="hover:bg-zinc-800 xl:hover:text-orange-600 px-1.5 xl:px-2.5 py-1 rounded-sm cursor-pointer"
            @click="nextPage"
            :disabled="visibleCurrentPage === pagesCount"
          >
            <span class="hidden md:block fugaz uppercase text-sm">Next</span>
            <font-awesome-icon
              :icon="['fas', 'angle-right']"
              class="block md:hidden"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full h-[90vh] flex justify-center items-center bg-zinc-950 text-white"
    >
      <img
        v-if="!serverError"
        src="../../assets/hub-full-logo.png"
        alt="gai hub logo"
        class="h-24 w-auto animate-pulse"
      />
      <!-- <img v-if="!serverError" src="../../assets/loading2.png" alt="loading icon"
        class="text-zinc-400 h-8 animate-spin"
      /> -->
      <div v-if="serverError">
        Error trying to connect to the server.
        <button @click="$router.go()" class="text-blue-600">Reload</button> page
        to try again.
      </div>
    </div>
    <Transition>
      <div
        v-if="storeSession?.id && !searching"
        @click="closeSessionBox"
        class="w-full h-full flex justify-center items-center bg-black bg-opacity-80 fixed top-0 left-0 z-[999]"
      >
        <Session
          :games="allGames"
          :fromSearch="false"
          class="z-20 w-[95%] h-full max-h-[95vh]"
        />
      </div>
    </Transition>
    <Transition>
      <SearchSession
        v-if="searching"
        :userPlatform="userPlatform?.id"
        :closeSearch="closeSearch"
        :allPlatforms="allPlatforms"
      />
    </Transition>
    <Transition>
      <div
        v-if="openCSVInput"
        @click="openCSVInput = false"
        class="w-full h-full flex justify-center items-center bg-black bg-opacity-80 fixed top-0 left-0 z-[999]"
      >
        <SessionsCsv
          @click.stop
          :getAllSessions="getAllSessions"
          :filters="activeFilters.filters"
          :dateRange="dateRange"
          :closeSessionsCSV="closeSessionsCSV"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import request from "@/services/axios";
import Slider from "@vueform/slider";
// import '@vueform/slider/themes/default.css'
import { useDateFormatter } from "@/helpers/dateFormatter.js";
import Session from "@/components/sessions/Session.vue";
import SearchSession from "@/components/sessions/SearchSession.vue";
import SessionsCsv from "@/components/sessions/SessionsCsv.vue";
import DatePicker from "@/components/DatePicker";

export default {
  data() {
    return {
      allSessions: [],
      sessionsFound: null,
      allPlatforms: [],
      userPlatform: null,
      allGames: [],
      isOpen: null,
      search: "",
      tableSort: {
        order: "extUpdatedAt",
        direction: "desc",
      },
      selectedPlatforms: [],
      selectedOperators: [],
      platformOperators: [],
      selectedGames: [],
      listLimit: 14,
      ready: false,
      loading: false,
      serverError: false,
      socketTest: this.socket,
      jobProgress: "",
      sessionsType: "real",
      pagesCount: 0,
      currentPage: 1,
      visibleCurrentPage: 1,
      timer: null,
      searching: false,

      lastFilters: null,
      submited: false,
      debounceTimer: null,
      debounceTimerPagination: null,
      moreFilters: false,
      nonZeroRounds: false,
      betRange: false,
      betRangeValues: [0, 3000],
      winRange: false,
      winRangeValues: [0, 3000],
      grossWinRange: false,
      grossWinRangeValues: [0, 3000],
      format: (value) => {
        return `${Math.round(value)} €`;
      },
      userId: "",
      openCSVInput: false,
      mailJobCalled: false,
      mountTimer: null,
    };
  },
  components: {
    Session,
    Multiselect,
    SearchSession,
    SessionsCsv,
    Slider,
    DatePicker,
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      if (this.$store.getters["sessions/getPlatforms"].length === 0) {
        await this.getAllPlatforms();
      } else {
        this.allPlatforms = this.$store.getters["sessions/getPlatforms"];
      }
      await this.getAllGames();

      this.ready = true;

      // this.socket.on("fetch-sessions", this.resetJobProgress);
      this.emitter.on("sessionUpdated", this.updateSessionsList);
      // window.addEventListener('scroll', this.handleScroll);
      if (this.screenWidth > 1280) this.listLimit = 14;
      if (this.screenWidth < 640) this.listLimit = 11;
    }, 400);
  },
  beforeUnmount() {
    // clearTimeout(this.mountTimer);
    // this.socket.off("fetch-sessions", this.resetJobProgress);
    // window.removeEventListener('scroll', this.handleScroll);
    this.closeSessionBox();
  },
  watch: {
    screenWidth() {
      if (this.screenWidth > 1280) this.listLimit = 14;
      if (this.screenWidth < 640) this.listLimit = 11;
    },
    // selectedGames() {
    //   if (this.selectedGames.length > 0) {
    //     this.activeFilters++
    //   }
    // }
  },
  computed: {
    dateRange() {
      return this.$store.state.filters.date;
    },

    activeJobs() {
      return this.$store.getters["jobs/activeJobs"];
    },
    activeFilters() {
      let filters = {};
      if (this.selectedGames.length > 0) {
        filters.selectedGames = this.selectedGames.map((id) => {
          return this.allGames.find((game) => game.id === id);
        });
      }
      if (this.selectedOperators.length > 0) {
        filters.selectedOperators = this.selectedOperators.map((id) => {
          return this.platformOperators.find((operator) => operator.id === id);
        });
      }
      if (this.selectedPlatforms.length > 0) {
        filters.selectedPlatforms = this.selectedPlatforms.map((id) => {
          return this.allPlatforms.find((platform) => platform.id === id);
        });
      }
      if (this.userId !== "") filters.userId = this.userId;
      if (this.nonZeroRounds) filters.nonZeroRounds = this.nonZeroRounds;
      if (this.betRange) filters.betRange = this.betRangeValues;
      if (this.winRange) filters.winRange = this.winRangeValues;
      if (this.grossWinRange) filters.grossWinRange = this.grossWinRangeValues;
      if (this.isOpen !== null) filters.isOpen = this.isOpen;
      if (this.sessionsType !== "real")
        filters.sessionsType = this.sessionsType;
      return { filters, count: Object.keys(filters).length };
    },
    maxBetRange() {
      if (this.betRangeValues[1] > 3000) {
        return this.betRangeValues[1];
      } else return 3000;
    },
    maxWinRange() {
      if (this.winRangeValues[1] > 3000) {
        return this.winRangeValues[1];
      } else return 3000;
    },
    maxGrossWinRange() {
      if (this.grossWinRangeValues[1] > 3000) {
        return this.grossWinRangeValues[1];
      } else return 3000;
    },
    validBetRange() {
      if (
        this.betRangeValues[1] !== 0 &&
        this.betRangeValues[1] !== "" &&
        this.betRangeValues[0] > this.betRangeValues[1]
      )
        return false;
      else return true;
    },
    validWinRange() {
      if (
        this.winRangeValues[1] !== 0 &&
        this.winRangeValues[1] !== "" &&
        this.winRangeValues[0] > this.winRangeValues[1]
      )
        return false;
      else return true;
    },
    validGrossWinRange() {
      if (
        this.grossWinRangeValues[0] === "" &&
        this.grossWinRangeValues[1] === ""
      )
        return false;
      if (
        this.grossWinRangeValues[0] !== "" &&
        this.grossWinRangeValues[1] !== "" &&
        this.grossWinRangeValues[0] > this.grossWinRangeValues[1]
      )
        return false;
      return true;
    },
    // validSubmit() {
    //   if (this.loading) return false
    //   if (!this.validBetRange) return false
    //   else return true
    // },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    screenWidth() {
      return this.$store.getters["getScreenWidth"];
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
    visiblePages() {
      const pages = [];
      const ellipsis = "...";

      if (this.pagesCount <= 10) {
        for (let i = 1; i <= this.pagesCount; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1, 2);

        if (this.visibleCurrentPage <= 4) {
          pages.push(3, 4, ellipsis, this.pagesCount - 1, this.pagesCount);
        } else if (this.visibleCurrentPage >= this.pagesCount - 3) {
          pages.push(
            ellipsis,
            this.pagesCount - 4,
            this.pagesCount - 3,
            this.pagesCount - 2,
            this.pagesCount - 1,
            this.pagesCount
          );
        } else {
          pages.push(
            ellipsis,
            this.visibleCurrentPage - 1,
            this.visibleCurrentPage,
            this.visibleCurrentPage + 1,
            ellipsis,
            this.pagesCount - 1,
            this.pagesCount
          );
        }
      }

      return pages;
    },
    fetchingSessions() {
      return this.$store.getters["jobs/fetching"];
    },
    lastJob() {
      return this.$store.getters["jobs/lastJob"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
    storeSession() {
      return this.$store.getters["sessions/getSession"];
    },
    actualSessions() {
      const filteredSessions = this.allSessions.filter((session) => {
        return session;
      });

      // Sort the sessions
      // const sortedSessions = this.sortSessions(filteredSessions);

      return filteredSessions;
    },
  },
  methods: {
    closeSessionsCSV() {
      this.openCSVInput = false;
    },
    changePositiveGrossWin() {
      if (this.positiveGrossWin === null) {
        this.positiveGrossWin = true;
        return;
      }
      if (this.positiveGrossWin === true) {
        this.positiveGrossWin = false;
        return;
      }
      if (this.positiveGrossWin === false) {
        this.positiveGrossWin = null;
        return;
      }
    },
    clickCallback(pageNum) {
      console.log(pageNum);
    },
    updateSessionsList() {
      const index = this.allSessions.findIndex(
        (session) => session.id === this.storeSession.id
      );
      this.allSessions.splice(index, 1, this.storeSession);
      // console.log('session list updated')
    },
    // handleSearch() {
    //   clearTimeout(this.timer);
    //   this.timer = setTimeout(() => {
    //     this.getAllSessions()
    //   }, 400); // Set the debounce time to 300 milliseconds (3 seconds)
    // },
    closeSearch() {
      this.searching = false;
      this.$store.dispatch("sessions/setSessionAction", {});
    },
    goToPage(page) {
      if (page !== "...") {
        this.currentPage = page;
        // this.loading = true
        if (this.debounceTimerPagination) {
          clearTimeout(this.debounceTimerPagination);
        }

        this.debounceTimerPagination = setTimeout(() => {
          this.getAllSessions();
        }, 300);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        // this.loading = true
      }
      if (this.debounceTimerPagination) {
        clearTimeout(this.debounceTimerPagination);
      }

      this.debounceTimerPagination = setTimeout(() => {
        this.getAllSessions();
      }, 400);
    },
    nextPage() {
      if (this.currentPage < this.pagesCount) {
        this.currentPage++;
        // this.loading = true
      }
      if (this.debounceTimerPagination) {
        clearTimeout(this.debounceTimerPagination);
      }

      this.debounceTimerPagination = setTimeout(() => {
        this.getAllSessions();
      }, 400);
    },
    changeSessionsType(type = null) {
      if (type !== null) {
        this.sessionsType = type;
        return;
      }
      if (this.sessionsType === "fun") {
        this.sessionsType = "real";
        return;
      }
      if (this.sessionsType === "real") {
        this.sessionsType = "all";
        return;
      }
      if (this.sessionsType === "all") {
        this.sessionsType = "fun";
        return;
      }
    },
    closeSessionBox() {
      document.body.style.overflowY = "auto";
      this.$store.dispatch("sessions/setSessionAction", {});
    },
    openSessionBox(session) {
      document.body.style.overflowY = "hidden";
      this.$store.dispatch("sessions/setSessionAction", session);
    },
    loadMoreSessions() {
      setTimeout(() => {
        this.listLimit += 50;
      }, 500); // 1000 milliseconds = 1 second
    },
    handleScroll() {
      const isBottomOfPage = this.isAtBottomOfPage();
      if (isBottomOfPage) {
        this.loadMoreSessions();
      }
    },
    isAtBottomOfPage() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      // Check if the user has scrolled to the bottom of the page
      return scrollTop + clientHeight >= scrollHeight;
    },
    resetJobProgress() {
      this.jobProgress = "";
      if (this.$route.name === "Sessions List") {
        this.getAllSessions();
      }
    },
    getTodayStart() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return this.lastJob?.from || today;
    },
    getTodayEnd() {
      const today = new Date();
      today.setHours(23, 59, 59, 59);
      return this.lastJob?.to || today;
    },
    getGameSessionsCount(game) {
      const sessionsCount = this.allSessions.filter((s) => {
        return s.game.slug === game.slug;
      });
      return sessionsCount.length;
    },
    getPlatformSessionsCount(platform) {
      const sessionsCount = this.allSessions.filter((s) => {
        if (this.isOpen !== null)
          return s.isOpen == this.isOpen && s.platform.id === platform.id;
        else return s.platform.id === platform.id;
      });
      return sessionsCount.length;
    },
    clearUpdatedAt(updatedAt) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      };
      return new Date(updatedAt).toLocaleDateString(
        this.appLanguage.long,
        options
      );
    },
    changeIsOpen() {
      if (this.isOpen === null) {
        this.isOpen = true;
        return;
      }
      if (this.isOpen === true) {
        this.isOpen = false;
        return;
      }
      if (this.isOpen === false) {
        this.isOpen = null;
        return;
      }
    },
    async getAllPlatforms() {
      try {
        if (Object.keys(this.user.attributes).length > 0) {
          const platform = (
            await request.get(
              `/platforms/byName/${this.user.attributes.platform[0]}`
            )
          ).data;
          this.userPlatform = platform;
          const data = (
            await request.get(`/platforms/${platform.id}/operators`)
          ).data;
          data.sort((a, b) => a.name.localeCompare(b.name));
          this.platformOperators = data;
        } else {
          const platforms = (await request.get("/platforms")).data.rows;
          platforms.sort((a, b) => a.name.localeCompare(b.name));
          this.allPlatforms = platforms.filter(
            (platform) => platform.dataServiceURL
          );
        }
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
    async getAllGames() {
      try {
        let games = [];
        if (Object.keys(this.user.attributes).length > 0) {
          const platform = this.userPlatform;
          games = (
            await request.get(`/platforms/${platform.id}/games`)
          ).data.filter((game) => game.isCatalog && !game.isDev);
        } else {
          games = (await request.get("games?with=platforms")).data.rows.filter(
            (game) => !game.isDev
          );
        }
        this.allGames = games;
        this.allGames.sort(
          (a, b) => this.getGameSessionsCount(b) - this.getGameSessionsCount(a)
        );
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
    async getAllSessions(fromSubmit = false, job = false, emails) {
      if (job) {
        this.$store.dispatch("jobs/setActiveJobsAction", {
          ...this.activeJobs,
          sessionsRangeMail: true,
        });
      }
      if (!this.validBetRange) {
        this.$toast.error("Invalid Bet range");
        return;
      }
      if (!this.validGrossWinRange) {
        this.$toast.error("Invalid Gross Win range");
        return;
      }
      if (!job) {
        this.loading = true;
      }
      if (fromSubmit) {
        this.currentPage = 1;
      }
      try {
        // this.allSessions = (await request.get('/bookkeeping/sessions')).data.rows
        // console.log('this.dateRange.start = ' + this.dateRange.start);
        // console.log('this.dateRange.end = ' + this.dateRange.end);
        // const sqlFrom = new Date(this.dateRange.start).toISOString().slice(0, 19);
        const sqlFrom = useDateFormatter(this.dateRange.start);
        // const sqlTo = new Date(this.dateRange.end).toISOString().slice(0, 19);
        const sqlTo = useDateFormatter(this.dateRange.end);
        let sessionTypeId = null;
        if (this.sessionsType == "real") sessionTypeId = 1;
        if (this.sessionsType == "fun") sessionTypeId = 4;

        const platforms = this.userPlatform?.id
          ? this.userPlatform?.id
          : this.selectedPlatforms;
        const filters = {
          from: sqlFrom,
          to: sqlTo,
          sessionTypeId: sessionTypeId,
          platforms: platforms,
          games: this.selectedGames,
        };
        // console.log(filters)
        if (
          this.lastFilters !== null &&
          JSON.stringify(this.lastFilters) !== JSON.stringify(filters)
        ) {
          this.currentPage = 1;
          this.lastFilters = filters;
        } else {
          this.lastFilters = filters;
        }
        // let url = `/bookkeeping/sessions/range?from=${sqlFrom}&to=${sqlTo}&sessionTypeId=${sessionTypeId}&gameIds=${this.selectedGames}&isOpen=${this.isOpen}&page=${this.currentPage}&limit=${this.listLimit}&order[${this.tableSort.order}]=${this.tableSort.direction}`
        let url = `/bookkeeping/sessions/range?`;
        if (job) url = `/range-mail?`;
        url += `from=${sqlFrom}`;
        url += `&to=${sqlTo}`;
        url += `&emails=${emails}`;
        url += `&sessionTypeId=${sessionTypeId}`;
        url += `&gameIds=${this.selectedGames}`;
        url += `&isOpen=${this.isOpen}`;
        url += `&page=${this.currentPage}`;
        url += `&limit=${this.listLimit}`;
        url += `&order[${this.tableSort.order}]=${this.tableSort.direction}`;
        url += `&nonZeroRounds=${this.nonZeroRounds}`;
        url += `&socketId=${this.socket.id}`;
        if (this.userId !== "") {
          url += `&userId=${this.userId}`;
        }
        if (this.betRange) {
          url += `&betRange=${this.betRangeValues}`;
        }
        if (this.winRange) {
          url += `&winRange=${this.winRangeValues}`;
        }
        if (this.grossWinRange) {
          url += `&grossWinRange=${this.grossWinRangeValues}`;
        }
        if (Array.isArray(platforms)) {
          for (const platform of platforms) {
            url += `&filter[platformId]=${platform}`;
          }
        } else {
          url += `&filter[platformId]=${platforms}`;
          if (this.selectedOperators.length > 0) {
            for (const operator of this.selectedOperators) {
              url += `&filter[operatorId]=${operator}`;
            }
          }
        }
        const response = await request.get(url);
        if (!job) {
          this.allSessions = response.data.rows;
          this.pagesCount = Math.ceil(response.data.count / this.listLimit);
          this.sessionsFound = response.data.count;
          // this.getAllPlatforms();
          this.submited = true;
        } else {
          this.$toast.open({
            message: "Sending sessions list to emails",
            type: "success",
            duration: 2000,
            position: "bottom",
          });
          this.openCSVInput = false;
        }
      } catch (e) {
        console.log(e);
        // TODO review this DRAMATIC error handling approach
        this.serverError = true;
      }
      this.visibleCurrentPage = this.currentPage;
      this.loading = false;
    },

    async fetchAllSessions() {
      this.$store.dispatch("jobs/setFetchingAction", true);
      try {
        // this.allSessions = (await request.get('/bookkeeping/sessions')).data.rows
        const sqlFrom = new Date(this.dateRange.start)
          .toISOString()
          .slice(0, 19);
        const sqlTo = new Date(this.dateRange.end).toISOString().slice(0, 19);
        // console.log('sqlFrom: ' + sqlFrom + ' sqlTo: ' + sqlTo);
        await request.get(
          `/bookkeeping/sessions/fetch?dateStart=${sqlFrom}&dateEnd=${sqlTo}&socketId=${this.socket.id}`
        );
        this.$store.dispatch("jobs/setLastJobAction", {
          from: this.dateRange.start,
          to: this.dateRange.end,
        });
      } catch (e) {
        console.log(e);
      }
    },

    async fetchSessions() {
      try {
        const sqlDate = this.dateRangeCleared.toISOString().split("T")[0];
        // console.log(sqlDate);
        await request.get(`/bookkeeping/sessions/fetch?date=${sqlDate}`);
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },

    getPropertyValue(object, propertyString) {
      const properties = propertyString.split(".");
      let value = object;
      for (let i = 0; i < properties.length; i++) {
        value = value[properties[i]];
      }
      return value;
    },
    sortTable(column) {
      if (this.loading) return;
      if (this.tableSort.order === column) {
        this.tableSort.direction =
          this.tableSort.direction === "asc" ? "desc" : "asc";
      } else {
        this.tableSort.order = column;
        this.tableSort.direction = "desc";
      }

      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      // Set a new timeout to make the request after a certain delay
      this.debounceTimer = setTimeout(() => {
        // Your request code goes here
        this.getAllSessions();
      }, 400);
    },
    async updateSessions(sessions) {
      if (sessions) {
        this.loading = true;
        this.allSessions = sessions;
        await this.getAllPlatforms();
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="postcss">
@import "../../../node_modules/@vueform/slider/themes/tailwind.scss";
.slider-tooltip {
  @apply bg-zinc-700 border-zinc-700;
}
.slider-connect {
  @apply bg-orange-600;
}
</style>
<template>
  <div class="w-full bg-zinc-950 min-h-full flex flex-col gap-4">
    <!-- header  -->
    <div class="h-max flex justify-between items-center rounded-t-md bg-zinc-950 w-full px-4 md:px-7 pt-4 pb-4">
      <h2 class="fugaz uppercase text-3xl xl:text-4xl dark:text-white">
        {{ $t('platforms.title') }}
      </h2>
      <div v-if="ready" class="w-full flex justify-end gap-2.5">
        <button v-if="!syncing" @click="syncPlatforms" 
          class="pb-2 pt-1 text-base md:text-lg rounded-full fugaz uppercase text-white xl:hover:text-orange-600"
        >
          {{ $t('platforms.syncPlatforms') }}
        </button>
        <div v-else class="pb-2 pt-1 text-base md:text-lg rounded-full fugaz uppercase text-white">
          ...syncing
        </div>
        <router-link :to="{ name: 'New platform'}" 
          class="pb-2 pt-1 text-base md:text-lg rounded-full fugaz uppercase text-white xl:hover:text-orange-600"
        >
          {{ $t('platforms.addPlatform') }}
        </router-link>
      </div>
    </div>
    <div class="flex flex-col h-full w-full">
      <div
        v-if="ready"
        class="bg-zinc-950 w-full rounded-lg shadow-md flex flex-col px-4 md:px-7 pb-[50px] xl:pb-0" 
      >
        <!-- search  -->
        <div class="w-full h-max flex justify-between items-center pb-3 shadow-sm z-30">
          <input type="text" v-model="search" :placeholder="$t('platforms.searchPlatform')" class="p-2 rounded-md 1/2 xl:w-1/3 outline-none bg-zinc-900 text-zinc-500 shadow-sm">
        </div>
        <div class="pb-4 align-middle inline-block min-w-full z-20">
          <div class="shadow relative rounded-tl-md rounded-bl-md">
            <table class="table table-auto border-separate border-spacing-y-[4px] w-full">
              <thead class="bg-zinc-800 text-white sticky top-0 fugaz uppercase">
                <tr>
                  <th @click="sortTable('name')" scope="col" class="py-3 text-left text-md font-medium uppercase cursor-pointer w-56">
                    <!-- ghost icon  -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                    {{ $t('platforms.name') }}
                    <font-awesome-icon v-if="tableSort == 'name'" :icon="['fas', 'sort-down']" class="pb-1 px-2"/>
                    <font-awesome-icon v-if="tableSort == 'nameInverse'" :icon="['fas', 'sort-up']" class="pt-1 px-2"/>
                    <!-- ghost icon -->
                    <font-awesome-icon v-if="tableSort !== 'name' && tableSort == 'nameInverse'" :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                  </th>
                  <th scope="col" class="text-left">
                  </th>
                  <!-- <th scope="col" class="hidden lg:table-cell px-6 py-3 text-center text-md font-medium uppercase tracking-wider">
                    Sessions
                  </th> -->
                  <th @click="sortTable('operators.length')" scope="col" class="hidden md:table-cell justify-center py-3 text-center text-md font-medium uppercase cursor-pointer w-44">
                    <!-- ghost icon  -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                    {{ $t('platforms.operators') }}
                    <font-awesome-icon v-if="tableSort == 'operators.length'" :icon="['fas', 'sort-down']" class="pb-1 px-2"/>
                    <font-awesome-icon v-if="tableSort == 'operators.lengthInverse'" :icon="['fas', 'sort-up']" class="pt-1 px-2"/>
                    <!-- ghost icon -->
                    <font-awesome-icon v-if="tableSort !== 'operators.length' && tableSort !== 'operators.lengthInverse'" 
                      :icon="['fas', 'sort-down']" 
                      class="pb-1 px-2 invisible"
                    />
                  </th>
                  <th @click="sortTable('users.length')" scope="col" class="hidden md:table-cell py-3 text-center text-md font-medium uppercase cursor-pointer w-44">
                    <!-- ghost icon  -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                    {{ $t('platforms.users') }}
                    <font-awesome-icon v-if="tableSort == 'users.length'" :icon="['fas', 'sort-down']" class="pb-1 px-2"/>
                    <font-awesome-icon v-if="tableSort == 'users.lengthInverse'" :icon="['fas', 'sort-up']" class="pt-1 px-2"/>
                    <!-- ghost icon -->
                    <font-awesome-icon v-if="tableSort !== 'users.length' && tableSort !== 'users.lengthInverse'" 
                      :icon="['fas', 'sort-down']" 
                      class="pb-1 px-2 invisible"
                    />
                  </th>
                  <th @click="sortTable('games.length')" scope="col" class="py-3 text-center text-md font-medium uppercase cursor-pointer w-44">
                    <!-- ghost icon  -->
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                    {{ $t('platforms.games') }}
                    <font-awesome-icon v-if="tableSort == 'games.length'" :icon="['fas', 'sort-down']" class="pb-1 px-2"/>
                    <font-awesome-icon v-if="tableSort == 'games.lengthInverse'" :icon="['fas', 'sort-up']" class="pt-1 px-2"/>
                    <!-- ghost icon -->
                    <font-awesome-icon v-if="tableSort !== 'games.length' && tableSort !== 'games.lengthInverse'" 
                      :icon="['fas', 'sort-down']" 
                      class="pb-1 px-2 invisible"
                    />
                  </th>
                </tr>
              </thead>
              <tbody v-if="platformsFromDB && platformsFromDB.length" class="fugaz uppercase">
                <tr v-for="(platform, index) in sortedPlatforms" :key="platform.id" 
                  class="hover:bg-opacity-90 cursor-pointer text-white hover:brightness-125"
                  @click="goToPlatform(platform)"
                  :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'">
                  <td class="pl-7 whitespace-nowrap items-center">
                      {{ platform.name }}
                      <font-awesome-icon 
                        v-if="platform.version == 'GAI-INTERNATIONAL'"
                        icon="fas fa-bolt"
                        class="h-5" style="color: mediumturquoise"
                        label="international"
                        title="international"
                      />
                  </td>
                  <td class="hidden md:table-cell whitespace-nowrap text-base md:text-lg">
                    <span class="text-xs text-orange-600">{{ platform.playServiceURL }}</span>
                  </td>
                  <!-- <td class="hidden lg:table-cell px-6 whitespace-nowrap text-base md:text-lg text-zinc-500 dark:text-zinc-200 text-center">
                    0
                  </td> -->
                  <td class="hidden md:table-cell whitespace-nowrap text-base md:text-lg text-center w-44">
                    {{ platform.operators.length }}
                  </td>
                  <td class="hidden md:table-cell whitespace-nowrap text-base md:text-lg text-center w-44">
                    {{ platform.users.length }}
                  </td>
                  <td class="py-4 whitespace-nowrap text-base md:text-lg text-center w-44">
                    {{ platform.games.length }}
                  </td>
                </tr>
              </tbody>
              <div v-else class="text-white py-2">
                {{ $t('platforms.nothingToShow') }}
              </div>
            </table>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-full h-[90vh] flex justify-center items-center gap-3 bg-zinc-950 text-white"
      >
        <img v-if="!serverError" src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
        <!-- <img
          src="../../assets/loading2.png"
          alt="loading icon"
          v-if="!serverError"
          class="h-12 animate-spin mb-2"
        /> -->
        <div v-else>
          {{ $t('serverError') }}
          <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
          {{ $t('tryAgain') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../services/axios'
export default {
  name: 'PlatformsView',
  data() {
    return {
      platformsFromDB: [],
      tableSort: 'games.lengthInverse',
      search: '',
      currentPage: 1,
      perPage: 50,
      ready: false,
      serverError: false,
      syncing: false,
      mountTimer: null,
    }
  },
  async created() {
    try {
      const response = await request.get('/platforms?with=users&with=operators&with=games')
      this.platformsFromDB = response.data.rows
      this.ready = true
    } catch (error) {
      console.log(error);
      this.serverError = true;
    }
  },
  computed: {
    platforms() {
      let searchedPlatforms = this.platformsFromDB.filter(plat => {
        if (plat.name.toLowerCase().includes(this.search.toLowerCase())) return plat
      })
      return searchedPlatforms.length ? searchedPlatforms : []
    },
    paginatedPlatforms() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.platforms.slice(start, end)
    },
    sortedPlatforms(){
      return this.sortPlatforms(this.platforms)
    }
  },
  methods: {
    goToPlatform(platform) {
      this.$router.push({ name: 'Platform', params: { id: platform.id } })
    },
    sortTable(column) {
      if (this.tableSort === column) {
        this.tableSort += 'Inverse';
      } else {
        this.tableSort = column;
      }
    },
    getPropertyValue(object, propertyString) {
      const properties = propertyString.split('.');
      let value = object;
      for (let i = 0; i < properties.length; i++) {
        value = value[properties[i]];
      }
      return value;
    },
    sortPlatforms(platforms) {
      if (this.tableSort) {
        let sort = this.tableSort;
        let reverse = false;
        if (sort.includes('Inverse')) {
          sort = sort.replace('Inverse', '');
          reverse = true;
        }
        platforms.sort((a, b) => {
          const valueA = this.getPropertyValue(a, sort);
          const valueB = this.getPropertyValue(b, sort);
          let comparison = 0;
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            const upperA = valueA.toUpperCase();
            const upperB = valueB.toUpperCase();
            if (upperA > upperB) {
              comparison = 1;
            } else if (upperA < upperB) {
              comparison = -1;
            }
          } else if (valueA > valueB) {
            comparison = 1;
          } else if (valueA < valueB) {
            comparison = -1;
          }
          return reverse ? comparison * -1 : comparison;
        });
      }
      return platforms;
    },
    async getPlatforms() {
      try {
        const response = await request.get('/platforms?with=users&with=operators&with=games')
        this.platformsFromDB = response.data.rows
      } catch (error) {
        console.log(error);
        this.serverError = true;
      }
    },
    async syncPlatforms() {
      this.syncing = true;
      try {
        await request.get('/platforms-synch')
        await this.getPlatforms()
        this.$toast.success('Platforms synchronized successfully!', {
          position: 'top-right',
          duration: 2000,
          pauseOnHover: true,
        })
      } catch (error) {
        console.log(error);
        this.$toast.error('Failed to synchronize platforms', {
          position: 'top-right',
          duration: 2000,
          pauseOnHover: true,
        })
      }
      this.syncing = false;
    }
  }
}
</script>

<style>

</style>
import request from "@/services/axios";

const state = {
  userId: null,
  username: null,
  email: null,
  roles: null,
  attributes: null,
  localRoles: null,
  token: null,
  notifications: [],
  gettingNotifications: false,
  lastGetNotifications: null,
};

const getters = {
  user: (state) => {
    return {
      userId: state.userId,
      username: state.username,
      email: state.email,
      roles: state.roles,
      attributes: state.attributes,
      localRoles: state.localRoles,
      notifications: state.notifications
    };
  },
  userId: (state) => state.userId,
  username: (state) => state.username,
  email: (state) => state.email,
  attributes: (state) => state.attributes,
  roles: (state) => state.roles,
  token: (state) => state.token,
  notifications: (state) => state.notifications,
  gettingNotifications: (state) => state.gettingNotifications,
  lastGetNotifications: (state) => state.lastGetNotifications,
};

const mutations = {
  setUser(state, user) {
    state.userId = user.userId;
    state.username = user.username;
    state.email = user.email;
    state.attributes = user.attributes;
    state.roles = user.roles;
    state.token = user.token;
    state.localRoles = {
      isLudusAdmin: user.roles.includes('ludus_admin'),
      isLudusManager: user.roles.includes('ludus_manager'),
      isPlatformAdmin: user.roles.includes('platform_admin'),
      isPlatformManager: user.roles.includes('platform_manager'),
      isPlatformGuest: user.roles.includes('platform_guest')
    };
  },
  setToken(state, token) {
    state.token = token;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  updateOneNotification(state, payload) {
    const notif = payload.notification
    const index = payload.index
    state.notifications[index] = payload.notification
  },
  setGettingNotifications(state, payload) {
    state.gettingNotifications = payload
  },
  setLastGetNotifications(state, payload) {
    state.lastGetNotifications = payload
  },
};

const actions = {
  setUserAction({ commit }, user) {
    // console.log('updating local store user', user);
    commit("setUser", user);
  },

  async setNotificationsAction({ commit }) {
    commit('setGettingNotifications', true);
    try {
      const notifications = (await request.get('notifications')).data.reverse()
      commit('setNotifications', notifications);
      commit('setLastGetNotifications', new Date());
    } catch (e) {
      console.log(e)
    }
    commit('setGettingNotifications', false);
  },

  updateOneNotificationAction({ commit }, payload) {
    commit('updateOneNotification', payload)
  },

  updateToken({ commit }, token) {
    console.log('updateToken');
    console.log(token);
    commit('setToken', token);
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

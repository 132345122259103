const state = {
  sidebarFixed: true,
}

const getters = {
  getSidebarFixed: state => state.sidebarFixed,
}

const actions = {
  setSidebarFixedAction({commit}, payload) {
    commit('setSidebarFixed', payload)
  },
}

const mutations = {
  setSidebarFixed(state, payload) {
    state.sidebarFixed = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
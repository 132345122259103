<template>
<div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1.5 px-3 md:px-8">
  <router-link
    v-for="(game, index) in games"
    :key="game.id"
    class="group relative flex flex-col items-center justify-center cursor-pointer shadow-md w-auto h-auto rounded-sm"

    :to="{ name: 'Game', params: { id: game.id } }"
    @click="setGamesOrder"
    style="aspect-ratio: 11 / 13;"
  >
    <img
      :src="mainImage(game)"
      alt="no image available"
      class="z-10 w-full h-full object-cover object-center rounded-sm"
      style="aspect-ratio: 11 / 13;"
    />
    <span class="absolute bottom-0 left-0 hidden lg:flex gap-1 justify-center items-end w-full h-1/2 bg-gradient-to-t from-black via-transparent bg-opacity-25 z-[30] pointer-events-none opacity-0 group-hover:opacity-100">
      <div class="flex flex-col items-center">
        <h4 class="fugaz uppercase truncate -mb-1.5">{{ game.name }}</h4>
        <h6 v-if="game.sub_name" class="fugaz uppercase truncate text-sm">{{ game.sub_name }}</h6>
      </div>
    </span>
    <span class="absolute top-0 left-0 hidden lg:flex gap-1 justify-start items-start w-full h-1/2 z-[30] pointer-events-none opacity-0 group-hover:opacity-100">
      <h2 class="text-sm fugaz uppercase whitespace-nowrap bg-zinc-950 bg-opacity-40 pl-1.5 pr-2 rounded-br-md">
        {{ getReleaseDate(game.release_date) }}
      </h2>
    </span>
  </router-link>
</div>

</template>
<script>
  export default {
    props: {
      games: Array,
      setGamesOrder: Function,
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
    },
    methods: {
      mainImage(game) {
        const mainImage = game.game_media.find(
          (image) => image.isMainImage === true
        );
        return mainImage
          ? mainImage.url
          : require(`@/assets/no-image-available.jpg`);
      },
      getReleaseDate(releaseDate) {
        if (releaseDate) {
          const date = new Date(releaseDate);
          const options = { year: "numeric", month: "short", day: "numeric" };
          return date.toLocaleDateString(this.appLanguage.long, options);
        } else return "N/A";
      },
    },
  }
</script>
<style scoped>

</style>
<template>
  <div
    v-if="ready"
    class="w-full min-h-full h-max flex flex-col items-center bg-zinc-200 dark:bg-zinc-900 relative edit"
  >
    <button
      class="fixed top-0 text-white fugaz uppercase px-3 pt-1.5 pb-1 rounded-br-md xl:hover:bg-zinc-700 xl:hover:border-r xl:hover:border-b border-zinc-600 z-[99] xl:hover:shadow-md"
      :class="sidebarFixed ? 'left-72' : 'left-20'"
      @click="$router.push({ name: 'Game', params: { id } })"
    >
      <font-awesome-icon
        :icon="['fas', 'backward']"
        class="h-4 pr-1"
      />
      Back to game
    </button>
    <div class="fixed bottom-0 z-[99]" :class="sidebarFixed ? 'left-72' : 'left-22'">
      <button
        @click="submitData()"
        class="bg-red-500 px-10 py-4 rounded-sm xl:hover:bg-red-600 xl:hover:opacity-100 fugaz uppercase text-white"
      >
        Save
      </button>
    </div>
    <div
      class="my-10 flex flex-col items-center rounded-md shadow-md bg-white dark:bg-zinc-800 px-4 py-6 relative"
      style="width: 85%; min-height: 80vh"
    >
      <div v-if="!cantBeDeleted" class="absolute top-2 right-1.5 text-white">
        <button v-if="!deleting" @click="deleting = true" class="px-3 bg-red-500 rounded-sm fugaz uppercase">Delete Game</button>
        <div v-if="deleting" class="flex items-center gap-2">
          Are you sure you want to delete this game?
          <button @click="deleting = false" class="px-3 bg-zinc-700 rounded-sm text-white fugaz uppercase">Cancel</button>
          <button @click="deleteGame()" class="px-3 bg-red-500 rounded-sm text-white fugaz uppercase">Confirm</button>
        </div>
      </div>
      <h1 class="text-5xl text-white fugaz uppercase">
        {{ game.name }}
      </h1>
      <div class="flex flex-col items-center px-8 pb-8 pt-5 bg-zinc-700 mt-8 rounded-md relative">
        <div class="flex w-full mb-4">
          <h2 class="fugaz uppercase text-white text-2xl">
            game info
          </h2>
        </div>
        <div class="flex flex-wrap justify-center gap-4 w-full">
          <div class="flex flex-col mb-2 bg-zinc-800 p-2 rounded-md shadow-md">
            <label class="capitalize text-white" for="name"
              >Name
            </label>
            <input
              v-model="newGameFields.name"
              name="name"
              type="text"
              class="w-80 rounded-sm px-1 outline-none border-b"
            />
          </div>
          <div class="flex flex-col mb-2 bg-zinc-800 p-2 rounded-md shadow-md">
            <label class="capitalize text-white" for="name"
              >Sub name
            </label>
            <input
              v-model="newGameFields.sub_name"
              name="name"
              type="text"
              class="w-80 rounded-sm px-1 outline-none border-b"
            />
          </div>
          <div class="flex flex-col mb-2 bg-zinc-800 p-2 rounded-md shadow-md">
            <label class="capitalize text-white" for="name"
              >Slug
            </label>
            <input
              v-model="newGameFields.slug"
              name="name"
              type="text"
              class="w-80 rounded-sm px-1 outline-none border-b"
            />
          </div>
        </div>
        <div class="flex flex-wrap justify-center gap-3 mt-6 w-full px-6">
          <div class="flex flex-col items-center bg-zinc-800 px-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="rtp"
              >RTP</label
            >
            <input
              v-model="newGameFields.rtp"
              name="rtp"
              type="number"
              max="100"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="volatility"
              >Volatility</label
            >
            <select v-model="newGameFields.volatility" class="py-2 rounded-md border pl-1">
              <option disabled value="0">0</option>
              <option v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="i" :value="i">
                {{ i }}
              </option>
            </select>
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="hit_frequency"
              >Hit frequency</label
            >
            <input
              v-model="newGameFields.hit_frequency"
              name="hit_frequency"
              type="number"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="max_multiplier_win"
              >Max win</label
            >
            <input
              v-model="newGameFields.max_multiplier_win"
              name="max_multiplier_win"
              type="number"
              step="0.1"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="type"
              >Type</label
            >
            <select v-model="newGameFields.type" class="py-2 rounded-md border pl-1 outline-none">
              <option value="SLOT MACHINE">SLOT MACHINE</option>
              <option v-for="i in ['ROULETTE', 'UNKNOW']" :key="i" :value="i">
                {{ i }}
              </option>
            </select>
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="format"
              >Format</label
            >
            <input
              v-model="newGameFields.format"
              name="format"
              max="100"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 pb-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="max_bet"
              >Max bet</label
            >
            <input
              v-model="newGameFields.max_bet"
              name="max_bet"
              type="number"
              min="0"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 pb-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="min_bet"
              >Min bet</label
            >
            <input
              v-model="newGameFields.min_bet"
              name="min_bet"
              type="number"
              min="0"
              step="0.1"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="payMethod"
              >Pay method</label
            >
            <select v-model="payMethod" class="py-2 rounded-md border pl-1 outline-none">
              <option value="Paylines">Paylines</option>
              <option v-for="i in [ 'Scatter', 'Ways', 'Tumble']" :key="i" :value="i">
                {{ i }}
              </option>
            </select>
          </div>
          <div v-if="payMethod !== 'Scatter' && payMethod !== 'Tumble'" 
            class="flex flex-col items-center bg-zinc-800 px-2 pb-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="lines"
              >Lines</label
            >
            <input
              v-model="newGameFields.lines"
              name="lines"
              type="number"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 pb-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="reels"
              >Reels</label
            >
            <input
              v-model="newGameFields.reels"
              name="reels"
              type="number"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div class="flex flex-col items-center bg-zinc-800 px-2 pb-2 rounded-md shadow-md">
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="gameRows"
            >
              Rows
            </label>
            <input
              v-model="newGameFields.gameRows"
              name="gameRows"
              type="number"
              class="w-20 py-1.5 rounded-md pl-2 outline-none border"
            />
          </div>
          <div
            class="flex flex-col items-center justify-between bg-zinc-800 p-2 rounded-md shadow-md"
          >
            <label
              class="capitalize font-semibold text-white my-1.5"
              for="volatility"
              >Supported platforms
            </label>
            <div class="flex items-center justify-between gap-x-2">
              <label class="flex items-center checkbox">
                <div
                  class="flex items-center truncate text-black dark:text-white"
                  :class="
                    newGameFields.supportedPlatforms?.mobile
                      ? 'font-semibold'
                      : 'font-normal'
                  "
                >
                  Mobile
                </div>
                <input
                  type="checkbox"
                  @click="
                    newGameFields.supportedPlatforms.mobile = !newGameFields
                      .supportedPlatforms.mobile
                  "
                  :checked="newGameFields.supportedPlatforms?.mobile"
                />
                <span class="checkmark"></span>
              </label>

              <label class="flex items-center checkbox">
                <div
                  class="flex items-center truncate text-black dark:text-white"
                  :class="
                    newGameFields.supportedPlatforms?.desktop
                      ? 'font-semibold'
                      : 'font-normal'
                  "
                >
                  Desktop
                </div>
                <input
                  type="checkbox"
                  @click="
                    newGameFields.supportedPlatforms.desktop = !newGameFields
                      .supportedPlatforms.desktop
                  "
                  :checked="newGameFields.supportedPlatforms?.desktop"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <label class="flex flex-col gap-1.5 py-2 items-center cursor-pointer relative bg-zinc-800 px-2 rounded-md h-max">
            <div class="font-medium text-white">Buy bonus</div>
            <input type="checkbox" class="sr-only" v-model="newGameFields.isBuyBonus">
            <div :class="['block w-14 h-8 rounded-full relative transition-all duration-300', { 'bg-green-500': newGameFields.isBuyBonus, 'bg-red-500': !newGameFields.isBuyBonus }]">
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                :class="{ 'translate-x-6': newGameFields.isBuyBonus }"
              ></div>
            </div>
          </label>
          <label class="flex flex-col gap-1.5 py-2 items-center cursor-pointer relative bg-zinc-800 px-2 rounded-md h-max">
            <div class="font-medium text-white">Is in Dev</div>
            <input type="checkbox" class="sr-only" v-model="newGameFields.isDev">
            <div :class="['block w-14 h-8 rounded-full relative transition-all duration-300', { 'bg-green-500': newGameFields.isDev, 'bg-red-500': !newGameFields.isDev }]">
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                :class="{ 'translate-x-6': newGameFields.isDev }"
              ></div>
            </div>
          </label>
          <div
            class="flex flex-col items-center gap-1.5 bg-zinc-800 px-2 pt-2 pb-2.5 rounded-md shadow-md w-32 h-max"
          >
            <div class="flex justify-between">
              <span
                class="font-medium text-white"
                >Release date
              </span>
            </div>
            <v-date-picker v-model="newGameFields.release_date" is-dark color="pink" :locale="appLanguage.long">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" class="w-[95%] rounded-md py-1 text-center" />
              </template>
            </v-date-picker>
          </div>
        </div>
        <!-- <h4 class="capitalize font-semibold text-white mb-1.5 mt-3">
          Description English
        </h4>
        <ckeditor :editor="editor" v-model="newGameFields.description_en" :config="editorConfig"></ckeditor>
        <h4 class="capitalize font-semibold text-white mb-1.5 mt-3">
          Description Italian
        </h4>
        <ckeditor :editor="editor" v-model="newGameFields.description_it" :config="editorConfig"></ckeditor> -->
        <div class="w-full flex flex-col items-center gap-1.5 mb-2 mt-3">
          <span class="text-white font-semibold text-lg">Description</span>
          <div class="w-full flex items-center gap-1.5 justify-center">
            <button 
              v-for="language of Object.keys(descriptions)" 
              @click="selectedLanguage = language" 
              :disabled="language !== 'english' && language !== 'italian'"
              class="px-2.5 pb-0.5 pt-1 rounded-md bg-zinc-800 fugaz uppercase xl:hover:text-orange-600 disabled:pointer-events-none transition-all duration-150 disabled:opacity-50"
              :class="selectedLanguage === language ? 'text-orange-600' : 'text-white'"
            >
              {{ language }}
            </button>
          </div>
        </div>
        <RichTextInput :key="selectedLanguage" :description="`${selectedLanguage} description`" :value="descriptions[selectedLanguage]" @input="updateParentValue"/>
        <h2 class="font-semibold text-lg text-white mt-4 mb-2 text-center">
          Optional info
        </h2>
        <div class="flex flex-wrap gap-3 justify-center" style="max-width: 1250px">
          <div
            v-for="(field, index) in dataFields"
            :key="index"
            class="flex gap-x-2 items-end bg-zinc-800 p-2 rounded-md shadow-md"
          >
            <div class="flex flex-col">
              <label
                class="capitalize text-white mb-1.5 flex justify-between"
                :for="field.key"
                >{{ field.key }}:
                <button
                  class="bg-red-500 text-white px-2 rounded-sm"
                  @click="removeField(index)"
                >
                  Remove
                </button>
              </label>
              <input
                v-model="field.value"
                :name="field.key"
                type="text"
                class="w-96 rounded-sm px-2 outline-none border-b"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center mt-4">
          <form v-if="addingNewField" @submit="addField($event)">
            <input
              v-model="newField"
              type="text"
              placeholder="Field name..."
              class="w-64 px-2 rounded-sm mr-2 outline-none border-b"
            />
            <button @click="addField($event)" class="px-2 rounded-sm fugaz uppercase text-white xl:hover:text-orange-600">
              Add Field
            </button>
          </form>
          <button
            v-else
            @click="addingNewField = true"
            class="px-3 rounded-sm text-xl fugaz uppercase xl:hover:text-orange-600 text-white"
          >
            Add new field
          </button>
        </div>
      </div>
      <div class="flex flex-col items-center bg-zinc-700 rounded-md px-5 pb-5 pt-2 my-6 min-w-full">
        <h2 class="text-4xl mt-3 fugaz uppercase text-white mb-2">Media</h2>
        <div class="flex flex-wrap gap-x-20 items-center justify-center w-full mt-4 border-t border-zinc-600 rounded-sm p-3">
          <div class="flex flex-col items-center">
            <h3 class="text-lg font-semibold text-white">
              Main image: <span class="font-normal text-sm">(JPG or PNG)</span>
            </h3>
            <div class="my-1 cursor-pointer xl:hover:brightness-75">
              <input
                type="file"
                ref="mainRef"
                accept="image/png, image/jpeg, image/webp"
                style="display: none"
                @change="uploadMedia($event, 'mainImage')"
              />
              <img
                class="w-auto"
                style="height: 350px"
                :src="newGameFields.mainImage.url"
                @click="openFileInput('mainRef')"
              />
              <input type="hidden" v-model="newGameFields.mainImage.url" />
            </div>
            <div class="text-sm dark:text-zinc-400 mt-1">
              Recommended size: 1242 x 1464 px
              <span
                v-if="newGameFields.mainImage.width"
                :class="
                  newGameFields.mainImage.width === 1242 &&
                  newGameFields.mainImage.height === 1464
                    ? 'text-green-500'
                    : 'text-red-500'
                "
              >
                (actual: {{ newGameFields.mainImage.width }} x
                {{ newGameFields.mainImage.height }} px)
              </span>
            </div>
          </div>
          <div>
            <h3 class="text-lg fugaz uppercase text-black dark:text-zinc-300 text-center cursor-default">Example</h3>
            <div class="relative w-max my-1 opacity-80" style="height: 250px; max-width: 300px;">
              <img class="w-full h-full" src="../../assets/main-image-demo.png" alt="main game image" />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap gap-x-20 items-center justify-center w-full mt-4 border-t border-zinc-600 rounded-sm p-3">
          <div class="flex flex-col items-center">
            <h3 class="text-lg font-semibold text-black dark:text-zinc-300">
              Banner image: <span class="font-normal text-sm">(JPG or PNG)</span>
            </h3>
            <div class="my-1 cursor-pointer xl:hover:brightness-75 relative w-96 h-36">
              <input
                type="file"
                ref="bannerRef"
                accept="image/png, image/jpeg, image/webp"
                style="display: none"
                @change="uploadMedia($event, 'bannerImage')"
              />
              <img
                :src="newGameFields.bannerImage.url"
                @click="openFileInput('bannerRef')"
                class="w-full h-full object-cover"
              />
              <input type="hidden" v-model="newGameFields.bannerImage.url" />
            </div>
            <div class="text-sm dark:text-zinc-400 mt-1">
              Recommended size: 1833 x 686 px
              <span
                v-if="newGameFields.bannerImage.width"
                :class="
                  newGameFields.bannerImage.width === 1833 &&
                  newGameFields.bannerImage.height === 686
                    ? 'text-green-500'
                    : 'text-red-500'
                "
              >
                (actual: {{ newGameFields.bannerImage.width }} x
                {{ newGameFields.bannerImage.height }} px)
              </span>
            </div>
          </div>
          <div>
            <h3 class="text-lg fugaz uppercase text-black dark:text-zinc-300 text-center cursor-default">Example</h3>
            <div class="relative my-1 w-[304px] max-h-[144px] opacity-80">
              <img src="../../assets/banner-demo.png" alt="main game image" class="w-full h-full object-cover" />
            </div>
          </div>
        </div>
        <h3 class="text-lg my-1 font-semibold text-black dark:text-zinc-300">
          Banner page preview:
        </h3>
        <div class="relative">
          <div class="absolute left-24" style="max-width: 400px; bottom: 25%">
            <h1 class="text-white text-2xl italic font-bold uppercase">GAI HUB</h1>
            <h1 class="text-white text-2xl italic font-bold uppercase">ONLINE GAMES</h1>
            <div
              class="w-[101%] h-8 mt-1 text-md bg-orange-600 text-white italic font-bold uppercase flex items-center justify-center"
            >
              Play demo
            </div>
          </div>
          <img :src="newGameFields.bannerImage.url" class="w-full h-full object-cover" />
        </div>
        <div class="flex flex-wrap gap-x-20 justify-center w-full mt-4 border-t border-zinc-600 rounded-sm p-3">
          <div class="flex flex-col items-center">
            <h3 class="text-lg mt-2 font-semibold text-black dark:text-zinc-300">
              Mini banner: <span class="font-normal text-sm">(JPG or PNG)</span>
            </h3>
            <div class="my-1 cursor-pointer xl:hover:brightness-75 relative w-max max-w-full h-20">
              <input
                type="file"
                ref="miniBannerRef"
                accept="image/png, image/jpeg, image/webp"
                style="display: none"
                @change="uploadMedia($event, 'miniBanner')"
              />
              <img
                :src="newGameFields.miniBanner.url"
                @click="openFileInput('miniBannerRef')"
                class="w-full h-full object-cover"
              />
              <input type="hidden" v-model="newGameFields.miniBanner.url" />
            </div>
            <div class="text-sm dark:text-zinc-400 mt-1">
              Recommended size: 1152 x 80 px
              <span
                v-if="newGameFields.miniBanner.width"
                :class="
                  newGameFields.miniBanner.width === 1152 &&
                  newGameFields.miniBanner.height === 80
                    ? 'text-green-500'
                    : 'text-red-500'
                "
              >
                (actual: {{ newGameFields.miniBanner.width }} x
                {{ newGameFields.miniBanner.height }} px)
              </span>
            </div>
          </div>
          <div class="flex flex-col items-center mt-1">
            <h3 class="text-lg fugaz uppercase text-black dark:text-zinc-300 text-center cursor-default">Example</h3>
            <div class="relative w-max max-w-[70%] max-h-20 my-1 opacity-80">
              <img src="../../assets/mini-banner-demo.png" alt="main game image" class="w-full h-full" />
            </div>
          </div>
        </div>

        <h3 class="text-lg my-2 font-semibold text-black dark:text-zinc-300">
          Gallery images:
        </h3>
        <div class="flex flex-wrap justify-center gap-4">
          <div
            v-for="(img, index) in newGameFields.galleryImages"
            :key="`img${index}`"
            class="h-36 w-36 relative"
          >
            <button
              class="absolute -top-2 -right-2 text-red-500 cursor-pointer xl:hover:scale-105"
              @click="removeGalleryImage(img)"
            >
              <font-awesome-icon :icon="['fas', 'circle-xmark']" class="h-5" />
            </button>
            <img class="h-full w-full object-cover" :src="img.url" alt="image to upload" />
          </div>
          <label
            for="image"
            class="px-3 h-36 w-36 flex justify-center items-center bg-zinc-600 xl:hover:brightness-90 cursor-pointer"
          >
            <font-awesome-icon
              class="text-orange-600 h-10 group-hover:scale-110 transition-all delay-50"
              icon="fa-solid fa-plus"
            />
            <input
              class="hidden"
              type="file"
              id="image"
              ref="galleryImage"
              @click="$event.target.value = null"
              multiple
              @change="handleGalleryImageUpload()"
              accept="image/png, image/jpeg, image/webp"
            />
          </label>
        </div>
        <h3 class="text-lg my-2 font-semibold text-black dark:text-zinc-300">
          Gallery videos:
        </h3>
        <div class="flex flex-wrap justify-center items-center gap-4">
          <div
            v-for="(img, index) in newGameFields.galleryVideos"
            :key="`img${index}`"
            class="relative"
          >
            <button
              class="absolute -top-2 -right-2 text-red-500 cursor-pointer xl:hover:scale-105 z-20"
              @click="removeGalleryImage(img)"
            >
              <font-awesome-icon :icon="['fas', 'circle-xmark']" class="h-5" />
            </button>
            <video class="h-auto w-44 z-10" controls :src="img.url"></video>
          </div>
          <label
            for="video"
            class="px-3 h-28 w-44 flex justify-center items-center bg-zinc-600 xl:hover:brightness-90  cursor-pointer"
          >
            <font-awesome-icon
              class="text-orange-600 h-10 group-hover:scale-110 transition-all delay-50"
              icon="fa-solid fa-plus"
            />
            <input
              class="hidden"
              type="file"
              id="video"
              ref="galleryVideo"
              accept=".mp4, .avi, .mov, .wmv, .flv, .webm"
              @click="$event.target.value = null"
              multiple
              @change="handleGalleryVideoUpload()"
            />
          </label>
        </div>

        <div class="flex items-center gap-3 mt-5 w-full">
          <div
            class="bg-zinc-800 shadow-md px-4 py-2 rounded-md w-1/2"
          >
            <div class="flex items-center gap-y-2">
              <h4 class="capitalize font-semibold text-white mr-2">
                Docs URL:
              </h4>
            </div>
            <input type="text" v-model="newGameFields.docs.url" placeholder="Paste URL" class="w-full outline-none px-1 bg-zinc-700 text-white">
          </div>
          <div
            class="bg-zinc-800 shadow-md px-4 py-2 rounded-md w-1/2"
          >
            <div class="flex items-center gap-y-2">
              <h4 class="capitalize font-semibold text-white mr-2">
                Promo assets URL:
              </h4>
            </div>
            <input type="text" v-model="newGameFields.assets.url" placeholder="Paste URL" class="w-full outline-none px-1 bg-zinc-700 text-white">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full h-full flex justify-center items-center bg-zinc-200 dark:bg-zinc-900 text-zinc-600 dark:text-zinc-400"
  >
    <img src="../../assets/loading2.png" alt="loading icon"
      v-if="!serverError"
      class="h-10 animate-spin"
    />
    <div v-else>
      Error trying to connect to the server.
      <button @click="$router.go()" class="text-blue-600">Reload</button> page to try
      again.
    </div>
  </div>
</template>

<script>
import request from "../../services/axios";
import RichTextInput from "../../components/games/RichTextInput.vue"

export default {
  name: "EditGame",
  props: {
    id: String,
  },
  components: {
    RichTextInput,
  },
  data() {
    return {
      ready: false,
      serverError: false,
      game: {},
      newGameFields: {},
      mainImageToUpload: {},
      bannerImageToUpload: {},
      miniBannerToUpload: {},
      galleryImagesToUpload: [],
      docsFileToUpload: {},
      assetsFileToUpload: {},
      mediaToDelete: [],
      dataFields: [],
      originalGamePlatforms: [],
      gamePlatforms: [],
      allPlatforms: [],
      platformsToAdd: [],
      platformsToDelete: [],
      addingNewField: false,
      newField: "",
      docsFile: {},
      assetFile: {},
      updates: {},
      //loaders
      loadingPlatforms: true,
      payMethod: "Paylines",
      finalGallery: [],
      changeGalleryOrder: false,
      deleting: false,
      selectedLanguage: "english",
      descriptions: {
        english: "",
        italian: "",
        chinese: "",
        japanese: "",
        korean: "",
        spanish: "",
        french: "",
      },
    };
  },
  computed: {
    appLanguage() {
      return this.$store.getters['getLanguage']
    },
    user() {
      return this.$store.getters['user/user']
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
    cantBeDeleted() {
      return this.game.platforms.some(plat => plat.platform_games.is_active !== null)
    }
  },
  async created() {
    try {
      let id = this.id;
      if (id.includes("&")) {
        const idSplited = id.split("&");
        id = idSplited[0];
      }
      const response = await request.get(`/games/${id}?with=game_media&with=platforms`);
      this.game = response.data;
      this.initializeFields();
      this.ready = true;
      this.serverError = false;
    } catch (error) {
      console.log(error);
      this.serverError = true;
    }
  },
  methods: {
    updateParentValue(value) {
      this.descriptions[this.selectedLanguage] = value;
    },
    addPlatform(platform) {
      platform.platform_games = {
        is_active: null,
      };
      //removing platform from all platforms list
      const allPlatforms = this.allPlatforms.map((platform) => platform.id);
      const index = allPlatforms.indexOf(platform.id);
      this.allPlatforms.splice(index, 1);

      this.gamePlatforms.push(platform);
      if(!this.originalGamePlatforms.map(p => p.id).includes(platform.id)) {
        this.platformsToAdd.push(platform);
      }
      if(this.platformsToDelete.map(p => p.id).includes(platform.id)) {
        const index = this.platformsToDelete.indexOf(platform)
        this.platformsToDelete.splice(index, 1);
      }
    },
    removePlatform(platform) {
      this.allPlatforms.push(platform);
      this.allPlatforms.sort((a, b) => a.name.localeCompare(b.name));
      const gamePlatforms = this.gamePlatforms.map((platform) => platform.id);
      const index = gamePlatforms.indexOf(platform.id);
      this.gamePlatforms.splice(index, 1);
      if(this.originalGamePlatforms.map(p => p.id).includes(platform.id)) {
        this.platformsToDelete.push(platform);
      }
      if(this.platformsToAdd.map(p => p.id).includes(platform.id)) {
        const index = this.platformsToAdd.indexOf(platform)
        this.platformsToAdd.splice(index, 1);
      }
    },
    addField(e) {
      e.preventDefault();
      const newField = {
        key: `${this.newField}`,
        value: "",
      };
      this.dataFields.push(newField);
      this.newField = "";
      this.addingNewField = false;
    },
    removeField(index) {
      this.dataFields.splice(index, 1);
    },
    async initializeFields() {
      this.newGameFields = JSON.parse(JSON.stringify(this.game));
      this.descriptions.english = this.newGameFields.description_en
      this.descriptions.italian = this.newGameFields.description_it
      const mainImage = this.game.game_media.find((image) => image.isMainImage);
      this.newGameFields.mainImage = mainImage
        ? mainImage
        : { url: require("@/assets/no-image-available.jpg") };
      const mainImg = new Image();
      mainImg.onload = () => {
        this.newGameFields.mainImage.width = mainImg.width;
        this.newGameFields.mainImage.height = mainImg.height;
      };
      mainImg.src = this.newGameFields.mainImage.url;
      const bannerImage = this.game.game_media.find((image) => image.isBanner);
      this.newGameFields.bannerImage = bannerImage
        ? bannerImage
        : { url: require("@/assets/no-image-available.jpg") };
      const bannerImg = new Image();
      bannerImg.onload = () => {
        this.newGameFields.bannerImage.width = bannerImg.width;
        this.newGameFields.bannerImage.height = bannerImg.height;
      };
      bannerImg.src = this.newGameFields.bannerImage.url;
      const miniBanner = this.game.game_media.find((image) => image.isMiniBanner);
      this.newGameFields.miniBanner = miniBanner
        ? miniBanner
        : { url: require("@/assets/no-image-available.jpg") };
      const miniImg = new Image();
      miniImg.onload = () => {
        this.newGameFields.miniBanner.width = miniImg.width;
        this.newGameFields.miniBanner.height = miniImg.height;
      };
      miniImg.src = this.newGameFields.miniBanner.url;
      this.newGameFields.galleryImages = this.game.game_media.filter((image) => {
        return (
          !image.isBanner &&
          !image.isMainImage &&
          !image.isMiniBanner &&
          image.mediaType === "gallery" &&
          (image.url.includes("jpg") || image.url.includes("png") || image.url.includes("webp"))
        );
      });
      this.newGameFields.galleryVideos = this.game.game_media.filter((image) => {
        return (
          !image.isBanner &&
          !image.isMainImage &&
          image.mediaType === "gallery" &&
          !image.url.includes("jpg") &&
          !image.url.includes("png") &&
          !image.url.includes("webp")
        );
      });
      const docsFile = this.game.game_media.find((image) => image.mediaType === "docs");
      this.newGameFields.docs = docsFile ? JSON.parse(JSON.stringify(docsFile)) : {};
      const assetsFile = this.game.game_media.find(
        (image) => image.mediaType === "assets"
      );
      this.newGameFields.assets = assetsFile ? JSON.parse(JSON.stringify(assetsFile)) : {};
      this.newGameFields.release_date = this.game.release_date;
      this.dataFields = Object.entries(this.game.propertiesData).map(([key, value]) => {
        return { key: key, value: value };
      });
      this.gamePlatforms = this.game.platforms;
      this.originalGamePlatforms = JSON.parse(JSON.stringify(this.game.platforms));

      const responseP = await request.get("/platforms");
      const platforms = responseP.data?.rows;
      let allPlatforms = platforms.map((platform) => ({
        name: platform.name,
        id: platform.id,
        externalIdentifier: platform.externalIdentifier,
      }));
      let res = allPlatforms.filter((id) => this.gamePlatforms.find((gp) => gp.id != id));

      const gamePlatforms = this.gamePlatforms;
      res = allPlatforms.filter(notAssociatedPlatforms);

      function notAssociatedPlatforms(platform) {
        return !gamePlatforms.find((gp) => gp.id == platform.id);
      }

      allPlatforms = res;
      this.loadingPlatforms = false;
      // const filteredArray = allPlatforms.filter(
      //   (item1) =>
      //     !this.gamePlatforms.some(
      //       (item2) => item1.externalIdentifier == item2.externalIdentifier
      //     )
      // );
      // filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      // this.allPlatforms = filteredArray;
      this.allPlatforms = allPlatforms;
      this.allPlatforms.sort((a, b) => a.name.localeCompare(b.name));
      this.mainImageToUpload = {};
      this.bannerImageToUpload = {};
      this.galleryImagesToUpload = [];
      this.mediaToDelete = [];
      this.payMethod = this.newGameFields.pay_method
    },
    async handleGalleryImageUpload() {
      for (let i = 0; i < this.$refs.galleryImage.files.length; i++) {
        const file = this.$refs.galleryImage.files[i];
        const convertedFile = await this.convertToWebP(file)
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.newGameFields.galleryImages.push({ file, url: reader.result });
        };
        this.galleryImagesToUpload.push({
          file: convertedFile,
          originalFile: file,
          isMainImage: false,
          isBanner: false,
          isMiniBanner: false,
          mediaType: "gallery",
        });
      }
    },
    handleGalleryVideoUpload() {
      for (let i = 0; i < this.$refs.galleryVideo.files.length; i++) {
        const file = this.$refs.galleryVideo.files[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.newGameFields.galleryVideos.push({ file, url: reader.result });
        };
        this.galleryImagesToUpload.push({
          file,
          isMainImage: false,
          isBanner: false,
          isMiniBanner: false,
          mediaType: "gallery",
        });
      }
    },
    removeGalleryImage(image) {
      // remove temporary file
      if (image.file) {
        if (image.url.includes("image/")) {
          const index = this.newGameFields.galleryImages.indexOf(image);
          this.newGameFields.galleryImages.splice(index, 1);
        }
        if (image.url.includes("video/")) {
          const index = this.newGameFields.galleryVideos.indexOf(image);
          this.newGameFields.galleryVideos.splice(index, 1);
        }
        const imageToDelete = this.galleryImagesToUpload.find(
          (image) => image.file === image.file
        );
        const index2 = this.galleryImagesToUpload.indexOf(imageToDelete);
        this.galleryImagesToUpload.splice(index2, 1);
      } else {
        if (image.url.includes("images/")) {
          const index = this.newGameFields.galleryImages.indexOf(image);
          this.newGameFields.galleryImages.splice(index, 1);
        }
        if (image.url.includes("videos/")) {
          const index = this.newGameFields.galleryVideos.indexOf(image);
          this.newGameFields.galleryVideos.splice(index, 1);
        }
        this.mediaToDelete.push(image.id);
      }
    },
    openFileInput(ref) {
      this.$refs[ref].click();
    },
    errorMessage(message) {
      this.$toast.error(message, {
        position: "top-right",
        autoClose: 3000,
      });
    },
    getHtmlText(htmlString) {
      // Create a temporary DOM element
      var tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;
      // Extract the text content
      return tempElement.textContent || tempElement.innerText;
    },
    checkUpdates(body, imagesToUpload) {
      Object.keys(body).forEach((key, index) => {

        if(key !== 'supportedPlatforms' && key !== 'platforms' && key !== 'propertiesData' && key !== 'description_en' && key !== 'description_it') {
          if(this.newGameFields[key] != this.game[key]) {
            this.updates[key] = {
              old: this.game[key],
              new: this.newGameFields[key]
            }
          } else if(this.updates[key] && Object.keys(this.updates[key]).length > 0) delete this.updates[key]
        }
        if(key === 'propertiesData') {
          if(this.updates.propertiesDataChanges) delete  this.updates.propertiesDataChanges
          for (const data of Object.keys(this.newGameFields.propertiesData)) {
            // if game has previous propertiesData
            if(this.game.propertiesData[data]) {
              // check differences
              if(this.newGameFields.propertiesData[data] !== this.game.propertiesData[data]) {
                if(!this.updates.propertiesDataChanges) this.updates.propertiesDataChanges = {}
                this.updates.propertiesDataChanges[data] = {
                  old: this.game.propertiesData[data],
                  new: this.newGameFields.propertiesData[data]
                }
              }
            } else {
              if(!this.updates.propertiesDataChanges) this.updates.propertiesDataChanges = {}
              this.updates.propertiesDataChanges[data] = {
                old: {},
                new: this.newGameFields.propertiesData[data]
              }
            }
          }
          const oldProperties = Object.keys(this.game.propertiesData)
          const newProperties = Object.keys(this.newGameFields.propertiesData)
          for (const property of oldProperties) {
            if(!newProperties.includes(property)) {
              if(!this.updates.propertiesDataChanges) this.updates.propertiesDataChanges = {}
              this.updates.propertiesDataChanges[property] = 'removed'
            }
          }
        }
        if(key === 'supportedPlatforms') {
          if(this.newGameFields.supportedPlatforms.desktop !== this.game.supportedPlatforms.desktop || 
            this.newGameFields.supportedPlatforms.mobile !== this.game.supportedPlatforms.mobile) {
            this.updates.supportedPlatforms = {
              old: this.game.supportedPlatforms,
              new: this.newGameFields.supportedPlatforms
            }
          } else if(this.updates.supportedPlatforms && Object.keys(this.updates.supportedPlatforms).length > 0) delete this.updates.supportedPlatforms
        }
        if(key === 'description_en' || key === 'description_it') {
          if(this.newGameFields[key] !== this.game[key]) {
            this.updates[key] = {
              old: this.getHtmlText(this.game[key]),
              new: this.getHtmlText(this.newGameFields[key])
            }
          }
        }
        if(key === 'platformsToAdd') {
          if(this.platformsToAdd.length > 0) {
            this.updates.platformsAdded = this.platformsToAdd.map(platform => platform.name)
          } else if(this.updates.platformsAdded?.length > 0) delete this.updates.platformsAdded
        }
        if(key === 'platformsToDelete') {
          if(this.platformsToDelete.length > 0) {
            this.updates.platformsRemoved = this.platformsToDelete.map(platform => platform.name)
          } else if(this.updates.platformsRemoved?.length > 0) delete this.updates.platformsRemoved
        }
        
      });
      const newDocs = this.newGameFields.docs
      const oldDocs = this.game.game_media.find((image) => image.mediaType === "docs")
      if((oldDocs === undefined && Object.keys(newDocs).length !== 0) || oldDocs !== undefined && newDocs?.url !== oldDocs?.url) {
        if(!this.updates.mediaAdded) this.updates.mediaAdded = {}
        this.updates.mediaAdded.docsChanged = true
      }
      const newAssets = this.newGameFields.assets
      const oldAssets = this.game.game_media.find((image) => image.mediaType === "assets")
      if((oldAssets === undefined && Object.keys(newAssets).length !== 0) || oldAssets !== undefined && newAssets?.url !== oldAssets?.url) {
        if(!this.updates.mediaAdded) this.updates.mediaAdded = {}
        this.updates.mediaAdded.assetsChanged = true
      }
      if(imagesToUpload.length > 0) {
        this.updates.mediaAdded = {}
        for (const image of imagesToUpload) {
          if(image.isMainImage) this.updates.mediaAdded.mainImageChanged = true
          if(image.isBanner) this.updates.mediaAdded.bannerImageChanged = true
          if(image.isMiniBanner) this.updates.mediaAdded.miniBannerChanged = true
          if(image.mediaType === 'gallery' && !image.isMainImage && !image.isBanner && !image.isMiniBanner){
            this.updates.mediaAdded.gallery ? this.updates.mediaAdded.gallery += 1 : this.updates.mediaAdded.gallery = 1
          }
        }
      }
      if(this.mediaToDelete.length > 0) {
        this.updates.imagesDeleted = this.mediaToDelete.length
      }
    },
    async convertToWebP(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error('No file provided'));
          return;
        }

        const reader = new FileReader();

        reader.onload = () => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            canvas.toBlob((blob) => {
              // Now we have a `blob` containing webp data
              const newFileName = file.name.split('.')[0]
              const convertedFile = new File([blob], `${newFileName}.webp`, { type: blob.type });
              resolve(convertedFile);
            }, 'image/webp');
          };

          image.src = reader.result;
        };

        reader.readAsDataURL(file);
      });
    },
    async uploadMedia(event, type) {
      const file = event.target.files[0];
      // console.log(file)
      const extension = file?.name.split(".")[1];
      if (extension !== "jpg" && extension !== "png" && extension !== "webp") {
        return this.errorMessage("Wrong file extension");
      }

      const convertedFile = await this.convertToWebP(file)
      // console.log(convertedFile)
      if (type == "mainImage") {
        this.mainImageToUpload = {
          file: convertedFile,
          originalFile: file,
          isMainImage: true,
          isBanner: false,
          isMiniBanner: false,
          mediaType: "gallery",
        };
        const oldMainImage = this.game.game_media?.find((image) => image.isMainImage);
        if (oldMainImage && !this.mediaToDelete.includes(oldMainImage.id)) {
          this.mediaToDelete.push(oldMainImage.id);
        }
      }
      if (type == "bannerImage") {
        this.bannerImageToUpload = {
          file: convertedFile,
          originalFile: file,
          isMainImage: false,
          isBanner: true,
          isMiniBanner: false,
          mediaType: "gallery",
        };
        const oldBannerImage = this.game.game_media?.find((image) => image.isBanner);
        if (oldBannerImage && !this.mediaToDelete.includes(oldBannerImage.id)) {
          this.mediaToDelete.push(oldBannerImage.id);
        }
      }
      if (type == "miniBanner") {
        this.miniBannerToUpload = {
          file: convertedFile,
          originalFile: file,
          isMainImage: false,
          isBanner: false,
          isMiniBanner: true,
          mediaType: "gallery",
        };
        const oldMiniBanner = this.game.game_media?.find((image) => image.isMiniBanner);
        if (oldMiniBanner && !this.mediaToDelete.includes(oldMiniBanner.id)) {
          this.mediaToDelete.push(oldMiniBanner.id);
        }
      }
      const fileSrc = URL.createObjectURL(convertedFile);
      // console.log(fileSrc)
      // get actual file dimensions
      const img = new Image();
      img.onload = () => {
        this.newGameFields[type].width = img.width;
        this.newGameFields[type].height = img.height;
        // URL.revokeObjectURL(img.src);
      };
      img.src = fileSrc;
      this.newGameFields[type].url = fileSrc;
      this.newGameFields[type].name = convertedFile.name;
      // console.log(fileSrc)
    },
    async submitDocs() {
      const newDocs = this.newGameFields.docs
      const oldDocs = this.game.game_media.find((image) => image.mediaType === "docs")

      if((!oldDocs && newDocs?.url) || newDocs?.url !== oldDocs?.url) {
        await request.post(`/games/media/url/${this.game.id}`, {
          url: newDocs.url,
          mediaType: 'docs',
          type: 'url'
        })
      }
    },
    async submitAssets() {
      const newAssets = this.newGameFields.assets
      const oldAssets = this.game.game_media.find((image) => image.mediaType === "assets")

      if((!oldAssets && newAssets?.url) || newAssets?.url !== oldAssets?.url) {
        await request.post(`/games/media/url/${this.game.id}`, {
          url: newAssets.url,
          mediaType: 'assets',
          type: 'url'
        })
      }
    },
    async submitMedia(imagesToUpload) {
      await this.submitDocs();
      await this.submitAssets();
      await this.deleteMedia();

      await Promise.all(
        imagesToUpload.map((image) => {
          let formData = new FormData();
          formData.append("file", image.file);
          formData.append("isMainImage", image.isMainImage);
          formData.append("isBanner", image.isBanner);
          formData.append("isMiniBanner", image.isMiniBanner);
          formData.append("mediaType", image.mediaType ? image.mediaType : "gallery");
          formData.append("type", "file");
          
          // Check if there's a compressed file and append it to the formData
          if (image.originalFile) {
            formData.append("file", image.originalFile); // Use the same field name "file"
          }

          return request.post(`/games/media/upload/${this.game.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        })
      ).then(() => {
        this.$router.push(`/game/${this.game.id}`);
      });
    },
    async deleteMedia() {
      await Promise.all(
        this.mediaToDelete.map((id) => request.delete(`/games/media/delete/${id}`))
      ) 
    },
    async submitData() {
      this.newGameFields.propertiesData = {};
      for (let i = 0; i < this.dataFields.length; i++) {
        this.newGameFields.propertiesData[this.dataFields[i].key] = this.dataFields[i].value;
      }
      const body = {
        id: this.game.id,
        name: this.newGameFields.name,
        sub_name: this.newGameFields.sub_name,
        slug: this.newGameFields.slug,
        externalIdentifier: this.newGameFields.externalIdentifier,
        rtp: this.newGameFields.rtp ? this.newGameFields.rtp.toFixed(2) : null,
        volatility: this.newGameFields.volatility,
        hit_frequency: this.newGameFields.hit_frequency,
        max_multiplier_win: this.newGameFields.max_multiplier_win ? this.newGameFields.max_multiplier_win.toFixed(2) : null,
        type: this.newGameFields.type,
        propertiesData: this.newGameFields.propertiesData,
        description_en: this.descriptions.english,
        description_it: this.descriptions.italian,
        release_date: this.newGameFields.release_date,
        supportedPlatforms: this.newGameFields.supportedPlatforms,
        platforms: this.newGameFields.gamePlatforms,
        format: this.newGameFields.format,
        max_bet: this.newGameFields.max_bet ? this.newGameFields.max_bet.toFixed(2) : null,
        min_bet: this.newGameFields.min_bet ? this.newGameFields.min_bet.toFixed(2): null,
        lines: this.newGameFields.lines ? this.newGameFields.lines : 0,
        reels: this.newGameFields.reels ? this.newGameFields.reels : 0,
        gameRows: this.newGameFields.gameRows ? this.newGameFields.gameRows : 0,
        isBuyBonus: this.newGameFields.isBuyBonus,
        isDev: this.newGameFields.isDev,
        max_exposure: this.newGameFields.max_exposure,
        platformsToAdd: this.platformsToAdd,
        platformsToDelete: this.platformsToDelete,
        pay_method: this.payMethod,
        isCatalog: true
      }
      const imagesToUpload = this.galleryImagesToUpload;
      if (this.mainImageToUpload.file) imagesToUpload.push(this.mainImageToUpload);
      if (this.bannerImageToUpload.file) imagesToUpload.push(this.bannerImageToUpload);
      if (this.miniBannerToUpload.file) imagesToUpload.push(this.miniBannerToUpload);
      if (this.docsFileToUpload.file) imagesToUpload.push(this.docsFileToUpload);
      if (this.assetsFileToUpload.file) imagesToUpload.push(this.assetsFileToUpload);

      // this.checkUpdates(body, imagesToUpload)
      // if(Object.keys(this.updates).length == 0) {
      //   // this.errorMessage('No changes were made')
      //   this.ready = true;
      //   this.$router.push(`/game/${this.game.id}`);
      //   return
      // }
      this.ready = false;
      const url = this.game.providerId
        ? `/games/update/${this.game.id}?providerName=gamestorage`
        : `/games/update/${this.game.id}`;
      
      Promise.all([
        await request.put(
          url,
          body,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ),
        // await this.deleteMedia(),
        await this.submitMedia(imagesToUpload),
      ]).then(() => {
        this.ready = true;
        // request.post('/logs/create', {
        //   type: 1,
        //   description: `${this.user.username} UPDATED the game "${this.game.slug}" (gameId: ${this.game.id})`,
        //   updates: this.updates
        // })
        this.$router.push(`/game/${this.game.id}`);
      });
    },
    async deleteGame() {
      try {
        await request.delete(`/games/delete/${this.game.id}`);
        this.$toast.success('Game deleted');
        this.$router.push('/catalog')
      } catch (e) {
        console.log(e)
        this.$toast.error(e.message);
      }
    }
  },
};
</script>

<style >
.edit .ql-toolbar {
  width: 100%;
  max-width: 1250px;
  background-color: white;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
</style>

const state = {
  itemsDisplay: 'grid',
  gridSort: 'release_date',
  listView: 'viewActiveList',
  gamesToRequest: [],
  requestingGames: false,
  gamesOrder: [],
}
const getters = {
  itemsDisplay: state => state.itemsDisplay,
  gamesToRequest: state => state.gamesToRequest,
  requestingGames: state => state.requesting,
  gridSort: state => state.gridSort,
  listView: state => state.listView,
  gamesOrder: state => state.gamesOrder,
}
const actions = {
  setItemsDisplayAction({commit}, payload) {
    commit('setItemsDisplay', payload)
  },
  addGamesToRequestAction({commit}, payload) {
    commit('addGamesToRequest', payload)
  },
  removeGamesToRequestAction({commit}, index) {
    commit('removeGamesToRequest', index)
  },
  setRequestingGamesAction({commit}, payload) {
    commit('setRequestingGames', payload)
  },
  setGridSortAction({commit}, payload) {
    commit('setGridSort', payload)
  },
  setListViewAction({commit}, payload) {
    commit('setListView', payload)
  },
  setGamesOrderAction({commit}, payload) {
    commit('setGamesOrder', payload)
  }
}
const mutations = {
  setItemsDisplay(state, payload) {
    state.itemsDisplay = payload
  },
  addGamesToRequest(state, payload) {
    state.gamesToRequest.push(payload)
  },
  removeGamesToRequest(state, index) {
    state.gamesToRequest.splice(index, 1)
  },
  setRequestingGames(state, payload) {
    state.requestingGames = payload
  },
  setGridSort(state, payload) {
    state.gridSort = payload
  },
  setListView(state, payload) {
    state.listView = payload
  },
  setGamesOrder(state, payload) {
    state.gamesOrder = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

const state = {
  fromStorage: false,
  basicInfo: {},
  gameDetails: {},
  imagesToUpload: [],
  gameMedia: {},
  stepThreeFields : {},
  loading: false,
}

const getters = {
  basicInfo: state => state.basicInfo,
  gameDetails: state => state.gameDetails,
  imagesToUpload: state => state.imagesToUpload,
  gameMedia: state => state.gameMedia,
  fromStorage: state => state.fromStorage,
  loading: state => state.loading,
}

const actions = {
  stepOneAction({commit}, payload) {
    commit('setBasicInfo', payload)
  },
  stepTwoAction({commit}, payload) {
    commit('setImagesToUpload', payload.imagesToUpload)
    commit('setGameMedia', payload.gameMedia)
  },
  stepThreeAction({commit}, payload) {
    commit('setGameDetails', payload.gameDetails)
  },
  setFromStorageAction({commit}, payload) {
    commit('setFromStorage', payload)
  },
  setLoadingAction({commit}, payload) {
    commit('setLoading', payload)
  },
  resetFormAction({commit}) {
    commit('resetForm')
  }
}

const mutations = {
  setBasicInfo(state, payload) {
    state.basicInfo = payload
  },
  setGameDetails(state, payload) {
    state.gameDetails = payload
  },
  setFromStorage(state, payload) {
    state.fromStorage = payload
  },
  setImagesToUpload(state, payload) {
    state.imagesToUpload = payload
  },
  setGameMedia(state, payload) {
    state.gameMedia = payload
  },
  setLoading(state, payload) {
    state.loading = payload
  },
  resetForm(state) {
    state.basicInfo = {}
    state.gameDetails = {}
    state.gameMedia = {}
    state.imagesToUpload = []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
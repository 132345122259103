<template>
  <tbody class="z-10" v-if="games.length > 0">
    <tr
      v-for="(game, index) in games"
      :key="game.id"
      class="hover:brightness-125 cursor-pointer"
      @click="openGame(game)"
      :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
      :style="
        index % 2 === 0
          ? getMiniBannerBackground(game, 'gray')
          : getMiniBannerBackground(game, 'black')
      "
    >
      <td
        class="table-cell relative whitespace-nowrap bg-cover bg-no-repeat text-center pl-5"
        :class="game.sub_name ? 'py-3' : 'py-6'"
      >
        <span
          class="truncate uppercase flex flex-col items-start gap-x-1.5 -mb-1 w-max max-w-full text-center pl-[60%]"
        >
          <span
            class="text-lg sm:text-xl text-zinc-900 dark:text-white fugaz cursor-pointer truncate max-w-full px-1.5 pt-px"
            style="text-shadow: #000 0px 0 5px"
            :class="game.sub_name ? 'rounded-t-sm' : 'rounded-sm'"
          >
            {{ game.name }}
          </span>
          <span
            v-if="game.sub_name"
            class="text-sm sm:text-base text-zinc-900 dark:text-white fugaz cursor-pointer truncate px-1.5 pt-px rounded-b-sm"
            style="text-shadow: #000 0px 0 5px"
          >
            {{ game.sub_name }}
          </span>
        </span>
      </td>
      <td
        class="hidden lg:table-cell w-28 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <span class="font-semibold">
          {{ game.rtp ? `${game.rtp.toFixed(2)} %` : "N/A" }}
        </span>
      </td>
      <td
        class="hidden lg:table-cell w-44 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <div
          class="flex justify-center gap-2 text-4xl text-white"
          :title="game.volatility"
        >
          <div
            v-for="index in fullCircle(game)"
            :key="`circle${index}`"
            class="text-2xl"
          >
            <font-awesome-icon :icon="['fa', 'circle']" />
          </div>
          <div v-if="hasHalfCircle(game)" class="text-2xl">
            <font-awesome-icon :icon="['fas', 'circle-half-stroke']" />
          </div>
          <div
            v-for="(n, index) in emptyCircles(game)"
            :key="`emptyCircle${index}`"
            class="text-2xl"
          >
            <font-awesome-icon :icon="['far', 'circle']" />
          </div>
        </div>
      </td>
      <!-- <td
        class="hidden xl:table-cell w-24 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <span class="font-semibold">
          {{ game.hit_frequency ? `${game.hit_frequency} %` : "N/A" }}
        </span>
      </td> -->
      <td
        class="hidden xl:table-cell w-24 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <span class="font-semibold">
          {{ game.max_multiplier_win ? `x${game.max_multiplier_win}` : "N/A" }}
        </span>
      </td>
      <!-- <td
        class="hidden xl:table-cell w-24 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <span class="font-semibold">
          {{ game.max_bet ? getCurrencyValue(game.max_bet) : "N/A" }}
        </span>
      </td>
      <td
        class="hidden xl:table-cell w-24 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <span class="font-semibold">
          {{ game.min_bet ? getCurrencyValue(game.min_bet) : "N/A" }}
        </span>
      </td> -->
      <!-- <td
        class="hidden xl:table-cell w-24 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <span class="font-semibold">
          {{ (game.lines && game.lines > 0) ? game.lines : "N/A" }}
        </span>
      </td>
      <td
        class="hidden xl:table-cell w-24 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <span class="font-semibold">
          {{ game.reels ? game.reels : "N/A" }}
        </span>
      </td>
      <td
        class="hidden xl:table-cell w-24 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <span class="font-semibold">
          {{ game.gameRows ? game.gameRows : "N/A" }}
        </span>
      </td> -->
      <td
        v-if="!viewActiveList && requestingGames"
        class="flex items-center w-24 pl-3 pt-6 whitespace-nowrap text-md xl:text-lg text-zinc-500 dark:text-white text-center"
      >
        <label
          v-if="!game.platform_games?.is_requested"
          class="checkbox mt-0.5"
        >
          <input
            type="checkbox"
            @click="addGameToRequestedList(game)"
            :checked="gamesToRequestChecked(game)"
          />
          <span class="checkmark"></span>
        </label>
      </td>
    </tr>
  </tbody>
  <div v-else class="text-white text-lg py-3 pl-1">Nothing to show</div>
</template>

<script>
export default {
  name: "CatalogTable",
  props: {
    games: {
      type: Array,
      default: [],
    },
    requestingGames: Boolean,
    viewActiveList: Boolean,
    gamesToRequest: Array,
    setGamesOrder: Function,
  },
  data() {
    return {};
  },
  methods: {
    openGame(game) {
      this.setGamesOrder();
      this.$router.push({
        name: "Game",
        params: {
          id: game.id,
        },
      });
    },
    getMiniBannerBackground(game, color) {
      const image = game.game_media.find(
        (image) => image.isMiniBanner === true
      );
      if (image) {
        if (color === "gray") {
          // rgb(29 29 31
          return `background-image: linear-gradient(to left, rgba(29, 29, 31, 1) 28%, rgba(29, 29, 31, 1) 0%, rgba(29, 29, 31, 0)), url("${image.url}");`;
        }
        if (color === "black") {
          // 24 24 27
          return `background-image: linear-gradient(to left, rgba(24, 24, 27, 1) 28%, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 27, 0)), url("${image.url}");`;
        }
      } else
        return `background-image: linear-gradient(to left, rgba(24, 24, 27, 1) 28%, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 27, 0)), rgba(24, 24, 27, 0));`;
    },
    addGameToRequestedList(game) {
      const gamesToRequestIDs = this.gamesToRequest.map((game) => game.id);
      if (gamesToRequestIDs.includes(game.id)) {
        const index = gamesToRequestIDs.indexOf(game.id);
        this.$store.dispatch("requesting/removeGamesToRequestAction", index);
        // this.gamesToRequest.splice(index, 1)
      } else {
        // this.gamesToRequest.push(game)
        this.$store.dispatch("requesting/addGamesToRequestAction", game);
      }
    },
    gamesToRequestChecked(game) {
      const gamesToRequestNames = this.gamesToRequest.map((game) => game.name);
      if (gamesToRequestNames.includes(game.name)) return true;
      else return false;
    },
    fullCircle(game) {
      return Math.floor(game.volatility / 2);
    },
    hasHalfCircle(game) {
      return game.volatility % 2 !== 0;
    },
    emptyCircles(game) {
      const maxCircles = 5;
      return Math.floor(maxCircles - game.volatility / 2);
    },
  },
};
</script>

<style></style>

<template>
  <div @click="closeBox" class="w-full h-full flex justify-center items-center bg-black bg-opacity-70 fixed top-0 left-0 z-[999] overflow-y-auto">
    <section @click.stop class="bg-zinc-800 w-[50vw] flex flex-col gap-3 rounded-md text-white relative border border-zinc-700">
      <header class="w-full py-3 px-4 border-b border-zinc-600 relative">
        <h3 class="fugaz uppercase">{{ editingEmail !== null ? $t('mailing.editEmail') : $t('mailing.newEmail') }}</h3>
        <button @click.stop="closeBox" class="absolute top-1.5 right-2 p-1 group">
          <font-awesome-icon :icon="['fas', 'xmark']" class="h-5 text-zinc-300 group-hover:text-orange-600 transition-all duration-100" />
        </button>
      </header>
      <div class="flex items-start justify-start gap-4 px-4">
        <div class="flex flex-col gap-1">
          <span class="text-sm font-light pl-0.5">{{ $t('mailing.platform') }}</span>
          <div class="flex items-center gap-1.5">
            <div 
              class="w-full md:w-52 z-[10] max-h-[15rem]" 
              :class="appLanguage.long == 'en-GB' ? 'min-w-[430px]' : 'min-w-[540px]'"
            >
              <Multiselect
                mode="single"
                v-model="selectedPlatform"
                :options="allPlatforms"
                label="name"
                value-prop="id"
                :placeholder="$t('mailing.selectPlatform')"
                :hide-selected="false"
                :caret="false"
                :close-on-select="false"
                :searchable="true"
                :multiple-label="values => `${values.length} Platforms selected`"
                :disabled="platformType == 'all'"
              />
            </div>
            <button @click="updatePlatformType('all')" class="px-3 py-[8px] rounded-[3px] font-semibold"
              :class="platformType == 'all' ? 'bg-orange-600' : 'bg-zinc-700'"
            >
              {{ $t('mailing.all') }}
            </button>
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <span class="text-sm font-light pl-0.5">{{ $t('mailing.reportTypes') }}</span>
          <div class="flex items-center gap-1.5 font-semibold">
            <button @click="addReportType('daily', 1)" class="px-3 py-[5px] rounded-[3px] border-[3px] bg-zinc-700"
              :class="selectedReportType.name == 'daily' ? 'border-orange-600' : 'border-transparent'"
            >
              {{ $t('mailing.daily') }}
            </button>
            <button @click="addReportType('monthly', 2)" class="px-3 py-[5px] rounded-[3px] border-[3px] bg-zinc-700"
              :class="selectedReportType.name == 'monthly' ? 'border-orange-600' : 'border-transparent'"
            >
              {{ $t('mailing.monthly') }}
            </button>
          </div>
        </div>
      </div>
      <form @submit.prevent="submit" class="px-4 pb-5">
        <label for="email" class="text-sm font-light pl-1">{{ $t('mailing.email') }}</label>
        <div class="flex items-center gap-2">
          <input v-model="newEmail" 
            :disabled="editingEmail !== null" type="email" name="email" 
            class="w-full bg-zinc-900 outline-none py-[7px] pl-2 rounded-[3px] disabled:opacity-70 disabled:cursor-not-allowed">
        </div>
        <div class="flex justify-center">
          <button type="submit" class="mt-3.5 px-5 py-1 rounded-[3px] bg-orange-600 hover:bg-pink-700 fugaz uppercase">
            {{ editingEmail !== null ? $t('mailing.save') : $t('mailing.add') }}
          </button>
        </div>
      </form>
      <button v-if="!deleting && editingEmail !== null" @click="deleting = true" class="absolute bottom-2 right-3.5 fugaz uppercase text-sm hover:text-red-600">
        {{ $t('mailing.delete') }}
      </button>
      <div v-if="deleting" class="absolute bottom-2 right-3.5 fugaz uppercase text-sm flex items-center gap-1.5">
        {{ $t('mailing.sure') }}
        <button @click="deleteEmail" class="fugaz uppercase text-sm hover:text-orange-600">
          {{ $t('mailing.yes') }}
        </button>
        <button @click="deleting = false" class="fugaz uppercase text-sm hover:text-orange-600">
          {{ $t('mailing.no') }}
        </button>
      </div>
    </section>
  </div>
</template>
<script>
  import request from '@/services/axios'
  import Multiselect from '@vueform/multiselect'
  
  export default {
    data() {
      return {
        editing: false,
        platformType: '',
        selectedPlatform: null,
        selectedReportType: {
          name: 'daily',
          id: 1
        },
        newEmail: '',
        selectedEmails: [],
        deleting: false,
      }
    },
    props: {
      allPlatforms: Array,
      editingEmail: Object,
    },
    components: {
      Multiselect
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
    },
    mounted() {
      if (this.editingEmail) {
        this.newEmail = this.editingEmail.email
        if (this.editingEmail.platformId !== null) {
          this.selectedPlatform = this.editingEmail.platformId
          this.platformType = ''
        } else {
          this.platformType = 'all'
          this.selectedPlatform = this.allPlatforms.map(p => p.id)
        }
        this.selectedReportType = {
          name: this.editingEmail.reportType,
          id: this.editingEmail.reportTypeId
        }
      }
    },
    methods: {
      updatePlatformType(value) {
        if (this.platformType !== value) {
          this.platformType = value
          if (this.platformType == 'all') {
            this.selectedPlatform = this.allPlatforms.map(p => p.id)
          } else {
            this.selectedPlatform = []
          }
        } else {
          this.platformType = ''
        }
      },
      selectAllPlatforms() {
        if(this.selectedPlatform.length !== this.allPlatforms.length) {
          this.selectedPlatform = this.allPlatforms.map(p => p.id)
        } else {
          this.selectedPlatform = []
        }
      },
      closeBox() {
        this.newEmail = ''
        this.selectedPlatform = null
        this.selectedReportType = {
          name: 'daily',
          id: 1
        }
        this.$emit('close-new-email')
      },
      addReportType(reportType, id) {
        this.selectedReportType = {
          name: reportType,
          id: id
        }
      },
      checkValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email)
      },
      addEmail() {
        if (!this.checkValidEmail(this.newEmail)) {
            this.$toast.error("Please enter a valid email address.");
            return; // Stop execution if email is invalid
        }

        this.selectedEmails.push(this.newEmail);
        this.newEmail = '';
      },
      removeEmail(email) {
        this.selectedEmails.splice(this.selectedEmails.indexOf(email), 1)
      },
      checkErrors() {
        if (!this.checkValidEmail(this.newEmail)) {
            this.$toast.error("Please enter a valid email address.");
            return false;
        }
        if (this.selectedReportType.length == 0) {
          this.$toast.error("Please select at least one report type.");
          return false;
        }
        // if (this.selectedEmails.length == 0) {
        //   this.$toast.error("Please add at least one email address.");
        //   return;
        // }
        if (this.selectedPlatform == null) {
          this.$toast.error("Please select at least one platform, or ALL");
          return false;
        }
        return true
      },
      async deleteEmail() {
        try {
          await request.delete(`/mailing-list-reports/${this.editingEmail.id}`)
          this.closeBox()
          this.$toast.success('Email deleted successfully')
        } catch (error) {
          console.log(error)
          this.$toast.error(error.message)
        }
      },
      async submit() {
        if (!this.checkErrors()) return
        const platformId = this.selectedPlatform.length == this.allPlatforms.length ? null : this.selectedPlatform
        try {
          await request.post('/mailing-list-reports', {
            email: this.newEmail,
            reportTypeId: this.selectedReportType.id,
            platformId,
          })
          if (this.editingEmail !== null) {
            this.closeBox()
            this.$toast.success('Email edited successfully')
          } else {
            this.$toast.success('Email added successfully')
            this.newEmail = ''
            this.$emit('new-email-created')
          }
        } catch (error) {
          console.log(error)
          this.$toast.error(error.message)
        }
      }
    }
  }
</script>
<style scoped>

</style>
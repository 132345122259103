<template>
  <div @click="emitter.emit('closeTicket')" class="text-white bg-black bg-opacity-60 w-full h-full flex flex-col items-center justify-center fixed top-0 left-0 z-[999]">
    <!-- <button @click="$emit('closeTicket')" class="absolute top-0 left-0 fugaz uppercase pb-1 pt-1.5 px-2 hover:text-orange-600">
      <font-awesome-icon :icon="['fas', 'arrow-left']" />
      tickets
    </button> -->
    <div @click.stop class="w-[70%] max-h-[95vh] flex flex-col gap-4 border border-zinc-600 rounded-md bg-black overflow-y-auto">
      <div class="sticky top-0 bg-zinc-950 rounded-t-md px-3 py-2 border-b border-zinc-700 flex items-start justify-between">
        <div class="flex items-center gap-2.5 pt-1">
          <h2 class="fugaz uppercase">
            {{ ticket.title }}
          </h2>
          <div class="px-3 pt-0.5 mb-1 rounded-xl fugaz uppercase" :class="getStatusColor(ticket.status)">{{ ticket.status }}</div>
        </div>
        <div class="h-full flex flex-col justify-between items-start">
          <span v-if="ticket.platform" class="font-extralight">{{ $t('tickets.platform') }}: <strong>{{ ticket.platform.name}}</strong></span>
          <div v-if="ticket.session" class="flex items-center gap-1.5 font-extralight">{{ $t('tickets.sessionId') }}: <strong>{{ ticket.session.aamsTicketId }}</strong>
            <font-awesome-icon :icon="['fas', 'up-right-from-square']" class="h-3 mb-0.5" />
          </div>
        </div>
        <div class="h-full flex flex-col justify-between items-end">
          <span class="font-light">{{ $t('tickets.createdBy') }}
            <strong v-if="ticket.reporter" class="capitalize">
              {{ loggedUser.username !== ticket.reporter.username ? ticket.reporter.username : 'You' }}
            </strong>
            <strong v-else class="capitalize">[{{ $t('tickets.deleted') }}]</strong>
          </span>
          <span class="font-extralight">{{ clearDate(ticket.createdAt) }}</span>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="w-full rounded-md flex items-center px-3">
          <div class="bg-zinc-800 border border-zinc-700 w-full h-full rounded-md px-2 py-2">
            <div class="bg-zinc-900 rounded-md p-2">
              <span v-html="ticket.description"></span>
            </div>
            <div class="mt-1.5 flex items-center" :class="ticket.ticket_media.length > 0 ? 'justify-between' : 'justify-end'">
              <div v-if="ticket.ticket_media.length > 0" class="flex items-center gap-1.5 pl-1">
                <font-awesome-icon :icon="['fas', 'paperclip']" class="h-3.5 mb-0.5" />
                {{ $t('tickets.attachments') }}:
                <div class="flex items-center gap-2.5">
                  <button v-for="media in ticket.ticket_media" @click="downloadFile(media.s3Url)" class="font-semibold hover:text-orange-600">
                    {{ clearMediaName(media.url) }}
                  </button>
                </div>
              </div>
              <div class="font-light">
                <strong v-if="ticket.reporter" class="capitalize">
                  {{ loggedUser.username !== ticket.reporter.username ? ticket.reporter.username : 'You' }}
                </strong>
                <strong v-else class="capitalize">[{{ $t('tickets.deleted') }}]</strong>
                <span class="text-sm ml-1.5">at {{ clearDate(ticket.createdAt) }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- answers -->
        <div v-if="ticket.answers.length > 0" class="w-full rounded-md flex flex-col gap-4 items-center px-3 py-4">
          <div v-for="(answer, index) in ticket.answers" 
            class="bg-zinc-700 w-full h-full rounded-md px-1.5 pt-1.5 pb-1 border-opacity-50"
            :class="
              (isPlatformUser && answer.responder?.role.includes('platform') && 'ml-2.5'),
              (!isPlatformUser && answer.responder?.role.includes('platform') && 'mr-2.5')
            "
          >
            <div v-if="index === ticket.answers.length - 1 && ticket.status === 'closed'" class="pb-1.5 text-center">
              <strong class="capitalize">{{ answer.responder ? answer.responder.username : `[${$t('tickets.deleted')}]` }}</strong> {{ $t('tickets.closedBy') }}
            </div>
            <div class="bg-zinc-800 rounded-md p-2">
              <span v-html="answer.description"></span>
            </div>
            <div class="mt-1.5 flex items-center" :class="answer.ticket_media.length > 0 ? 'justify-between' : 'justify-end'">
              <div v-if="answer.ticket_media.length > 0" class="flex items-center gap-1.5 pl-1">
                <font-awesome-icon :icon="['fas', 'paperclip']" class="h-3.5 mb-0.5" />
                {{ $t('tickets.attachments') }}:
                <div class="flex items-center gap-2.5">
                  <button v-for="media in answer.ticket_media" @click="downloadFile(media.s3Url)" class="font-semibold hover:text-orange-600">
                    {{ clearMediaName(media.url) }}
                  </button>
                </div>
              </div>
              <div class="font-light text-right">
                <strong v-if="ticket.reporter" class="capitalize">
                  {{ loggedUser.username !== ticket.reporter.username ? ticket.reporter.username : 'You' }}
                </strong>
                <strong v-else class="capitalize">[{{ $t('tickets.deleted') }}]</strong>
                <span class="text-sm ml-1.5">{{ clearDate(answer.createdAt) }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- ONGOING -->
        <div v-if="ticket.status !== 'closed' && !creatingAnswer" 
          class="w-full py-3 flex gap-2 justify-center sticky bottom-0 bg-zinc-950 border-t border-zinc-800">
          <button v-if="!closing" @click="creatingAnswer = true" class="px-3.5 bg-green-600 hover:bg-green-700 rounded-md fugaz uppercase pt-0.5">
            {{ $t('tickets.addAnswer') }}
          </button>
          <button v-if="!deleting" @click="deleting = true" class="absolute bottom-1 right-2 group font-light">
            {{ $t('tickets.deleteTicket') }}
            <font-awesome-icon :icon="['fas', 'trash']" class="h-3.5 pl-px group-hover:text-orange-600" />
          </button>
          <div v-else class="absolute bottom-1 right-2 flex items-center gap-1.5">
            {{ $t('tickets.sureDelete') }}
            <button @click="deleteTicket" class="pt-px fugaz uppercase text-sm hover:text-orange-600">
              {{ $t('tickets.yes') }}
            </button>
            <button @click="deleting = false" class="pt-px fugaz uppercase text-sm hover:text-orange-600">
              {{ $t('tickets.no') }}
            </button>
          </div>
        </div>
        <!-- ADDING ANSWER -->
        <div v-if="creatingAnswer" class="px-5 pb-3 pt-1.5 border-t border-zinc-800">
          <div class="flex items-center justify-center">
            <h3 class="fugaz uppercase">{{ $t('tickets.newAnswer') }}</h3>
          </div>
          <RichTextInput :value="answerDescription" @input="updateParentValue" class="text-black" />

          <div v-if="mediasToUpload.length > 0" class="flex flex-col gap-1 items-center mt-2">
            {{ $t('tickets.attachments') }}:
            <div class="flex flex-wrap items-center justify-center gap-1.5">
              <button v-for="(media, index) in mediasToUpload" @click="removeMedia(index)" 
                class="font-semibold hover:text-red-500 px-1.5 bg-zinc-700 rounded-md">
                {{ media.name }}
              </button>
            </div>
          </div>

          <div class="flex items-center justify-between mt-1">
            <div class="flex items-center justify-start gap-2 mt-1.5">
              <label for="attachments" 
                class="px-2.5 py-0.5 bg-zinc-700 hover:bg-zinc-800 rounded-md font-semibold flex items-center gap-1.5 cursor-pointer hover:text-orange-600 whitespace-nowrap">
                <font-awesome-icon :icon="['fas', 'paperclip']" class="h-3.5 mb-0.5" />
                {{ $t('tickets.addAttachments') }}
                <input
                  class="hidden"
                  type="file"
                  id="attachments"
                  ref="attachments"
                  multiple
                  @click="$event.target.value = null"
                  @change="handleFileUpload()"
                />
                <font-awesome-icon :icon="['fas', 'plus']" class="h-3.5" />
              </label>
            </div>
            <div class="flex items-center justify-start mt-1.5">
              <button @click="closing = !closing" 
                class=" rounded-md font-semibold flex items-center gap-1.5"
                :class="closing ? 'text-pink-500' : 'text-white'"
              >
                {{ closing ? 'Close Ticket with this answer' : 'Close Ticket with this answer?' }}
              </button>
            </div>
          </div>
          <div class="flex items-center justify-center gap-2 mt-1">
            <button @click="createAnswer" class="pt-0.5 px-3 rounded-md bg-green-600 hover:bg-green-700 fugaz uppercase">{{ $t('tickets.sendAnswer') }}</button>
            <button @click="creatingAnswer = false" class="pt-0.5 px-3 rounded-md bg-zinc-600 hover:bg-zinc-700 fugaz uppercase">{{ $t('tickets.cancel') }}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import request from "@/services/axios";
import RichTextInput from "../components/games/RichTextInput"

  export default {
    data() {
      return {
        creatingAnswer: false,
        answerDescription: "",
        mediasToUpload: [],
        closing: false,
        deleting: false,
      }
    },
    props: {
      ticket: Object,
      getStatusColor: Function,
      clearDate: Function,
    },
    components: {
      RichTextInput,
    },
    computed: {
      loggedUser() {
        return this.$store.getters['user/user']
      },
      isPlatformUser() {
        return Object.keys(this.loggedUser.attributes).length > 0
      },
    },
    methods: {
      removeMedia(index) {
        this.mediasToUpload.splice(index, 1);
      },
      downloadFile(url) {
        window.open(url);
      },
      updateParentValue(value) {
        this.answerDescription = value;
      },
      handleFileUpload() {
        for (let i = 0; i < this.$refs.attachments.files.length; i++) {
          const file = this.$refs.attachments.files[i];
          const fileSrc = URL.createObjectURL(file);
    
          this.mediasToUpload.push({
            file: file,
            name: file.name,
            url: fileSrc
          });
        }
      },
      clearMediaName(mediaUrl) {
        const splittedMedia = mediaUrl.split('/')
        return splittedMedia[splittedMedia.length - 1]
      },
      async deleteTicket() {
        try {
          await request.delete(`/tickets/${this.ticket.id}`)
          this.$toast.success('Ticket deleted')
          this.emitter.emit('ticketUpdated')
          this.emitter.emit('closeTicket')
        } catch (e) {
          console.log(e.message)
          this.$toast.error('Something went wrong, please try again')
        }
      },
      async submitMedia(ticketId, answerId) {
        await Promise.all(
          this.mediasToUpload.map((media) => {
            let formData = new FormData();
            formData.append("file", media.file);
            formData.append("answerId", answerId);

            return request.post(`/tickets/${ticketId}/media`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          })
        )
      },
      async createAnswer() {
        try {
          const newAnswer = (await request.post('/answers', {
            status: this.closing ? 'closed' : 'ongoing',
            description: this.answerDescription,
            ticketId: this.ticket.id,
            responderId: this.loggedUser.userId
          })).data
          
          await this.submitMedia(this.ticket.id, newAnswer.id)

          this.emitter.emit('ticketUpdated')
          this.$toast.success('Answer added')
        } catch (e) {
          console.log(e.message)
          this.$toast.error('Something went wrong, please try again')
        }
      },
    },
    mounted() {

    },
    beforeUnmount() {

    }
  }
</script>
<style scoped>

</style>
import axios from 'axios'
import store from '../store';

const request = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_ENDPOINT
})

request.interceptors.request.use(
  (config) => {
    const token = store.getters['user/token'];

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request
<template>
  <div 
    @click.stop="clickOut()"
    :key="headerKey"
    class="fixed bottom-0 left-0 z-[99999] w-full flex justify-center xl:hidden bg-zinc-900 flex-row transition-all duration-150 h-[50px] border-t border-zinc-950 shadow-md"
  >
    <!-- first row -->
    <!-- <div  class="flex items-center pl-2 justify-between pt-[7.5px]"
      style="min-height: 4rem;"
    >
      <img src="../assets/GAI_LOGO.png" @click="openMobileSidebar(!mobileSidebar)" alt="gai gub logo" 
        class="w-12 h-12 object-cover"
      >
      <div v-if="mobileSidebar" class="h-full px-2.5" @click="openUserBox">
        <font-awesome-icon icon="fa-solid fa-circle-user" class="text-2xl text-white" 
        :class="mobileSidebar ? 'block' : 'hidden'"/>
      </div>
    </div> -->
    <div 
      class="w-full h-full flex items-center relative" 
      :class="(isIphone && isLandscape) ? 'justify-start' : 'justify-center'"
    >
      <div class="h-full px-2.5 flex items-center absolute top-0 bottom-0 my-auto right-0" @click.stop="openUserBox">
        <font-awesome-icon icon="fa-solid fa-circle-user" class="h-6 text-white" />
      </div>
      <div v-if="userMenu" class="absolute bottom-[50px] right-0 flex flex-col items-start bg-zinc-900 text-white rounded-tl-md">
        <router-link 
          @click="goToRoute" 
          :to="{name: 'Notifications'}" 
          class="border-l border-y border-zinc-800 px-2.5 pt-1.5 pb-1 rounded-tl-md w-full flex justify-start gap-1.5 fugaz uppercase text-sm"
        >
          <font-awesome-icon :icon="['fas', 'bell']" class="h-5 text-white" :class="unseenNotifications.length && 'ml-1.5'"/>
          <span class="pt-0.5">Notifications</span>
          <font-awesome-icon v-if="unseenNotifications.length" :icon="['fas', 'circle']" class="h-1.5 w-1.5 text-red-500"/>
        </router-link>
        <router-link 
          @click="goToRoute" 
          :to="{name: 'Settings'}" 
          class="border-l border-b border-zinc-800 px-2.5 pt-1.5 pb-1 w-full flex justify-start gap-1.5 fugaz uppercase text-sm"
        >
          <font-awesome-icon :icon="['fas', 'gear']" class="h-5 text-white"/>
          <span class="pt-0.5">Settings</span>
        </router-link>
        <button @click="logout" class="border-l border-b border-zinc-800 px-2.5 pt-1.5 pb-1 w-full flex justify-start gap-1.5 fugaz uppercase text-sm">
          <font-awesome-icon :icon="['fas', 'right-from-bracket']" class="h-5 text-white" :class="unseenNotifications.length && 'ml-1'"/>
          <span class="pt-0.5">Logout</span>
        </button>
      </div>
      <router-link 
        :to="{ name: 'Dashboard'}"
        v-if="!userRoles.isPlatformGuest && !userRoles.isPlatformManager"
        @click="goToRoute('Dashboard')"
        class="relative w-[42px] h-full flex items-center justify-center transition-opacity duration-100 text-white group"
        :class="[($route.name === 'Dashboard' && 'font-semibold')]"
        title="Dashboard"
      >
        <div class="absolute bottom-0 left-0 h-1 w-full" :class="$route.name === 'Dashboard' ? 'bg-orange-600' : 'group-hover:bg-pink-500'"></div>
        <font-awesome-icon icon="fa-solid fa-chart-line" class="pl-1 pb-1 h-5" />
      </router-link>
      <router-link 
        :to="{ name: 'Catalog'}"
        v-if="!userRoles.isPlatformManager"
        @click="goToRoute('Catalog')"
        class="relative w-[42px] h-full flex items-center justify-center transition-opacity duration-100 text-white group" 
        :class="[(($route.name === 'Catalog' || $route.name === 'Game') && 'font-semibold')]"
        title="Catalog"
      >
        <div 
          class="absolute bottom-0 left-0 h-1 w-full"
          :class="($route.name === 'Catalog' || $route.name === 'Game') ? 'bg-orange-600' : 'group-hover:bg-pink-500'"
        ></div>
        <img class="h-9 w-auto object-contain -mb-1 pt-0.5 invert" src="../assets/noun-slot-machine-4476814.svg" alt="slots">
      </router-link>
      <router-link 
        :to="{ name: 'Games'}"
        v-if="userRoles.isLudusAdmin"
        @click="goToRoute('Games')"
        class="relative w-[42px] h-full flex items-center justify-center transition-opacity duration-100 text-white group" 
        :class="[($route.name === 'Games' && 'font-semibold')]"
        title="Games"
      >
        <div 
          class="absolute bottom-0 left-0 h-1 w-full"
          :class="$route.name === 'Games' ? 'bg-orange-600' : 'group-hover:bg-pink-500'"
        ></div>
        <img class="h-6 w-auto object-contain -mb-1 pb-1 invert" src="../assets/slots.png" alt="slots">
      </router-link>
      <router-link 
        :to="{ name: 'Sessions'}"
        v-if="!userRoles.isPlatformGuest"
        @click="goToRoute('Sessions')"
        class="relative w-[42px] h-full flex items-center justify-center transition-opacity duration-100 text-white group"
        :class="[(true ? 'opacity-100' : 'opacity-0'), (($route.name === 'Sessions' || $route.name === 'Sessions List') && 'font-semibold')]"
      >
        <div 
          class="absolute bottom-0 left-0 h-1 w-full"
          :class="($route.name === 'Sessions' || $route.name === 'Sessions List') ? 'bg-orange-600' : 'group-hover:bg-pink-500'"
        ></div>
        <font-awesome-icon :icon="['fas', 'chalkboard-user']" class="h-5" />
      </router-link>
      <router-link 
        :to="{ name: 'Charts'}"
        v-if="!userRoles.isPlatformGuest && !userRoles.isPlatformManager"
        @click="goToRoute('Charts')"
        class="relative w-[42px] h-full flex items-center justify-center transition-opacity duration-100 text-white group"
        :class="[(true ? 'opacity-100' : 'opacity-0'), ($route.name === 'Charts' && 'font-semibold')]"
      >
        <div 
          class="absolute bottom-0 left-0 h-1 w-full"
          :class="$route.name === 'Charts' ? 'bg-orange-600' : 'group-hover:bg-pink-500'"
        ></div>
        <font-awesome-icon :icon="['fas', 'chart-pie']" class="h-5" />
      </router-link>
      <div
        v-if="(userRoles.isLudusAdmin || userRoles.isLudusManager)"
        @click.stop="expandMenu"
        class="relative w-[42px] h-full flex items-center justify-center transition-opacity duration-100 text-white fugaz uppercase text-sm cursor-pointer group"
      >
        <font-awesome-icon 
          :icon="['fas', 'ellipsis']" 
          class="h-5" 
          :class="($route.name === 'Platforms' || $route.name === 'Bets' || $route.name === 'Maintenances' || $route.name === 'Logs') ? 'text-orange-600' : 'text-white hover:text-pink-500'" 
        />
        <Transition>
          <div v-if="mobileSidebar && !userMenu" 
            class="absolute bottom-[50px] -left-16 flex flex-col items-start bg-zinc-900 w-max border border-zinc-800 rounded-t-md"
          >
            <router-link 
              :to="{ name: 'Platforms'}"
              v-if="(userRoles.isLudusAdmin || userRoles.isLudusManager)"
              @click.stop="goToRoute('Platforms')"
              class="relative py-2 px-2 w-full h-full flex items-center justify-start border-b border-zinc-800 text-white fugaz uppercase text-sm group/platforms" 
            >
              <div 
                class="absolute bottom-0 right-0 w-1 h-full"
                :class="($route.name === 'Platforms' || $route.name === 'New platform' || $route.name === 'Platform') ? 'bg-orange-600' : 'group-hover/platforms:bg-pink-500'"
              ></div>
              <img class="h-[22px] w-auto object-contain -mb-1  invert pr-1.5" src="../assets/data-transfer.svg" alt="slots">
              <span class="pt-1 pl-0.5">Platforms</span>
            </router-link>
            <router-link 
              :to="{ name: 'Bets'}"
              v-if="userRoles.isLudusAdmin"
              @click.stop="goToRoute('Bets')"
              class="relative py-2 px-2 w-full h-full flex items-center justify-start border-b border-zinc-800 text-white fugaz uppercase text-sm group/bets"
            >
              <div 
                class="absolute bottom-0 right-0 w-1 h-full"
                :class="$route.name === 'Bets' ? 'bg-orange-600' : 'group-hover/bets:bg-pink-500'"
              ></div>
              <img class="h-[22px] w-auto object-contain -mb-1 invert pr-1.5" src="../assets/coin.png" alt="coin">
              <span class="pt-1 pl-0.5">Bets</span>
            </router-link>
            <router-link 
              :to="{ name: 'Maintenances'}"
              v-if="userRoles.isLudusAdmin"
              @click.stop="goToRoute('Maintenances')"
              class="relative py-2 pl-2 pr-3 w-full h-full flex items-center justify-start border-b border-zinc-800 text-white fugaz uppercase text-sm group/maintenances"
            >
              <div 
                class="absolute bottom-0 right-0 w-1 h-full"
                :class="$route.name === 'Maintenances' ? 'bg-orange-600' : 'group-hover/maintenances:bg-pink-500'"
              ></div>
              <font-awesome-icon :icon="['fas', 'gear']" class="h-5 pr-1.5" />
              <span class="pt-1 pl-0.5">Maintenances</span>
            </router-link>
            <router-link 
              :to="{ name: 'Logs'}"
              v-if="userRoles.isLudusAdmin"
              @click.stop="goToRoute('Logs')"
              class="relative py-2 px-2 w-full h-full flex items-center justify-start text-white fugaz uppercase text-sm group/logs"
            >
              <div 
                class="absolute bottom-0 right-0 w-1 h-full"
                :class="$route.name === 'Logs' ? 'bg-orange-600' : 'group-hover/logs:bg-pink-500'"
              ></div>
              <font-awesome-icon :icon="['fas', 'bars']" class="h-5 pr-1.5" />
              <span class="pt-1 pl-0.5">Logs</span>
            </router-link>
          </div>
        </Transition>
      </div>
    </div>
  
  </div>
</template>

<script>
import { keycloak } from '../keycloak.config'
export default {
  name: 'MobileSidebar',
  data(){
    return {
      userMenu: false,
      isIphone: false,
      headerKey: 0,
      isLandscape: false,
    }
  },
  computed: {
    // isLandscape() {
    //   return this.screenWidth > this.screenHeight;
    // },
    screenHeight() {
      return this.$store.getters['getScreenHeight']
    },
    screenWidth() {
      return this.$store.getters['getScreenWidth']
    },
    mobileSidebar() {
      return this.$store.getters['getMobileSidebar']
    },
    unseenNotifications() {
      const userNotifications = this.$store.getters['user/notifications']
      return userNotifications.filter(notif => !notif.seen)
    },
    user() {
      return this.$store.getters['user/user']
    },
    userRoles() {
      return this.user.localRoles
    },
  },
  created() {
    this.checkIfIPhone();
  },
  mounted() {
    this.emitter.on('clickOut', this.clickOut)
    window.addEventListener('resize', this.handleOrientationChange);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleOrientationChange);
  },
  // watch: {
  //   mobileSidebar() {
  //     this.userMenu = false
  //   }
  // },
  methods: {
    handleOrientationChange() {
      // Call handleResize to update isLandscape
      this.handleResize()
      this.checkIfIPhone()
      // If it's an iPhone and landscape, increment headerKey
      if (this.isIPhone && this.isLandscape) {
        alert(`this.isPhone: ${this.isIPhone} and this.isLandscape:${this.isLandscape}`)
      }
      this.$nextTick(() => {
        this.headerKey++;
      });
    },
    handleResize() {
      if (window.innerWidth > window.innerHeight) {
        this.isLandscape = true;
      } else {
        this.isLandscape = false;
      }
    },
    checkIfIPhone() {
      // const userAgent = navigator.userAgent;
      // if (/iPhone/i.test(userAgent)) {
      //   this.isIPhone = true;
      // }
      let platform = navigator?.userAgentData?.platform || navigator?.platform
      // console.log(platform)
      const isIphone = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(platform)
      // // iPad on iOS 13 detection
      // || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      // console.log(isIphone)
      this.isIphone = isIphone
    },
    clickOut() {
      this.openMobileSidebar(false)
      this.userMenu = false
    },
    openUserBox() {
      this.userMenu = true
      this.openMobileSidebar(true)
    },
    expandMenu() {
      this.openMobileSidebar(true)
      this.userMenu = false
    },
    openMobileSidebar(value) {
      this.$store.dispatch('setMobileSidebarAction', value)
    },
    goToRoute(name) {
      this.openMobileSidebar(false)
      // this.$router.push({ name: name})
    },
    logout() {
      keycloak.logout()
    },
  }
}
</script>

<style>

</style>
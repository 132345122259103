const state = {
  session: {},
  filtersFromSessions: null,
  platforms: [],
}

const getters = {
  getSession: state => state.session,
  getFiltersFromSessions: state => state.filtersFromSessions,
  getPlatforms: state => state.platforms
}

const actions = {
  setSessionAction({commit}, payload) {
    commit('setSession', payload)
  },
  setFiltersFromSessionsAction({commit}, payload) {
    commit('setFiltersFromSessions', payload)
  },
  setPlatformsAction({commit}, payload) {
    commit('setPlatforms', payload)
  },
}

const mutations = {
  setSession(state, payload) {
    state.session = payload
  },
  setFiltersFromSessions(state, payload) {
    state.filtersFromSessions = payload
  },
  setPlatforms(state, payload) {
    state.platforms = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
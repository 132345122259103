const state = {
  showGallery: false,
  actualImage: {},
}

const getters = {
  showGallery: state => state.showGallery,
  actualImage: state => state.actualImage
}

const actions = {
  setShowGalleryAction({commit}, payload) {
    commit('setShowGallery', payload)
  },
  setActualImageAction({commit}, payload) {
    commit('setActualImage', payload)
  },
}

const mutations = {
  setShowGallery(state, payload) {
    state.showGallery = payload
  },
  setActualImage(state, payload) {
    state.actualImage = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};